import EzBiApiMethod from "../EzBiApiMethod";
import {AddQueryRequest, AddQueryResponse} from "./types";

export class AddQuery extends EzBiApiMethod<AddQueryRequest, AddQueryResponse> {
    static readonly instance: AddQuery = new AddQuery();
    readonly isPrivate = true;
    readonly method = "POST";

    get path(): string {
        return "session/queries";
    }
}

export default AddQuery;
