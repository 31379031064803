import Dispatcher from "../dispatcher/Dispatcher";
import useDispatcher from "./useDispatcher";

const DISPATCHERS: Map<Dispatcher, Record<keyof any, Dispatcher>> = new Map();

export default function useAttribute<T extends Record<string, unknown>, Q extends keyof T>(
    dispatcher: Dispatcher<T>, attribute: Q
): T[Q] {
    let hostMap: Record<keyof any, Dispatcher>;

    if (!DISPATCHERS.has(dispatcher as never)) {
        DISPATCHERS.set(dispatcher as never, hostMap = {});
    } else {
        hostMap = DISPATCHERS.get(dispatcher as never) as never;
    }

    let attributeDispatcher: Dispatcher;

    if (!(attribute in hostMap)) {
        hostMap[attribute] = attributeDispatcher = new Dispatcher();
        dispatcher.subscribe(v => attributeDispatcher.update(v?.[attribute]), true);
    } else {
        attributeDispatcher = hostMap[attribute];
    }

    return useDispatcher(attributeDispatcher)[0] as never;
}
