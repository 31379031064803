export default class FocusTracker {
    private node?: HTMLElement;

    constructor(private callback?: () => void) {
        this.onFocusOut = this.onFocusOut.bind(this);
        this.simulate = this.simulate.bind(this);
    }

    private onFocusOut(): void {
        if (!this.callback || this.node?.matches(":focus-within")) {
            return;
        }

        this.callback();
        this.init();
    }

    public init(node?: HTMLElement, callback?: () => void) {
        if (this.node) {
            this.node.removeEventListener("focusout", this.onFocusOut, true);
        }

        this.node = node;

        if (this.node) {
            this.node.addEventListener("focusout", this.onFocusOut, true);
        }

        this.callback = callback;
    }

    public simulate() {
        if (this.callback) {
            this.callback();
        }
        this.init();
    }
}
