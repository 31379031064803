import EzBiApiMethod from "../EzBiApiMethod";
import {MeResponse} from "./types";

export class Me extends EzBiApiMethod<undefined, MeResponse> {
    static readonly instance: Me = new Me();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "session/me";
    }
}

export default Me;
