import Chart, {
    type AbortableDataRequest,
    type AbortableDataRequestFactory,
    AxisInfo,
    type DataCategory,
    DataItem
} from "../component/Chart";

import "./dashboard.scss"
import ChartDateRange from "../component/ChartDateRange";
import Translated from "../component/Translated";
import type {AMEResult} from "ez-api-client/dist/api/types";
import EzBiApiClient from "../api/EzBiApiClient";
import {Line} from "recharts";
import NumericStatViewer from "../component/NumericStatViewer";
import ChartLocationPicker from "../component/ChartLocationPicker";
import ChartCPOPicker from "../component/ChartCPOPicker";
import {useCallback, useMemo, useRef, useState} from "react";
import {DateOnly} from "../api/types";
import {useNumberFormat} from "../hooks/useNumberFormat";
import {DateFilterableNumericStatView} from "./DateFilterableNumericStatView";
import {NamedDateRange} from "./NamedDateRange";
import useTranslation from "../hooks/useTranslation";
import {PeriodContextProvider} from "../context/PeriodContext";
import {DEFAULT_PERIOD, Period} from "../types";

const dateRangeOnlyFilter = [ChartDateRange];

const dateRangeLocationsCPOIdsFilter = [ChartDateRange, ChartLocationPicker, ChartCPOPicker];

const dateAxis: Record<string, AxisInfo> = {
    date: {
        key: "date",
        name: "charts.labels.date",
        default: "Date"
    }
};

const categoryMap: Record<string, DataCategory> = {
    contracts: {
        id: "newContracts",
        name: "charts.contracts.new",
        default: "New Contracts",
        selected: true,
        x: dateAxis,
        y: {
            newContracts: {
                key: "newContracts",
                name: "charts.contracts.newCount",
                default: "New Contract Count",
                stroke: "#8884d8",
                label: {
                    name: "charts.contracts.newCount",
                    default: "New Contract Count",
                    angle: -90,
                    position: "insideLeft"
                }
            }
        },
        component: Line
    },
    activeContracts: {
        id: "activeContracts",
        name: "charts.contracts.active",
        default: "Active Contracts",
        selected: false,
        x: dateAxis,
        y: {
            activeContracts: {
                key: "activeContracts",
                name: "charts.contracts.activeCount",
                default: "Active Contract Count",
                stroke: "#da9b1f",
                orientation: "end",
                label: {
                    name: "charts.contracts.activeCount",
                    default: "Active Contract Count",
                    angle: 90,
                    position: "insideRight"
                }
            }
        },
        component: Line
    },
    users: {
        id: "newUsers",
        name: "charts.users.new",
        default: "New Users",
        selected: true,
        x: dateAxis,
        y: {
            newUsers: {
                key: "newUsers",
                name: "charts.users.newCount",
                default: "New User Count",
                stroke: "#8884d8",
                label: {
                    name: "charts.users.newCount",
                    default: "New User Count",
                    angle: -90,
                    position: "insideLeft"
                }
            }
        },
        component: Line
    },
    activeUsers: {
        id: "activeUsers",
        name: "charts.users.active",
        default: "Active Users",
        selected: false,
        x: dateAxis,
        y: {
            activeUsers: {
                key: "activeUsers",
                name: "charts.users.activeCount",
                default: "Active User Count",
                stroke: "#da9b1f",
                orientation: "end",
                label: {
                    name: "charts.users.activeCount",
                    default: "Active User Count",
                    angle: 90,
                    position: "insideRight"
                }
            }
        },
        component: Line
    },
    usages: {
        id: "usages",
        name: "charts.usages.usage",
        default: "Usage",
        selected: true,
        x: dateAxis,
        y: {
            energy: {
                key: "usages",
                name: "charts.usages.energy",
                default: "Energy (kWh)",
                orientation: "start",
                stroke: "#da9b1f",
                label: {
                    name: "charts.users.energy",
                    default: "Energy (kWh)",
                    angle: -90,
                    position: "insideLeft"
                },
            }/*,
            duration: {
                key: "duration",
                name: "charts.usages.duration",
                default: "Duration (h)",
                orientation: "end",
                stroke: "#8884d8",
                label: {
                    name: "charts.users.duration",
                    default: "Duration (h)",
                    angle: 90,
                    position: "insideRight"
                },
                groupKeys: ["cpoId", "locationId"]
            }*/
        },
        component: Line
    }
}

const contractCategories: DataCategory[] = [categoryMap.contracts, categoryMap.activeContracts];
const userCategories: DataCategory[] = [categoryMap.users, categoryMap.activeUsers];
const usageCategories: DataCategory[] = [categoryMap.usages]

const emptyDataRequest: AbortableDataRequest = Object.freeze({
    response: Promise.resolve([]),
    abort() {
    }
});

const dataFactory: AbortableDataRequestFactory = (filter, categories, mergeBy) => {
    if (!categories?.length) {
        return emptyDataRequest;
    }

    const toAbortableDataRequest = (
        dataCategory: DataCategory,
        response: AMEResult<any, any[]>,
        keyMap: Record<string, string>,
    ): AbortableDataRequest => {
        const keyEntries = Object.entries(keyMap)

        return {
            abort() {
                response?.context?.abortController?.abort?.()
            },
            response: response.then(result => result.succeed())
                .then(result => result.filter(Boolean as never).map(item => {
                    return keyEntries.reduce((prev, [src, dst]) => {
                        prev[src] = item[dst];
                        return prev;
                    }, {} as DataItem);
                }))
        }
    };

    const responses = categories.map(category => {
        switch (category.id) {
            case categoryMap.contracts.id:
                return toAbortableDataRequest(
                    category,
                    EzBiApiClient.instance.contracts.contracts({...filter}),
                    {
                        [categoryMap.contracts.x.date.key]: "date",
                        [categoryMap.contracts.y.newContracts.key]: "count",
                    }
                );
            case categoryMap.activeContracts.id:
                return toAbortableDataRequest(
                    category,
                    EzBiApiClient.instance.contracts.activeContracts({...filter}),
                    {
                        [categoryMap.activeContracts.x.date.key]: "date",
                        [categoryMap.activeContracts.y.activeContracts.key]: "count",
                    }
                );
            case categoryMap.users.id:
                return toAbortableDataRequest(
                    category,
                    EzBiApiClient.instance.users.users({...filter}),
                    {
                        [categoryMap.users.x.date.key]: "date",
                        [categoryMap.users.y.newUsers.key]: "count",
                    }
                );
            case categoryMap.activeUsers.id:
                return toAbortableDataRequest(
                    category,
                    EzBiApiClient.instance.users.activeUsers({...filter}),
                    {
                        [categoryMap.activeUsers.x.date.key]: "date",
                        [categoryMap.activeUsers.y.activeUsers.key]: "count",
                    }
                );
            case categoryMap.usages.id:
                return toAbortableDataRequest(
                    category,
                    EzBiApiClient.instance.stats.usages({...filter}),
                    {
                        [categoryMap.usages.x.date.key]: "date",
                        [categoryMap.usages.y.energy.key]: "energy",
                        //[categoryMap.usages.y.duration.key]: "duration",
                    }
                );
            default:
                return null;
        }
    }).filter(Boolean as never) as AbortableDataRequest[];

    if (!responses.length) {
        return emptyDataRequest;
    }

    return {
        response: Promise.all(responses.map(item => item.response))
            .then(list => (list.flat() as DataItem[]).reduce((prev, cur) => {
                const val = String(cur[mergeBy]);
                if (!(val in prev)) {
                    prev[val] = cur;
                } else {
                    const target: any = prev[val];

                    Object.entries(cur).forEach(([key, value]) => {
                        if (key === mergeBy) {
                            return;
                        }

                        if (key in target) {
                            target[key] += value;
                        } else {
                            target[key] = value;
                        }
                    })
                }
                return prev;
            }, {}))
            .then(obj => Object.values(obj) as DataItem[]),
        abort() {
            responses.forEach(response => response.abort())
        }
    };
};

export default function Dashboard() {

    const [format] = useNumberFormat();

    const [translate] = useTranslation();

    const contractsTip = useCallback((filters: Record<string, unknown>, dataCategories: DataCategory[]) => {
        return Promise.all([
            EzBiApiClient.instance.stats.getStatsBy({
                startDate: new DateOnly(Date.parse('2016-01-01')).resetTime(),
                endDate: new DateOnly().resetTime(),
                type: "allContracts"
            }).then(v => v.succeed())
                .then(v => ({chart: format.format(v.value)}))
                .catch(() => null),
            dataCategories.find(v => v.id === categoryMap.contracts.id && v.selected)
                && EzBiApiClient.instance.stats.getStatsBy({
                type: "allContracts",
                startDate: DateOnly.fromOrToday(filters.from as never).resetTime(),
                endDate: DateOnly.fromOrToday(filters.to as never).resetTime()
            }).then(v => v.succeed())
                .then(v => ({[categoryMap.contracts.id]: format.format(v.value)}))
                .catch(() => null),
            dataCategories.find(v => v.id === categoryMap.activeContracts.id && v.selected)
            && EzBiApiClient.instance.stats.getStatsBy({
                type: "activeContracts",
                startDate: DateOnly.fromOrToday(filters.from as never).resetTime(),
                endDate: DateOnly.fromOrToday(filters.to as never).resetTime()
            }).then(v => v.succeed())
                .then(v => ({[categoryMap.activeContracts.id]: format.format(v.value)}))
                .catch(() => null)
        ]).then(v => v.filter(Boolean as never)
            .reduce((prev, cur) => Object.assign(prev as never, cur), {}));
    }, [format]);

    const usersTip = useCallback((filters: Record<string, unknown>, dataCategories: DataCategory[]) => {
        return Promise.all([
            EzBiApiClient.instance.stats.getStatsBy({
                startDate: new DateOnly(Date.parse('2016-01-01')).resetTime(),
                endDate: new DateOnly().resetTime(),
                type: "allUsers"
            }).then(v => v.succeed())
                .then(v => ({chart: format.format(v.value)}))
                .catch(() => null),
            dataCategories.find(v => v.id === categoryMap.users.id && v.selected)
            && EzBiApiClient.instance.stats.getStatsBy({
                type: "allUsers",
                startDate: DateOnly.fromOrToday(filters.from as never).resetTime(),
                endDate: DateOnly.fromOrToday(filters.to as never).resetTime()
            }).then(v => v.succeed())
                .then(v => ({[categoryMap.users.id]: format.format(v.value)}))
                .catch(() => null),
            dataCategories.find(v => v.id === categoryMap.activeUsers.id && v.selected)
            && EzBiApiClient.instance.stats.getStatsBy({
                type: "activeUsers",
                startDate: DateOnly.fromOrToday(filters.from as never).resetTime(),
                endDate: DateOnly.fromOrToday(filters.to as never).resetTime()
            }).then(v => v.succeed())
                .then(v => ({[categoryMap.activeUsers.id]: format.format(v.value)}))
                .catch(() => null)
        ]).then(v => v.filter(Boolean as never)
            .reduce((prev, cur) => Object.assign(prev as never, cur), {}));
    }, [format]);

    const totalKwTip = useCallback((filters: Record<string, unknown>, dataCategories: DataCategory[]) => {
        return Promise.all([
            EzBiApiClient.instance.stats.getStatsBy({
                startDate: new DateOnly(Date.parse('2016-01-01')).resetTime(),
                endDate: new DateOnly().resetTime(),
                type: "totalEnergyConsumptionKwh"
            }).then(v => v.succeed())
                .then(v => ({chart: format.format(v.value)}))
                .catch(() => null),
            dataCategories.find(v => v.id === categoryMap.usages.id && v.selected)
            && EzBiApiClient.instance.stats.getStatsBy({
                type: "totalEnergyConsumptionKwh",
                startDate: DateOnly.fromOrToday(filters.from as never).resetTime(),
                endDate: DateOnly.fromOrToday(filters.to as never).resetTime()
            }).then(v => v.succeed())
                .then(v => ({[categoryMap.usages.id]: format.format(v.value)}))
                .catch(() => null)
        ]).then(v => v.filter(Boolean as never)
            .reduce((prev, cur) => Object.assign(prev as never, cur), {}));
    }, [format]);

    const [period, setPeriod] = useState(DEFAULT_PERIOD);

    const periodRef = useRef<Period>(DEFAULT_PERIOD);

    const onPeriodChange = useCallback((period: Period) => {
        periodRef.current = period;
    }, [periodRef]);

    const onApplyClick = useCallback(() => {
        setPeriod(periodRef.current);
    }, [periodRef])

    return <PeriodContextProvider period={period} setPeriod={setPeriod}>
        <div className="dashboard-container">
            <div className="pb-4 d-flex align-items-center justify-content-center">
                <div className="card shadow">
                    <div className="card-body d-flex align-items-center gap-2">
                        <NamedDateRange defaultValue={DEFAULT_PERIOD} onChange={onPeriodChange} />
                        <button className="btn btn-sm btn-primary" onClick={onApplyClick}>{
                            translate("common.labels.applyToAll", "Apply to All")
                        }</button>
                    </div>
                </div>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1">
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.users.activeCount", default: "Active User Count"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.users.activeCountDescription",
                            default: "The total number of users of this CPO that have charged at least once in {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-people-fill"></i>}
                        variant="info"
                        stateKey="activeUsers"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.contracts.activeCount", default: "Active Contract Count"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.contracts.activeCountDescription",
                            default: "The total number of contracts of this CPO that have charged at least once in {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-chevron-bar-contract"></i>}
                        variant="warning"
                        stateKey="activeContracts"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.energy", default: "Total Energy Usage"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.energyDescription",
                            default: "The total energy consumption for this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-lightning-charge-fill"></i>}
                        variant="success"
                        stateKey="totalEnergyConsumptionKwh"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.totalAmount", default: "Gross Income"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.totalAmountDescription",
                            default: "Gross income of this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-cash-stack"></i>}
                        variant="success"
                        stateKey="totalAmount"
                        currency
                    />
                </div>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1">
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.allUsers", default: "All Users"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.allUsersDescription",
                            default: "All registered users of this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-people"></i>}
                        variant="success"
                        stateKey="allUsers"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.allContracts", default: "All Contracts"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.allContractsDescription",
                            default: "All registered contracts of this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-chevron-contract"></i>}
                        variant="primary"
                        stateKey="allContracts"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.activeNonMembers", default: "Active Non-Member Contracts"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.activeNonMembersDescription",
                            default: "Count of the contracts created {{component::datePicker}} bur not upgraded for this CPO yet."
                        }), [])}
                        icon={<i className="bi bi-arrows-angle-contract"></i>}
                        variant="info"
                        stateKey="activeNonMembers"
                    />
                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.transactions", default: "Transaction Count"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.transactionsDescription",
                            default: "Count of the charging transactions for this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-battery-charging"></i>}
                        variant="success"
                        stateKey="transactionCount"
                    />
                </div>
            </div>
            <div className="row row-cols-md-2 row-cols-sm-1">
                <div className="col">
                    <Chart dataFactory={dataFactory}
                           mergeBy={dateAxis.date.key}
                           dataCategories={contractCategories}
                           title={<Translated caption="charts.contracts.title" default="Contracts" />}
                           filters={dateRangeOnlyFilter} info={contractsTip}></Chart>
                </div>
                <div className="col">
                    <Chart dataFactory={dataFactory}
                           mergeBy={dateAxis.date.key}
                           dataCategories={userCategories}
                           title={<Translated caption="charts.users.title" default="Users" />}
                           filters={dateRangeOnlyFilter} info={usersTip}></Chart>
                </div>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1">
                <div className="col">
                    <NumericStatViewer title={<Translated caption="charts.stats.stations" default="Location Count" />}
                                       description={<Translated caption="charts.stats.stationsDescription"
                                                                default="The total number of locations of this CPO" />}
                                       icon={<i className="bi bi-geo-alt-fill"></i>}
                                       variant="primary"
                                       statKey="locationCount" />

                </div>
                <div className="col">
                    <NumericStatViewer title={<Translated caption="charts.stats.units" default="Unit Count" />}
                                       description={<Translated caption="charts.stats.unitsDescription"
                                                                default="The total number of units of this CPO" />}
                                       icon={<i className="bi bi-diagram-3-fill"></i>}
                                       variant="warning"
                                       statKey="stationCount" ratioKey="stationCountDC" ratioTitle="DC" />

                </div>
                <div className="col">
                    <NumericStatViewer title={<Translated caption="charts.stats.evses" default="EVSE Count" />}
                                       description={<Translated caption="charts.stats.evsesDescription"
                                                                default="The total number of EVSE of this CPO" />}
                                       icon={<i className="bi bi-ev-station-fill"></i>}
                                       variant="info"
                                       statKey="unitCount" ratioKey="unitCountDC" ratioTitle="DC"  />

                </div>
                <div className="col">
                    <DateFilterableNumericStatView
                        title={useMemo(() => ({caption: "charts.stats.newUnits", default: "Recently Added Units"}), [])}
                        description={useMemo(() => ({
                            caption: "charts.stats.newUnitsDescription",
                            default: "Count of recently added units for this CPO {{component::datePicker}}."
                        }), [])}
                        icon={<i className="bi bi-ev-station"></i>}
                        variant="success"
                        stateKey="newUnits"
                    />
                </div>
            </div>
            <div className="row row-cols-md-2 row-cols-sm-1">
                <div className="col large-filters">
                    <Chart dataFactory={dataFactory}
                           mergeBy={dateAxis.date.key}
                           dataCategories={usageCategories}
                           title={<Translated caption="charts.usages.title" default="Usages" />}
                           filters={dateRangeLocationsCPOIdsFilter} info={totalKwTip}></Chart>
                </div>
                <div className="col">
                    <div className="row row-cols-lg-2 row-cols-md-1">
                        <div className="col">
                            <DateFilterableNumericStatView
                                title={useMemo(() => ({caption: "charts.stats.duration", default: "Total Energy Usage"}), [])}
                                description={useMemo(() => ({
                                    caption: "charts.stats.durationDescription",
                                    default: "The total charging hours spent for this CPO {{component::datePicker}}."
                                }), [])}
                                icon={<i className="bi bi-clock-fill"></i>}
                                variant="primary"
                                stateKey="totalChargingHours"
                            />
                        </div>
                        <div className="col">
                            <DateFilterableNumericStatView
                                title={useMemo(() => ({caption: "charts.stats.allNonMembers", default: "All Non-Member Contracts"}), [])}
                                description={useMemo(() => ({
                                    caption: "charts.stats.allNonMembersDescription",
                                    default: "Count of the contracts created {{component::datePicker}} but not upgraded for this CPO yet."
                                }), [])}
                                icon={<i className="bi bi-question-diamond"></i>}
                                variant="warning"
                                stateKey="allNonMembers"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PeriodContextProvider>;
}
