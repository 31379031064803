import {Authenticate, AuthenticateRequest} from "./Authenticate";
import {AuthenticateResponse} from "./types";
import RefreshToken, {RefreshTokenRequest} from "./RefreshToken";
import type {
    AMEResult,
    IApiExecutionContextFactory
} from "ez-api-client/dist/api/types";

export default class AuthApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.authenticate = this.authenticate.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
    }

    authenticate(request: AuthenticateRequest): AMEResult<AuthenticateRequest, AuthenticateResponse> {
        return Authenticate.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Authenticate.instance, request)
        );
    }

    refreshToken(request: RefreshTokenRequest): AMEResult<RefreshTokenRequest, AuthenticateResponse> {
        return RefreshToken.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(RefreshToken.instance, request)
        );
    }
}
