import type {IApiRequest, IApiResponse, Modifier, OptionalGeneric} from "ez-api-client/dist/api/types";
import {JsonApiMethod} from "ez-api-client/dist/api/JsonApiMethod";
import {DateOnly} from "./types";

const IS_DATE_EXP = /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])(?:T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+Z|Z))?$/;

function fixDates(obj: any): any {
    switch (typeof obj) {
        case "string":
            if (IS_DATE_EXP.test(obj)) {
                return Date.parse(obj);
            }
            break;
        case "object":
            if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    obj[index] = fixDates(item);
                });
            } else if (obj) {
                Object.entries(obj).forEach(([key, value]) => {
                    obj[key] = fixDates(value);
                })
            }
            break;
    }
    return obj;
}

function stringifyDates(obj: any): any {
    switch (typeof obj) {
        case "object":
            if (obj instanceof DateOnly) {
                return obj.toISOString().split("T")[0];
            }

            if (obj instanceof Date) {
                return obj.toISOString();
            }

            if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    obj[index] = stringifyDates(item);
                });
            } else if (obj) {
                Object.entries(obj).forEach(([key, value]) => {
                    obj[key] = stringifyDates(value);
                })
            }
            break;
    }
    return obj;
}

export default abstract class EzBiApiMethod<
    TReq extends OptionalGeneric<IApiRequest>,
    TRes extends OptionalGeneric<IApiResponse>,
    >
    extends JsonApiMethod<TReq, TRes> {

    get cacheable(): boolean {
        return false;
    }

    get requestModifier(): OptionalGeneric<Modifier> {
        return stringifyDates;
    }

    get responseModifier(): OptionalGeneric<Modifier> {
        return fixDates;
    }
}
