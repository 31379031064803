import type {Dictionary, IApiRequest} from "ez-api-client/dist/api/types";

export interface DateGroup extends Dictionary {
    date: string
    count: number
}

export type DateGroupListResponse = DateGroup[]

export class DateOnly extends Date {
    public resetTime(): this {
        this.setTime(Math.floor(this.getTime() / 86400000) * 86400000 - this.getTimezoneOffset() * 60000)
        return this;
    }

    public addDays(days: number): this {
        this.setTime(this.getTime() + days * 86400000);
        return this;
    }

    public beginOfTheMonth(): this {
        this.setDate(1);
        return this;
    }

    public isEndOfTheMonth(): boolean {
        return new DateOnly(this.getTime()).addDays(1).isBeginOfTheMonth();
    }

    public isBeginOfTheMonth(): boolean {
        return this.getDate() === 1;
    }

    public asDateString(): string {
        return this.toISOString().split('T')[0];
    }

    public static fromOrToday(date?: Date): DateOnly {
        return date ? new DateOnly(Number(date)) : new DateOnly();
    }

    public static from(date: Date | null | undefined): DateOnly | undefined {
        if (!date) {
            return undefined;
        }

        return new DateOnly(Number(date)).resetTime();
    }

    public static diff(date?: Date): number {
        if (!date) {
            return 0;
        }

        date = new Date(Number(date));
        date.setHours(0, 0, 0, 0);

        const now = new Date();
        now.setHours(0, 0, 0, 0);
        return (Number(date) - Number(now)) / 86400000;
    }

    public static fromDiff(diff: number): DateOnly {
        return new DateOnly().resetTime().addDays(diff);
    }

    public static diffPeriod(start?: Date, stop?: Date): [number, number] {
        const begin = this.diff(start);
        const end = this.diff(stop);
        if (begin < end) {
            return [begin, end];
        }

        return [end, end];
    }

    public static isDiffBeginOfTheMonth(diff: number): boolean {
        const beginOfTheMonth = new Date();
        beginOfTheMonth.setHours(0, 0, 0, 0);
        beginOfTheMonth.setDate(1);
        return diff === this.diff(beginOfTheMonth);
    }

    public static isDiffFullMonth(start: number, end: number): boolean {
        const date = new DateOnly();
        date.setHours(0, 0, 0, 0);
        const time = date.getTime();
        date.addDays(end);
        if (!date.isEndOfTheMonth()) {
            return false;
        }
        const month = date.getMonth();

        date.setTime(time);
        date.addDays(start);
        if (!date.isBeginOfTheMonth()) {
            return false;
        }

        return month === date.getMonth();
    }

    public static getMonthAndYear(diff: number, lang?: string): Record<string, unknown> {
        const parts = new Intl.DateTimeFormat(lang ?? "en", {month: "long", year: "numeric"})
            .formatToParts(new DateOnly().addDays(diff))

        return {
            month: parts.find(v => v.type === "month")?.value,
            year: parts.find(v => v.type === "year")?.value
        }
    }

    public static getStartAndEnd(startDiff: number, endDiff: number): {start: string, end: string} {
        return {
            start: new DateOnly().addDays(startDiff).asDateString(),
            end: new DateOnly().addDays(endDiff).asDateString()
        }
    }
}

export interface DateFilter extends IApiRequest {
    from?: DateOnly
    to?: DateOnly
}
