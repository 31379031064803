import {Query} from "../../api/session/types";
import equals from "../../utils/equals";
import {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Connection,
    Edge,
    EdgeChange,
    getConnectedEdges,
    getIncomers,
    MarkerType,
    Node,
    NodeChange
} from "reactflow";
import {PresentationType} from "./types";
import {
    BinaryNodeInfo,
    ColumnNodeInfo,
    ComparisonNodeInfo,
    ConditionNodeInfo,
    ConstantNodeInfo,
    FunctionNodeInfo,
    NodeInfo,
    OrderInfo,
    OrderType,
    SelectionInfo,
    TableNodeInfo
} from "./flow/types";
import {Viewport} from "@reactflow/core/dist/esm/types/general";
import ObjectDispatcher from "../../dispatcher/ObjectDispatcher";
import {
    CPQLAliasableExpression,
    CPQLArrayExpression,
    CPQLBinaryExpression,
    CPQLCallExpression,
    CPQLChainedConditionalExpression,
    CPQLColumnNameExpression,
    CPQLComparisonExpression,
    CPQLConstantExpression,
    CPQLExpression,
    CPQLGroupExpression,
    CPQLHavingStatement,
    CPQLInExpressionBase,
    CPQLIsNotNullExpression,
    CPQLIsNullExpression,
    CPQLNamedAndTyped,
    CPQLNameExpression,
    CPQLOrderExpression,
    CPQLParsedQuery,
    CPQLWhereStatement
} from "../../api/cpql/types";
import {XYPosition} from "@reactflow/core/dist/esm/types/utils";
import {getStylePropsForConnection} from "./flow/utils";


const connectionMap: Record<string, string[]> = {
    "table": ["column"],
    "column": ["table", "selection", "order", "function", "condition", "binary", "comparison", "rowFilter", "rowFilter", "groupFilter"],
    "selection": ["column", "function", "condition", "constant", "binary", "comparison", "rowFilter"],
    "order": ["column", "function", "condition", "constant", "binary", "comparison"],
    "function": ["condition", "selection", "order", "function", "binary", "comparison", "rowFilter", "groupFilter"],
    "condition": ["selection", "order", "rowFilter", "groupFilter", "function"],
    "rowFilter": ["condition", "column", "function", "condition", "constant", "binary", "comparison"],
    "groupFilter": ["condition", "column", "function", "condition", "constant", "binary", "comparison"],
    "constant": ["selection", "function", "condition", "rowFilter", "groupFilter", "order", "binary", "comparison"],
    "binary": ["selection", "function", "condition", "rowFilter", "groupFilter", "order", "binary", "comparison"],
    "comparison": ["selection", "function", "condition", "rowFilter", "groupFilter", "order", "binary"],
}

const defaultExpression = {
    "@type": "ConstantExpression",
    type: "NULL",
    value: "null"
} as CPQLConstantExpression;

function toGroupExpression(expression: CPQLExpression): CPQLGroupExpression {
    return {
        "@type": "GroupExpression",
        expression
    };
}

function hasRecursive(source: Node<NodeInfo>, target: Node<NodeInfo>, nodes: Node<NodeInfo>[], edges: Edge[]) {
    return false;
    //return getIncomers(source, nodes, edges).includes(target) || getOutgoers(source, nodes, edges).includes(target);
}

type AutoInputNode = BinaryNodeInfo | ComparisonNodeInfo | ConditionNodeInfo;

export interface QueryDesignerData {
    type?: PresentationType
    props?: Record<string, unknown>
    nodes: Node<NodeInfo>[]
    edges: Edge[]
    viewport?: Viewport
}

function getEmptyQueryDesignerData(): QueryDesignerData {
    return {
        props: {},
        nodes: [
            {
                type: "selection",
                position: {
                    x: 0,
                    y: 60
                },
                id: "selection",
                data: {
                    type: "selection",
                    object: {}
                },
                deletable: false,
                updatable: false
            } as never,
            {
                type: "groupFilter",
                position: {
                    x: 0,
                    y: 160
                },
                id: "groupFilter",
                data: {
                    type: "groupFilter",
                    object: {}
                },
                deletable: false,
                updatable: false
            } as never,
            {
                type: "rowFilter",
                position: {
                    x: 0,
                    y: 260
                },
                id: "rowFilter",
                data: {
                    type: "rowFilter",
                    object: {}
                },
                deletable: false,
                updatable: false
            } as never,
            {
                type: "order",
                position: {
                    x: 0,
                    y: 360
                },
                id: "order",
                data: {
                    type: "order",
                    object: {}
                },
                deletable: false,
                updatable: false
            } as never
        ],
        edges: [],
        viewport: {
            x: 0,
            y: 0,
            zoom: 1
        },
        type: PresentationType.TABLE
    };
}

let ID = Date.now();

export default class QueryDispatcher extends ObjectDispatcher<Query> {
    private _designerData: QueryDesignerData = undefined as never;
    private _id = 0;

    constructor(arg?: Query) {
        super(arg);

        this.setProp = this.setProp.bind(this);
        this.getProp = this.getProp.bind(this);
        this.setType = this.setType.bind(this);
        this.addEdge = this.addEdge.bind(this);
        this.init(arg);
    }

    equals(next?: Query): boolean {
        return equals(next, this.value);
    }

    private init(value?: Query, notify?: boolean) {
        if (value?.designerData) {
            try {
                this._designerData = JSON.parse(value.designerData);
            } catch (e) {
                this._designerData = getEmptyQueryDesignerData();
            }
        } else {
            this._designerData = getEmptyQueryDesignerData();
        }

        if (notify) {
            this.notifyAsync();
        }
    }

    private get designerData(): QueryDesignerData {
        return this._designerData ?? (this._designerData = getEmptyQueryDesignerData());
    }

    protected onUpdate(value: Query | undefined) {
        this.init(value, true);
    }

    private flushDesignerDataChange(): void {
        const {value} = this;
        if (!value) {
            return;
        }

        this.updateState({designerData: JSON.stringify(this._designerData)})
    }

    getProps(): Record<string, unknown> {
        return this._designerData?.props ?? {};
    }

    getProp<T = unknown>(name: string): T {
        return this._designerData?.props?.[name] as T;
    }

    setProp(name: string, value: unknown): void {
        const current = this._designerData.props?.[name];
        if (equals(value, current)) {
            return;
        }

        const props = this._designerData.props ?? (this._designerData.props = {});

        if (value === undefined) {
            delete props[name];
        } else {
            props[name] = value;
        }

        this.flushDesignerDataChange();
    }

    setNodesAndEdges(nodes: Node<NodeInfo>[], edges: Edge<NodeInfo>[]): void {
        const {nodes: currentNodes, edges: currentEdges} = this._designerData;

        if (equals(nodes, currentNodes) && equals(edges, currentEdges)) {
            return;
        }

        this._designerData.nodes = nodes;
        this._designerData.edges = edges;
        this.flushDesignerDataChange();
    }

    getNodesAndEdges(): {nodes: Node<NodeInfo>[], edges: Edge<NodeInfo>[]} {
        return {
            nodes: this.designerData.nodes.slice(),
            edges: this.designerData.edges.slice()
        };
    }

    getNodesAndEdgesOptional() {
        if (!this.value?.id) {
            return undefined;
        }

        return this.getNodesAndEdges();
    }

    setViewport(viewport: Viewport): void {
        if (!this._designerData) {
            return;
        }

        if (equals(this._designerData.viewport, viewport)) {
            return;
        }

        this._designerData.viewport = viewport;
        this.flushDesignerDataChange();
    }

    getViewport(): Viewport {
        return this.designerData.viewport ?? {x: 0, y: 0 , zoom: 1};
    }

    get type(): PresentationType {
        return this._designerData?.type ?? PresentationType.TABLE;
    }

    setType(type: PresentationType) {
        if (this._designerData.type === type) {
            return;
        }
        this._designerData.type = type;
        this.flushDesignerDataChange();
    }

    setQuery(query?: string) {
        this.ifPresent(v => {
            if (!query) {
                query = "";
            }
            query = query.trim();

            if (v.query === query) {
                return false;
            }

            v.query = query;
            return true;
        });
    }

    applyChanges(nodes: NodeChange[], edges: EdgeChange[]) {
        const dd = this.designerData;
        if (nodes?.length) {
            dd.nodes = applyNodeChanges(nodes, dd.nodes);
        }

        if (edges?.length) {
            dd.edges = applyEdgeChanges(edges, dd.edges);
        }

        this.flushDesignerDataChange();
    }

    getNodes(): Node<NodeInfo>[] {
        return this.designerData.nodes.slice();
    }

    getEdges(): Edge[] {
        return this.designerData.edges.slice();
    }

    updateNodesAndEdges({nodes, edges}: {nodes?: Node<NodeInfo>[], edges?: Edge[]}): void {
        let changed = false;

        if (nodes && !equals(this.getNodes(), nodes)) {
            this.designerData.nodes = nodes.slice();
            changed = true;
        }

        if (edges && !equals(this.getEdges(), edges)) {
            this.designerData.edges = edges.slice();
            changed = true;
        }

        if (changed) {
            this.flushDesignerDataChange();
        }
    }

    private getNextHandleName(node: Node<AutoInputNode>): string {
        const id = String(node.data.count++);
        return `${node.id}-input${id.padStart(4, "0")}`;
    }

    private resetNode(node: Node<AutoInputNode>, edges: Edge[]): void {
        const data = node.data;
        data.inputs = []
        data.min = 2;
        data.max = 2;

        switch (data.object.type) {
            case "ADD":
            case "MUL":
            case "SUB":
                data.max = Number.MAX_SAFE_INTEGER;
                break;
            case "IS_NOT_NULL":
            case "IS_NULL":
                data.min = 1;
                data.max = 1;
                break;
            case "IN":
            case "NOT_IN":
                data.max = Number.MAX_SAFE_INTEGER;
                break;
            default:
                if (node.type === "condition") {
                    data.max = Number.MAX_SAFE_INTEGER;
                }
                break;
        }

        data.count ??= data.min;

        for (let i=0; i<2 && i<edges.length; i++) {
            data.inputs.push(edges[i].targetHandle as string);
        }

        for(let i=data.inputs.length; i<2; i++) {
            data.inputs.push(this.getNextHandleName(node))
        }

        for (let i=0; i<data.inputs.length && i<edges.length; i++) {
            edges[i].targetHandle = data.inputs[i];
        }

        for (let i=data.inputs.length; i<edges.length; i++) {
            const id = this.getNextHandleName(node);
            data.inputs.push(id);
            edges[i].targetHandle = id;
        }

        if (data.inputs.length < data.max && edges.length >= data.min) {
            data.inputs.push(this.getNextHandleName(node));
        }
    }

    updateNode<T extends NodeInfo>(id: string, cb: (node: Node<T>) => boolean) {
        const node = this.getNode<T>(id);
        if (!node) {
            return;
        }
        const clone = JSON.parse(JSON.stringify(node));
        if (!cb(clone)) {
            return;
        }
        const dd = this.designerData;
        dd.nodes = dd.nodes.map(n => {
            if (n.id === id) {
                return clone;
            }
            return n;
        });
        this.flushDesignerDataChange();
    }

    nextId(): string {
        return String(++ID);
    }

    addNode<T extends NodeInfo>(node: Node<T>, edge?: Edge): boolean {
        const dd = this.designerData;
        if (node.type === "table" && dd.nodes.find(v => v.data.object.name === node.data.object.name)) {
            return false;
        }

        node = {...node, id: edge ? node.id : this.nextId(), selected: true};

        if (node.type === "function") {
            const funcNode = node as Node<FunctionNodeInfo>;
            funcNode.data.paramCount = 0;
            const func = funcNode.data.object;
            if (func.varArgs && func.parameters.length) {
                funcNode.data.varArg = {...func.parameters.at(-1)} as never;
                (func.parameters.at(-1) as CPQLNamedAndTyped).name += "|0";
            }
            func.parameters.forEach(v => (v.name = `${v.name}@${this.nextId()}`));
        }

        if (node.type === "binary" || node.type === "comparison" || node.type === "condition") {
            this.resetNode(node as never, []);
        }

        dd.nodes.forEach(v => (v.selected = false));
        dd.nodes.push(node);

        if (edge) {
            dd.edges = addEdge(edge, dd.edges);
        }

        this.flushDesignerDataChange();
        return true;
    }

    private getNodesWithEdges(type: string): Map<Node<BinaryNodeInfo>, Edge[]> {
        const dd = this.designerData;
        return dd.edges.reduce((prev, cur) => {
            const node = dd.nodes
                .find(v => v.id === cur.target && v.type === type) as Node<BinaryNodeInfo>;

            if (!node) {
                return prev;
            }

            if (!prev.has(node)) {
                prev.set(node, []);
            }

            prev.get(node)?.push(cur);
            return prev;
        }, new Map<Node<BinaryNodeInfo>, Edge[]>())
    }

    private resetNodes(type: string, edgeFilter: (edge: Edge) => boolean): void {
        this.getNodesWithEdges(type).forEach((edges, node) => {
            this.resetNode(node, edges.filter(edgeFilter));
        })
    }

    deleteNode(id: string) {
        this.resetNodes("binary", v => v.source !== id);
        this.resetNodes("comparison", v => v.source !== id);
        this.resetNodes("condition", v => v.source !== id);
        const dd = this.designerData;
        dd.edges = dd.edges.filter(q => q.source !== id && q.target !== id);
        dd.nodes = dd.nodes.filter(n => n.id !== id && n.data.source !== id);
        this.flushDesignerDataChange();
    }

    deleteConnectedNodesByType(nodeId: string, type: string) {
        const edges = this.getConnectedEdges(nodeId);
        const nodesToDelete = edges.map(v => v.source === nodeId ? v.target : v.source);
        const dd = this.designerData;
        dd.nodes = dd.nodes.filter(q => q.type !== type || !nodesToDelete.includes(q.id));
        this.flushDesignerDataChange();
    }

    static getRandomPointOnCircle(x: number, y: number, radius: number): XYPosition {
        const angle = Math.random() * Math.PI + Math.PI / 3;
        const pointX = x + radius * Math.cos(angle);
        const pointY = y + radius * Math.sin(angle);
        return {x: pointX, y: pointY};
    }

    static isColumnNode(node: Node<NodeInfo>): node is Node<ColumnNodeInfo> {
        return node.type === "column";
    }

    duplicateNode(id: string) {
        const node = this.designerData.nodes.find(v => v.id === id);
        if (!node) {
            return;
        }

        if (QueryDispatcher.isColumnNode(node)) {
            return this.addColumn(node.data.source, node.data.object.name, node.data.title as string);
        }

        this.addNode({
            ...node,
            id: this.nextId(),
            position: {
                x: node.position.x + 50,
                y: node.position.y + 50
            },
            data: {
                object: node.data.object,
                title: node.data.title
            }
        } as Node<NodeInfo>);
    }

    addColumn(tableId: string, columnName: string, title: string) {
        const table = this.designerData.nodes.find(v => v.id === tableId);
        if (!table) {
            return;
        }
        if (table.data.type !== "table") {
            return;
        }
        const tableData = table.data;
        let {width, height, position: {x, y}} = table;
        width ||= 128;
        height ||= 128;
        const rad = Math.sqrt(width * width + height * height) + 32;

        const columnId = this.nextId();

        this.addNode(
            {
                type: "column",
                position: QueryDispatcher.getRandomPointOnCircle(x + 16, y + 16, rad),
                data: {
                    type: "column",
                    object: tableData.object.columns.find(v => v.name === columnName),
                    source: tableId,
                    table: {
                        name: tableData.object.name
                    },
                    title
                } as ColumnNodeInfo,
                id: columnId,
                selected: true
            },
            {
                id: this.nextId(),
                source: tableId,
                target: columnId,
                type: "floating",
                animated: true,
                sourceHandle: "column",
                targetHandle: "input",
                updatable: false,
                deletable: false,
                style: {
                    stroke: "var(--bs-primary)",
                    strokeWidth: 2
                },
                markerEnd: MarkerType.Arrow
            }
        );
    }

    getTitle<T extends NodeInfo>(node: Node<T>): string {
        if (!node) {
            return "";
        }
        switch (node.type) {
            case "function":
            {
                const func = node.data as FunctionNodeInfo;
                const params = func.object.parameters.slice();
                if (func.varArg) {
                    params.pop();
                }
                const edges = this.getConnectedEdges(node.id);
                return `${func.object.name}(${
                    params.map(v => {
                        const edge = edges.find(q => q.targetHandle === v.name);
                        if (!edge) {
                            return "";
                        }

                        const node = this.getNode(edge.source);
                        if (!node) {
                            return "";
                        }

                        return this.getTitle(node);
                    }).join(", ")
                })`;
            }
        }

        return node.data.title ?? "";
    }

    getTitleById(id: string) {
        return this.getTitle(this.getNode(id));
    }

    addEdge(connection: Connection) {
        const dd = this.designerData;
        let edgeOverride: Partial<Edge> = {};

        const sourceNode = dd.nodes.find(v => v.id === connection.source);
        const targetNode = dd.nodes.find(v => v.id === connection.target);
        if (!sourceNode || !targetNode || sourceNode === targetNode
            || hasRecursive(sourceNode, targetNode, dd.nodes, dd.edges)) {
            return;
        }

        if (!connectionMap[sourceNode.type as string].includes(targetNode.type as string)) {
            return;
        }

        switch (targetNode.type) {
            case "selection":
            case "rowFilter":
            case "groupFilter":
            case "order":
                edgeOverride.targetHandle = connection.source as string;
                break;
        }

        const selectedNode = targetNode.type === "selection" ? sourceNode : null;
        if (selectedNode) {
            const incomers = this.getIncomers("selection");
            incomers.sort((a, b) => (b.data.selection?.index ?? 0) - (a.data.selection?.index ?? 0));
            selectedNode.data.selection = {grouped: false, alias: "", index: (incomers?.[0]?.data?.selection?.index ?? 0) + 1};
        }

        const orderedNode = targetNode.type === "order" ? sourceNode : null;
        if (orderedNode) {
            const incomers = this.getIncomers("order");
            incomers.sort((a, b) => (b.data.order?.index ?? 0) - (a.data.order?.index ?? 0));
            orderedNode.data.order = {type: "ASC", index: (incomers?.[0]?.data?.order?.index ?? 0) + 1};
        }

        const functionNode = (targetNode.type === "function" ? targetNode : null) as Node<FunctionNodeInfo>;
        if (functionNode && connection.targetHandle) {
            if (connection.targetHandle.includes("|")) {
                functionNode.data.object.parameters.push({
                    ...functionNode.data.varArg,
                    name: functionNode.data.varArg.name + "|" + (++functionNode.data.paramCount) + "@" + this.nextId()
                })
            }
        }

        const binaryNode = (targetNode.type === "binary" ? targetNode : null) as Node<BinaryNodeInfo>;
        if (binaryNode) {
            const edges = this.getConnectedEdges(binaryNode.id).filter(v => v.target === binaryNode.id)
                .concat(connection as never);
            this.resetNode(binaryNode, edges);
        }

        const comparisonNode = (targetNode.type === "comparison" ? targetNode : null) as Node<ComparisonNodeInfo>;
        if (comparisonNode) {
            const edges = this.getConnectedEdges(comparisonNode.id).filter(v => v.target === comparisonNode.id)
                .concat(connection as never);
            this.resetNode(comparisonNode, edges);
        }

        const conditionNode = (targetNode.type === "condition" ? targetNode : null) as Node<ConditionNodeInfo>;
        if (conditionNode) {
            const edges = this.getConnectedEdges(conditionNode.id).filter(v => v.target === conditionNode.id)
                .concat(connection as never);
            this.resetNode(conditionNode, edges);
        }

        dd.edges = addEdge({
            ...connection,
            animated: true,
            type: "step",
            style: {
                ...getStylePropsForConnection(targetNode, sourceNode)
            },
            ...edgeOverride
        }, dd.edges);

        this.flushDesignerDataChange();
    }

    getIncomers(id: string): Node<NodeInfo>[] {
        const dd = this.designerData;
        const source = dd.nodes.find(v => v.id === id);
        if (!source) {
            return [];
        }
        return getIncomers(
            source,
            dd.nodes,
            dd.edges
        );
    }

    getConnectedEdges(id: string): Edge[] {
        const dd = this.designerData;
        const source = dd.nodes.find(v => v.id === id);
        if (!source) {
            return [];
        }
        return getConnectedEdges([source], dd.edges);
    }

    setOrderType(id: string, orderType: OrderType) {
        const node = this.designerData.nodes.find(v => v.id === id);
        if (!node?.data?.order) {
            return;
        }
        if (node.data.order.type === orderType) {
            return;
        }
        node.data.order.type = orderType;
        this.flushDesignerDataChange();
    }

    setGrouped(id: string, grouped: boolean) {
        const node = this.designerData.nodes.find(v => v.id === id);
        if (!node?.data?.selection) {
            return;
        }
        if (node.data.selection.grouped === grouped) {
            return;
        }
        node.data.selection.grouped = grouped;
        this.flushDesignerDataChange();
    }

    setAlias(id: string, alias: string) {
        const node = this.designerData.nodes.find(v => v.id === id);
        if (!node?.data?.selection) {
            return;
        }
        if (node.data.selection.alias === alias) {
            return;
        }
        node.data.selection.alias = alias;
        this.flushDesignerDataChange();
    }

    setConstantValue(id: string, value: string, notify?: boolean) {
        const node = this.designerData.nodes.find(v => v.id === id) as Node<ConstantNodeInfo>;
        if (node?.type !== "constant") {
            return;
        }

        node.data.value = value;
        node.data.title = value;
        if (notify) {
            this.flushDesignerDataChange();
        }
    }

    deleteSelection(sourceId: string, selectionId: string) {
        const node = this.designerData.nodes.find(v => v.id === sourceId);
        if (!node) {
            return;
        }
        delete node.data.selection;
        this.deleteEdge(sourceId, selectionId);
    }

    deleteOrder(sourceId: string, orderId: string) {
        const node = this.designerData.nodes.find(v => v.id === sourceId);
        if (!node) {
            return;
        }
        delete node.data.order;
        this.deleteEdge(sourceId, orderId);
    }

    deleteEdge(sourceId: string, targetId: string) {
        this.resetNodes("binary", v => v.source !== sourceId);
        this.resetNodes("comparison", v => v.source !== sourceId);
        this.resetNodes("condition", v => v.source !== sourceId);
        const dd = this.designerData;
        const first = dd.edges.find(v => v.source === sourceId && v.target === targetId);
        dd.edges = dd.edges.filter(q => q !== first);
        this.flushDesignerDataChange();
    }

    deleteEdgeObj(edge?: Edge) {
        if (!edge) {
            return
        }
        const dd = this.designerData;
        if (edge.targetHandle?.includes("|")) {
            const targetNode = dd.nodes.find(v => v.id === edge.target);
            if (targetNode && targetNode.type === "function") {
                const func = (targetNode as Node<FunctionNodeInfo>).data.object;
                const index = func.parameters.findIndex(v => v.name === edge.targetHandle);
                if (index > -1) {
                    func.parameters.splice(index, 1);
                }
            }
        }
        this.deleteEdge(edge.source, edge.target);
    }

    isGroupable(id: string): boolean {
        const node = this.getNode(id);
        const type = node?.type;
        if (type !== "function") {
            return true;
        }

        return !(node.data as FunctionNodeInfo).object.aggregate;
    }

    getNode<T extends NodeInfo>(id: string): Node<T> {
        return this.designerData.nodes.find(v => v.id === id) as never;
    }

    getNodesByType<T extends NodeInfo>(type: string): Node<T>[] {
        return this.designerData.nodes.filter(v => v.type === type) as never;
    }

    parseExpression(node: Node<NodeInfo>): CPQLExpression | undefined {
        if (!node) {
            return defaultExpression;
        }

        switch (node.type) {
            case "condition": {
                const edges = this.getConnectedEdges(node.id)
                    .filter(v => v.target === node.id)
                    .sort((a, b) => (a.targetHandle as string).localeCompare(b.targetHandle as string));

                if (edges.length < 2) {
                    return {
                        "@type": "ConstantExpression",
                        type: "BOOLEAN",
                        value: "FALSE"
                    } as CPQLConstantExpression;
                }

                const conditionNode = node as Node<ConditionNodeInfo>;
                const firstExp = {} as CPQLChainedConditionalExpression;

                let current = firstExp;
                while (edges.length) {
                    const edge = edges.shift() as Edge;
                    const left = this.parseExpression(this.getNode(edge.source)) as CPQLExpression;
                    current.right = edges.length ? {
                        "@type": "ChainedConditionalExpression",
                        operator: conditionNode.data.object.type,
                        left
                    } as CPQLChainedConditionalExpression : left;
                    current = current.right as never;
                }

                return {
                    "@type": "GroupExpression",
                    expression: firstExp.right
                } as CPQLGroupExpression;
            }
            case "comparison": {
                const comparisonNode = node as Node<ComparisonNodeInfo>;
                const edges = this.getConnectedEdges(node.id)
                    .filter(v => v.target === node.id)
                    .sort((a, b) => (a.targetHandle as string).localeCompare(b.targetHandle as string));

                if (edges.length < 1) {
                    return toGroupExpression(defaultExpression);
                }

                const operator = comparisonNode.data.object.type;
                switch (operator) {
                    case "IS_NULL":
                        return toGroupExpression({
                            "@type": "IsNullExpression",
                            expression: this.parseExpression(this.getNode(edges[0].source))
                        } as CPQLIsNullExpression);
                    case "IS_NOT_NULL":
                        return toGroupExpression({
                            "@type": "IsNotNullExpression",
                            expression: this.parseExpression(this.getNode(edges[0].source))
                        } as CPQLIsNotNullExpression);
                    case "IN":
                    case "NOT_IN": {
                        let array = {
                            "@type": "ArrayExpression",
                            expressions: edges.length > 1 ? edges.slice(1).map(v => this.parseExpression(this.getNode(v.source))) : [defaultExpression]
                        } as CPQLArrayExpression;

                        return toGroupExpression({
                            "@type": operator === "IN" ? "InExpression" : "NotInExpression",
                            left: this.parseExpression(this.getNode(edges[0].source)),
                            array
                        } as CPQLInExpressionBase);
                    }
                    default:
                        return toGroupExpression({
                            "@type": "ComparisonExpression",
                            operator,
                            left: this.parseExpression(this.getNode(edges[0].source)),
                            right: edges.length > 1 ? this.parseExpression(this.getNode(edges[1].source)) : defaultExpression
                        } as CPQLComparisonExpression);
                }
            }
            case "binary": {
                const edges = this.getConnectedEdges(node.id)
                    .filter(v => v.target === node.id)
                    .sort((a, b) => (a.targetHandle as string).localeCompare(b.targetHandle as string));

                if (edges.length < 2) {
                    return {
                        "@type": "ConstantExpression",
                        type: "NULL",
                        value: "null"
                    } as CPQLConstantExpression;
                }

                const binaryNode = node as Node<BinaryNodeInfo>;
                const firstExp = {} as CPQLBinaryExpression;

                let current = firstExp;
                while (edges.length) {
                    const edge = edges.shift() as Edge;
                    const left = this.parseExpression(this.getNode(edge.source)) as CPQLExpression;
                    current.right = edges.length ? {
                        "@type": "BinaryExpression",
                        operator: binaryNode.data.object.type,
                        left
                    } as CPQLBinaryExpression : left;
                    current = current.right as never;
                }

                return {
                    "@type": "GroupExpression",
                    expression: firstExp.right
                } as CPQLGroupExpression;
            }
            case "constant": {
                const constantNode = node as Node<ConstantNodeInfo>;
                return {
                    "@type": "ConstantExpression",
                    type: constantNode.data.object.type,
                    value: constantNode.data.value
                } as CPQLConstantExpression
            }
            case "column":
                return {
                    "@type": "ColumnNameExpression",
                    left: {
                        "@type": "NameExpression",
                        name: (node.data as ColumnNodeInfo).table?.name
                    },
                    right: {
                        "@type": "NameExpression",
                        name: node.data.object.name
                    }
                } as CPQLColumnNameExpression;
            case "function":
            {
                const func = node.data as FunctionNodeInfo;
                const edges = this.getConnectedEdges(node.id).filter(v => v.target === node.id)
                const parameters = func.object.parameters.map(param => {
                    const edge = edges.find(v => v.targetHandle === param.name);
                    if (!edge) {
                        return null;
                    }
                    const paramNode = this.getNode(edge.source);
                    if (!paramNode) {
                        return null;
                    }
                    return this.parseExpression(paramNode);
                });

                if (func.varArg && !parameters.at(-1)) {
                    parameters.pop();
                }

                return {
                    "@type": "CallExpression",
                    name: {
                        "@type": "NameExpression",
                        name: func.object.name
                    } as CPQLNameExpression,
                    parameters
                } as CPQLCallExpression
            }
        }
    }

    private parseChainedExpression(...nodes: Node<NodeInfo>[]): CPQLExpression | undefined {
        if (!nodes.length) {
            return undefined;
        }

        return nodes.reduce((prev, node) => {
            let exp = this.parseExpression(node);
            if (!exp) {
                return prev;
            }

            if (prev.exp) {
                exp = {
                    "@type": "ChainedConditionalExpression",
                    left: prev.exp,
                    right: exp,
                    operator: "AND"
                } as CPQLChainedConditionalExpression;
            }

            prev.exp = exp;
            return prev;
        }, {} as {exp?: CPQLExpression}).exp;
    }

    createQueryAst(): CPQLParsedQuery | null {
        const nodes = this.designerData.nodes;

        const selectedNodes = nodes.filter(v => Boolean(v.data.selection))
            .sort((a, b) =>
                (a.data.selection as SelectionInfo).index - (b.data.selection as SelectionInfo).index);

        if (!selectedNodes.length) {
            return null;
        }

        const columns: CPQLAliasableExpression[] = selectedNodes.map(node => {
            let expression = this.parseExpression(node);

            if (!expression) {
                throw new Error("Not implemented");
            }

            let alias = node.data.selection?.alias || this.getTitle(node);
            if (alias) {
                alias = `\`${alias.replaceAll("`", "\\`")}\``;
            }
            return {
                "@type": "AliasableExpression",
                grouped: Boolean(node.data.selection?.grouped),
                alias: alias ? {
                    "@type": "NameExpression",
                    name: alias
                } : undefined,
                expression
            } as CPQLAliasableExpression;
        });

        const tables: CPQLAliasableExpression[] = this.designerData.nodes
            .filter(v => v.type === "table")
            .map(table => ({
                "@type": "AliasableExpression",
                alias: table.data.alias ? {
                    "@type": "NameExpression",
                    name: (table.data as TableNodeInfo).alias
                } : undefined,
                expression: {
                    "@type": "ColumnNameExpression",
                    left: {
                        "@type": "NameExpression",
                        name: table.data.object.name
                    }
                } as CPQLColumnNameExpression
            } as CPQLAliasableExpression));

        const orderedNodes = nodes.filter(v => Boolean(v.data.order))
            .sort((a, b) =>
                (a.data.order as OrderInfo).index - (b.data.order as OrderInfo).index);

        const orders: CPQLOrderExpression[] = orderedNodes.map(node => {
            let expression = this.parseExpression(node);

            if (!expression) {
                throw new Error("Not implemented");
            }

            return {
                "@type": "OrderExpression",
                type: node.data.order?.type as never,
                expression
            } as CPQLOrderExpression
        });

        const rowFilterExp = this.parseChainedExpression(...this.getIncomers("rowFilter"));
        const groupFilterExp = this.parseChainedExpression(...this.getIncomers("groupFilter"));

        let rowFilter: CPQLWhereStatement | undefined;
        let groupFilter: CPQLHavingStatement | undefined;

        if (rowFilterExp) {
            rowFilter = {
                "@type": "WhereStatement",
                expression: rowFilterExp
            };
        }

        if (groupFilterExp) {
            groupFilter = {
                "@type": "HavingStatement",
                expression: groupFilterExp
            };
        }

        return {
            columns: {
                "@type": "ExpressionListStatement",
                expressions: columns
            },
            tables: {
                "@type": "ExpressionListStatement",
                expressions: tables
            },
            order: {
                "@type": "ExpressionListStatement",
                expressions: orders
            },
            rowFilter,
            groupFilter
        }
    }

    getSelectionFields(): string[] {
        const edges = this.getConnectedEdges("selection");
        return edges.map(v => this.getNode(v.source))
            .filter(Boolean)
            .sort((a, b) => (a.data.selection?.index ?? 0) - (b.data.selection?.index ?? 0))
            .map((v, index) =>
                v.data.selection?.alias || this.getTitle(v as never) || String(index)
            );
    }
}
