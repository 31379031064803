import React, {useEffect, useState} from "react";
import {Alert, Card} from "react-bootstrap";
import Animator from "../../utils/Animator";
import {useNumberFormat} from "../../hooks/useNumberFormat";
import classNames from "classnames";
import {useQueryExecutionDispatcher} from "../../context/QueryExecutionContext";
import {useQueryDispatcher} from "../query-editor/context/QueryEditorContext";
import useLanguage from "../../hooks/useLanguage";
import {LocalizedString} from "../MultiLangStringEditor";

import "./counter.scss";

export default function Counter(): JSX.Element {
    const [language] = useLanguage() as [string, any, any];
    const [node, setNode] = useState<HTMLSpanElement | null>(null);
    const [numberFormat] = useNumberFormat();
    const [state] = useQueryExecutionDispatcher();
    const [, queryDispatcher] = useQueryDispatcher();

    const field = queryDispatcher.getProp<number>("field") || 0;
    const format = queryDispatcher.getProp<LocalizedString>("format")?.[language] || "(value) (name)"
    const value = Number(state.result?.data?.[0]?.[field] || 0);
    const name = state.result?.fields?.[field]?.name || "";

    const icon = queryDispatcher.getProp<string>("icon");
    const title = queryDispatcher.getProp<LocalizedString>("title")?.[language];
    const description = queryDispatcher.getProp<LocalizedString>("description")?.[language]
    const theme = queryDispatcher.getProp<string>("theme") || "primary";

    useEffect(() => {
        if (!node) {
            return;
        }

        function formatValue(value: number): string {
            return format.replaceAll("(value)", numberFormat.format(value))
                .replaceAll("(name)", name);
        }

        const current = Number(node.dataset.value) || 0;
        const diff = value - current;
        if (!diff) {
            node.innerText = formatValue(current);
            return;
        }

        const init = current;
        const animator = new Animator(
             1500,
            Animator.easeOutExpo,
            percent => {
                const current = Math.ceil(init + percent * (value - init));
                node.dataset.value = current as never;
                node.innerText = formatValue(current);
            }
        );

        animator.start();

        return () => animator.stop();
    }, [name, format, value, node, numberFormat])

    return <Card className="counter">
        <Card.Body className="d-flex flex-column">
            <Alert className="small-alert title d-flex gap-2 align-items-center" variant={theme}>
                <span className="icon">
                    <i className={classNames("bi", icon)}></i>
                </span>
                <div>
                    <Alert.Heading>
                        {title}
                    </Alert.Heading>
                    {description}
                </div>
            </Alert>
            <span className="value flex-grow-1 flex-fill d-flex align-items-center gap-2">
                <span ref={setNode}>0</span>
            </span>
        </Card.Body>
    </Card>
}
