import React, {useEffect, useState} from "react";
import {Alert, Card} from "react-bootstrap";
import Animator from "../utils/Animator";
import {Variant} from "react-bootstrap/types";
import {StatsResult} from "../api/stats/types";
import {useNumberFormat} from "../hooks/useNumberFormat";
import useAttribute from "../hooks/useAttribute";
import StatDispatcher from "../dispatcher/StatDispatcher";

import "./numeric-stat-viewer.scss";
import classNames from "classnames";
import Dispatcher from "../dispatcher/Dispatcher";

export interface NumericStatViewerProps {
    title: React.ReactNode
    description: React.ReactNode
    icon: React.ReactNode
    statKey: keyof StatsResult
    variant?: Variant
    className?: string
    dispatcher?: Dispatcher<StatsResult>
    loading?: boolean
    ratioKey?: string
    ratioTitle?: string
    currency?: boolean
}

export default function NumericStatViewer(
    {
        title,
        description,
        icon,
        statKey,
        variant,
        className,
        dispatcher,
        loading,
        ratioKey,
        ratioTitle,
        currency
    }: React.PropsWithoutRef<NumericStatViewerProps>
): JSX.Element {
    const [node, setNode] = useState(null as (HTMLSpanElement | null));
    const [ratioNode, setRatioNode] = useState(null as (HTMLSpanElement | null));
    const [numberFormat] = useNumberFormat(currency);
    const value = useAttribute(dispatcher ?? StatDispatcher.instance, statKey) as number ?? 0;
    const ratio = useAttribute(dispatcher ?? StatDispatcher.instance, ratioKey ?? "_") as number ?? 0;

    useEffect(() => {
        if (!node) {
            return;
        }

        const current = Number(node.dataset.value ?? 0);
        const diff = value - current;
        if (!diff) {
            node.innerText = numberFormat?.format(value) ?? String(value);
            if (ratioNode && ratioTitle && value) {
                ratioNode.innerText = `${ratioTitle}: ${numberFormat?.format(ratio)} (${(ratio / value).toFixed(2)}%)`;
            }
            return;
        }

        const diffAbs = Math.abs(diff);
        const valueAbs = Math.abs(value);

        const animator = new Animator(
            Math.min(valueAbs, diffAbs) / Math.max(valueAbs, diffAbs) * 2500,
            Animator.easeOutExpo,
            percent => {
                const current = Math.ceil(percent * value);
                const strCurrent = String(current);
                node.dataset.value = strCurrent;
                node.innerText = numberFormat?.format(current) ?? strCurrent;
                if (ratioNode && ratioTitle && current) {
                    ratioNode.innerText = `${ratioTitle}: ${numberFormat?.format(Math.ceil(percent * ratio))} (${(ratio / current * 100).toFixed(2)}%)`;
                }
            }
        );

        animator.start();

        return () => animator.stop();
    }, [node, ratioNode, ratio, value, numberFormat, ratioTitle])

    return <Card className={classNames("numeric-stat-viewer", className)}>
        <Card.Body className="d-flex flex-column">
            <Alert className="alert title d-flex gap-2 align-items-center" variant={variant ?? "info"}>
                <span className="icon fs-1">{icon}</span>
                <div>
                    <Alert.Heading className="mb-2 fw-bold">
                        {title}
                    </Alert.Heading>
                    {description}
                </div>
            </Alert>
            {
                loading && <div className="d-flex flex-fill flex-grow-1 align-items-center justify-content-center">
                    <div className="spinner-border"></div>
                </div>
            }
            <span className={classNames("value flex-grow-1 flex-fill justify-content-center align-items-center flex-column", {"d-none": loading})}>
                <span ref={setNode}>0</span>
                <span ref={setRatioNode} className={classNames("fs-5", {"d-none": !ratioTitle, ["text-" + variant]: true})}></span>
            </span>
        </Card.Body>
    </Card>
}
