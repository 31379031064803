import EzBiApiMethod from "../EzBiApiMethod";
import {AuthenticateResponse} from "./types";
import type {
    Dictionary
} from "ez-api-client/dist/api/types";

export interface AuthenticateRequest extends Dictionary {
    username: string
    password: string
}

export class Authenticate extends EzBiApiMethod<AuthenticateRequest, AuthenticateResponse> {
    static readonly instance: Authenticate = new Authenticate();
    readonly isPrivate = false;
    readonly method = "POST";

    get path(): string {
        return "auth/authenticate";
    }
}

export default Authenticate;
