import {LegendType} from "../types";

export enum AreaType {
    basis = "basis",
    basisClosed = "basisClosed",
    basisOpen = "basisOpen",
    bumpX = "bumpX",
    bumpY = "bumpY",
    bump = "bump",
    linear = "linear",
    linearClosed = "linearClosed",
    natural = "natural",
    monotoneX = "monotoneX",
    monotoneY = "monotoneY",
    monotone = "monotone",
    step = "step",
    stepBefore = "stepBefore",
    stepAfter = "stepAfter"
}

export interface AreaSettings {
    dataKey: string
    type: AreaType
    legendType: LegendType
    connectNulls: boolean
}
