import React from "react";
import {FloatingEdge} from "./FloatingEdge";
import {TableNode} from "./TableNode";
import {ColumnNode} from "./ColumnNode";
import {SelectionNode} from "./SelectionNode";
import {OrderNode} from "./OrderNode";
import {GroupFilterNode} from "./GroupFilterNode";
import {RowFilterNode} from "./RowFilterNode";
import {FunctionNode} from "./FunctionNode";
import {ConstantNode} from "./ConstantNode";
import {BinaryNode} from "./BinaryNode";
import {ComparisonNode} from "./ComparisonNode";
import {
    CPQLBinaryOperator,
    CPQLComparisonOperator,
    CPQLConditionalOperator,
    CPQLConstantType
} from "../../../api/cpql/types";
import ConditionNode from "./ConditionNode";

export const rightHandleStyle: React.CSSProperties = {
    right: "-1em",
    width: ".6em",
    height: ".6em"
}
export const leftHandleStyle: React.CSSProperties = {
    left: "-1em",
    width: ".6em",
    height: ".6em"
}
export const topHandleStyle: React.CSSProperties = {
    top: "-1em",
    width: ".6em",
    height: ".6em"
}
export const bottomHandleStyle: React.CSSProperties = {
    bottom: "-1em",
    width: ".6em",
    height: ".6em"
}
export const hiddenHandleStyle: React.CSSProperties = {
    opacity: 0
}
export const edgeTypes = {
    floating: FloatingEdge,
};
export const nodeTypes = {
    table: TableNode,
    column: ColumnNode,
    selection: SelectionNode,
    order: OrderNode,
    groupFilter: GroupFilterNode,
    rowFilter: RowFilterNode,
    function: FunctionNode,
    constant: ConstantNode,
    binary: BinaryNode,
    comparison: ComparisonNode,
    condition: ConditionNode
}

export const comparisonOperators: CPQLComparisonOperator[] = ["EQ", "GT", "GTE", "NEQ", "LT", "LTE", "IN", "NOT_IN", "LIKE", "NOT_LIKE", "IS_NULL", "IS_NOT_NULL"];
export const constantTypes: CPQLConstantType[] = ["STRING", "INTEGER", "DECIMAL", "BOOLEAN", "DATE", "TIME", "DATETIME", "NULL"];
export const arithmeticOperators: CPQLBinaryOperator[] = ["ADD", "SUB", "MUL", "DIV", "POW", "MOD"];
export const binaryOperators: CPQLBinaryOperator[] = ["LSH", "RSH", "AND", "OR"];
export const allBinaryOperators: CPQLBinaryOperator[] = arithmeticOperators.concat(binaryOperators);
export const conditionalOperators: CPQLConditionalOperator[] = ["AND", "OR"];

