import Dispatcher from "./Dispatcher";
import {MeResponse} from "../api/session/types";
import LoggedInDispatcher from "./LoggedInDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import TokenDispatcher from "./TokenDispatcher";

const EMPTY: MeResponse = Object.freeze({}) as never;

export default class LoggedInUserDispatcher extends Dispatcher<MeResponse> {
    static readonly instance: LoggedInUserDispatcher = new LoggedInUserDispatcher(EMPTY);
}

LoggedInDispatcher.instance.subscribe(loggedIn => {
    if (!loggedIn) {
        LoggedInUserDispatcher.instance.update(EMPTY);
        return;
    }

    EzBiApiClient.instance.session.me()
        .then(result => result.succeed())
        .then(LoggedInUserDispatcher.instance.update)
        .catch(err => {
            if (err?.httpCode === 401) {
                TokenDispatcher.reset();
            }
        });
})
