import EzBiApiMethod from "../EzBiApiMethod";
import {StatsByResult, StatsRequest} from "./types";

export class GetStatsBy extends EzBiApiMethod<StatsRequest, StatsByResult> {
    static readonly instance: GetStatsBy = new GetStatsBy();
    readonly isPrivate = true;
    readonly method = "POST";

    get path(): string {
        return "stats";
    }
}

export default GetStatsBy;
