import {InputComponentProps} from "../types";
import React, {useEffect, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import enumKeys from "../utils/enumKeys";

export enum TimeUnit {
    SECONDS = "SECONDS",
    MINUTES = "MINUTES"
}

export interface Frequency {
    active: boolean
    value: number
    type: TimeUnit
}

export interface ShortPeriodPickerProps extends InputComponentProps<Frequency> {

}

export default function ShortPeriodPicker({id, value, onInput}: React.PropsWithoutRef<ShortPeriodPickerProps>) {
    const [t] = useTranslation();
    const [v, setValue] = useState<Frequency>(() => ({...value} as never));

    function update(freq: Partial<Frequency>) {
        Object.assign(v, freq);
        if (onInput instanceof Function) {
            onInput(v);
        }
        setValue({...v});
    }
    useEffect(() => {
        setValue({...value} as never);
    }, [value]);

    return <InputGroup size="sm">
        <InputGroup.Text>
            <Form.Check checked={Boolean(v.active)} onChange={e => update({active: e.currentTarget.checked})} />
        </InputGroup.Text>
        <Form.Control type="number" value={v.value ?? 30} id={id} min={1} max={60} step={1}
                      onInput={e => update({value: (e.currentTarget as HTMLInputElement).valueAsNumber | 0})}
                      className="w-auto" disabled={!v.active}/>
        <Form.Select className="text-capitalize w-auto flex-grow-0" value={v.type ?? TimeUnit.SECONDS}
                     onInput={e => update({type: e.currentTarget.value as never})} disabled={!v.active}>
            {
                enumKeys(TimeUnit).map(key => <option key={key} value={key}>
                    {t(`common.labels.TU_${key}`, key.toLowerCase().replace(/_+/g, " "))}
                </option>)
            }
        </Form.Select>
    </InputGroup>
}
