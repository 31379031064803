import Dispatcher from "../dispatcher/Dispatcher";
import {useEffect, useState} from "react";

type DispatcherParam<T> = T extends Dispatcher<infer Q> ? Q : never;

export type UseDispatcherResult<D extends Dispatcher<any>, T extends DispatcherParam<D> | undefined, V = T> = [V, (v?: T) => void, D];
export type UseDispatcherMappedResult<D extends Dispatcher<any>, T extends DispatcherParam<D> | undefined, V = T> = [V, D];


export default function useDispatcher<D extends Dispatcher<any>, T extends DispatcherParam<D> | undefined>(
    dispatcherInstance: D
): UseDispatcherResult<D, T> {
    const [value, setValue] = useState(dispatcherInstance.value);

    useEffect(() => {
        return dispatcherInstance.subscribe(setValue as never);
    }, [dispatcherInstance]);

    return [value, (v?: T) => dispatcherInstance.update(v), dispatcherInstance] as never;
}

export function useDispatcherMapped<D extends Dispatcher<any>, T extends DispatcherParam<D> | undefined, V>(
    dispatcherInstance: D,
    mapper: (d: D) => V | undefined
): UseDispatcherMappedResult<D, T, V> {
    const [value, setValue] = useState(mapper(dispatcherInstance));

    useEffect(() => {
        return dispatcherInstance.subscribeMapped(
            mapper,
            setValue as never
        );
    }, [mapper, dispatcherInstance]);

    return [value, dispatcherInstance] as never;
}
