import {InputComponentProps} from "../../../types";
import React, {useEffect, useMemo} from "react";
import useTranslation from "../../../hooks/useTranslation";
import {AreaSettings, AreaType} from "./AreaSettings";
import objectKey from "../../../utils/objectKey";
import EnumDropdown from "../../EnumDropdown";
import {LegendType} from "../types";
import ObjectDispatcher from "../../../dispatcher/ObjectDispatcher";

export interface AreaSettingsControlProps extends InputComponentProps<AreaSettings> {

}

const DEFAULT: AreaSettings = {
    dataKey: "uv",
    type: AreaType.linear,
    legendType: LegendType.line,
    connectNulls: false,
};

export default function AreaSettingsControl({value, onInput, id}: AreaSettingsControlProps) {
    const [t] = useTranslation();
    const stateDispatcher = useMemo(() => new ObjectDispatcher<AreaSettings>({...DEFAULT} as never), []);

    useEffect(() => {
        if (!onInput) {
            return;
        }

        return stateDispatcher.subscribe(v => onInput(v, id));
    }, [stateDispatcher, onInput, id]);

    useEffect(() => {
        stateDispatcher.update({
            ...DEFAULT,
            ...value
        });
        // eslint-disable-next-line
    }, [objectKey(value), stateDispatcher]);

    const state: AreaSettings = stateDispatcher.value as never;

    return <div className="w-100 grid-two-columns gap-2">
        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.type", "Type")}
        </label>
        <EnumDropdown value={state.type} onChange={stateDispatcher.asUpdater("type", e => e) as never}
                      enumObj={AreaType} translationCategory="charts.components.areaType" dropdownToggleSize="sm" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.connectNulls", "Connect Nulls")}
        </label>
        <input type="checkbox" className="form-check-input" checked={state.connectNulls}
               onChange={stateDispatcher.asUpdater("connectNulls", e => e.currentTarget.checked)}/>

    </div>
}
