import {StateButtonState} from "./types";

export const REM = Number((window.getComputedStyle(document.documentElement).fontSize || "16px").replace(/\D+/g, ""));

export const horizontalAlignStates: StateButtonState[] = [
    {
        key: "left",
        icon: "align-start"
    },
    {
        key: "center",
        icon: "align-center"
    },
    {
        key: "right",
        icon: "align-end"
    }
];

export const verticalAlignStates: StateButtonState[] = [
    {
        key: "top",
        icon: "align-top"
    },
    {
        key: "middle",
        icon: "align-middle"
    },
    {
        key: "bottom",
        icon: "align-bottom"
    }
];

export const hAlignStates: StateButtonState[] = [
    {
        key: "start",
        icon: "align-top"
    },
    {
        key: "center",
        icon: "align-middle"
    },
    {
        key: "end",
        icon: "align-bottom"
    }
];

export const vAlignStates: StateButtonState[] = [
    {
        key: "start",
        icon: "align-top"
    },
    {
        key: "center",
        icon: "align-middle"
    },
    {
        key: "end",
        icon: "align-bottom"
    },
    {
        key: "between",
        icon: "justify"
    }
];

export const justifyStates: StateButtonState[] = [
    {
        key: "start",
        icon: "justify-left"
    },
    {
        key: "center",
        icon: "text-center"
    },
    {
        key: "end",
        icon: "justify-right"
    },
    {
        key: "between",
        icon: "justify"
    }
];

export const distributionStates: StateButtonState[] = [
    {
        key: "row",
        icon: "distribute-vertical"
    },
    {
        key: "column",
        icon: "distribute-horizontal"
    }
];
