import isMobile from "./utils/isMobile";

let oldTimer = 0;
const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
        const target = entry.target as HTMLElement;
        const width = (
            entry.borderBoxSize?.[0]?.inlineSize ??
            entry.contentBoxSize?.[0]?.inlineSize ??
            entry.contentRect?.width ??
            0);

        if (!width) {
            continue;
        }

        const targetWidth = 2145;

        cancelAnimationFrame(oldTimer);
        oldTimer = requestAnimationFrame(() => {
            target.style.setProperty("font-size", `${(width / targetWidth * 14).toFixed(4)}px`, "important");
        });
        break;
    }
});

export default function init(target: HTMLElement) {
    if (isMobile()) {
        target.style.removeProperty("font-size")
        return;
    }

    resizeObserver.observe(target);
};
