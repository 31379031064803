import React, {forwardRef, Ref, useCallback, useRef} from "react";
import {Button, Form, FormControlProps, InputGroup} from "react-bootstrap";
import If from "./If";

function ClearableInputWithoutRef({disabled, size, ...props}: FormControlProps, ref: Ref<any>) {
    const refObj = useRef<HTMLInputElement | null>(null);
    const currentRef = useCallback((node: HTMLInputElement) => {
        refObj.current = node;
        if (ref instanceof Function) {
            ref(node);
        }
    }, [ref, refObj]);

    const onClick = useCallback(() => {
        if (!refObj.current) {
            return;
        }
        const inputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        inputSetter?.call(refObj.current, "");
        refObj.current?.dispatchEvent(new Event("input", {bubbles: true}))
    }, []);

    return <InputGroup size={size}>
        <Form.Control {...props} ref={currentRef} disabled={disabled} />
        <If condition={Boolean(refObj.current?.value)}>
            <Button variant="outline-secondary" onClick={onClick} disabled={disabled}>
                <i className="bi bi-x-circle-fill"></i>
            </Button>
        </If>
    </InputGroup>
}

const ClearableInput = forwardRef(ClearableInputWithoutRef);

export default ClearableInput;
