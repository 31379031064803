import useTranslation from "../../hooks/useTranslation";
import {useQueryEditorContext} from "./context/QueryEditorContext";
import {QueryItemList} from "./QueryItemList";
import If from "../If";
import {QueryDesigner} from "./QueryDesigner";
import {QueryContentEditor} from "./QueryContentEditor";
import React, {useCallback} from "react";
import {QueryRawResults} from "./QueryRawResults";
import presentationTypeControls from "./presentationTypeControls";
import {Template} from "./flow/Template";
import {useDispatcherMapped} from "../../hooks/useDispatcher";
import QueryDispatcher from "./QueryDispatcher";
import QueryListDispatcher from "../../dispatcher/QueryListDispatcher";

export function QueryManagerBody() {
    const [t] = useTranslation();
    const [type] = useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => d.type, [])
    );

    const Control = presentationTypeControls[type] ?? Template;

    return <>
        <div className="resize-handle">
            <i className="bi bi-three-dots-vertical"></i>
        </div>
        <div className="right-pane resizable vertical">
            <QueryDesigner/>
            <div className="resize-handle">
                <i className="bi bi-three-dots"></i>
            </div>
            <div className="editor resizable horizontal">
                <div className="preview card shadow" data-resize-key="queryEditor.preview" data-resize-initial=".5">
                    <div className="card-header d-flex align-items-center">
                        <div className="fw-bold small">{t("pages.queryEditor.preview", "Preview")}</div>
                        <div className="m-auto"></div>
                    </div>
                    <div className="card-body">
                        <Control />
                    </div>
                </div>
                <div className="resize-handle">
                    <i className="bi bi-three-dots-vertical"></i>
                </div>
                <div className="resizable vertical">
                    <QueryContentEditor className="cpql-editor card shadow" data-resize-key="queryEditor.queryEditor.query"
                                        data-resize-initial=".5" />
                    <div className="resize-handle">
                        <i className="bi bi-three-dots"></i>
                    </div>
                    <QueryRawResults />
                </div>
            </div>
        </div>
    </>
}

export function QueryManager() {
    const [hasItems] = useDispatcherMapped(
        QueryListDispatcher.instance,
        useCallback((d: QueryListDispatcher) => d.hasItems, [])
    );

    return <div className="query-editor-container resizable horizontal user-select-none">
        <QueryItemList className="card shadow" data-resize-key="queryEditor.queryItemList"/>
        <If condition={hasItems}>
            <QueryManagerBody />
        </If>
    </div>
}
