import EzBiApiMethod from "../EzBiApiMethod";
import {CPQLExecRequest, CPQLExecResponse} from "./types";

export class Exec extends EzBiApiMethod<CPQLExecRequest, CPQLExecResponse> {
    static readonly instance: Exec = new Exec();
    readonly isPrivate = true;
    readonly method = "POST";

    get cacheable(): boolean {
        return true;
    }

    get path(): string {
        return "cpql/exec";
    }
}

export default Exec;
