import {Query} from "../../api/session/types";

export type DeleteQueryUpdater = (query: Query) => void;

export enum SortType {
    NONE,
    AZ,
    ZA,
    NA,
    ND
}

export enum PresentationPropType {
    STRING,
    ICON,
    FREQUENCY,
    THEME,
    FIELD,
    CHART_COMPONENTS
}

export interface PresentationProp extends Record<string, any>{
    key: string
    name: string
    type: PresentationPropType
    placeholder?: string
    showLabel?: boolean
}

export enum PresentationType {
    TABLE = "TABLE",
    LINE_CHART = "LINE_CHART",
    AREA_CHART = "AREA_CHART",
    BAR_CHART = "BAR_CHART",
    SCATTER_CHART = "SCATTER_CHART",
    PIE_CHART = "PIE_CHART",
    RADAR_CHART = "RADAR_CHART",
    RADIAL_BAR_CHART = "RADIAL_BAR_CHART",
    COMPOSED_CHART = "COMPOSED_CHART",
    TREE_MAP = "TREE_MAP",
    COUNTER = "COUNTER"
}
