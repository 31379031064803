export type EasingFunction = (time: number, duration: number) => number;
export type AnimationCallback = (value: number) => void

export default class Animator {
    private frameRequest: number = 0;
    private begin: number = 0;

    constructor(private readonly durationInMs: number,
                private readonly easingFunction: EasingFunction,
                private readonly callback: AnimationCallback) {
        this.nextFrame = this.nextFrame.bind(this);
    }

    private nextFrame(time: DOMHighResTimeStamp) {
        if (!this.begin) {
            this.begin = time;
        }

        let diff = time - this.begin;
        let next = true;

        if (diff > this.durationInMs) {
            diff = this.durationInMs;
            next = false;
        }

        this.callback(this.easingFunction(diff, this.durationInMs));

        if (next) {
            this.frameRequest = requestAnimationFrame(this.nextFrame);
        }
    }

    public start() {
        if (this.frameRequest) {
            return;
        }

        this.begin = 0;
        this.frameRequest = requestAnimationFrame(this.nextFrame);
    }

    public stop() {
        if (!this.frameRequest) {
            return;
        }

        cancelAnimationFrame(this.frameRequest);
        this.frameRequest = 0;
    }

    static easeOutCubic(t: number, d: number): number {
        return ((t = t / d - 1) * t * t + 1);
    }
    static easeOutExpo (t: number, d: number) {
        return (t === d) ? 1 : (-Math.pow(2, -10 * t / d) + 1);
    }
}
