import {useQueryEditorContext} from "./context/QueryEditorContext";
import React, {FormEvent, HTMLAttributes, useCallback, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import useTranslation from "../../hooks/useTranslation";

function Header() {
    const [t] = useTranslation();

    return <div className="card-header d-flex align-items-center">
        <div className="fw-bold small">{t("pages.queryEditor.cpqlEditor", "CPQL Editor")}</div>
        <div className="m-auto"></div>
    </div>;
}

export function QueryContentEditor(props: React.PropsWithoutRef<HTMLAttributes<HTMLDivElement>>) {
    const dispatcher = useQueryEditorContext();
    const [value, setValue] = useState(() => dispatcher.value?.query ?? "");

    useEffect(() => {
        return dispatcher.subscribe(value => {
            setValue(value?.query ?? "");
        });
    }, [dispatcher]);

    const updateQueryContent = useCallback((e: FormEvent<HTMLTextAreaElement>) => {
        dispatcher.ifPresent(v => {
            const next = e.currentTarget.value;
            if (v.query !== next) {
                v.query = next;
                setValue(next);
                return true;
            }
            return false;
        });
    }, [dispatcher]);

    useEffect(() => {
        setValue(dispatcher.value?.query ?? "");
    }, [dispatcher.value]);

    return <div {...props}>
        <Header />
        <Form.Control as="textarea" className="form-control h-100 no-resize" value={value}
                      onInput={updateQueryContent} spellCheck={false}/>
    </div>
}
