import {CPQLExecRequest, CPQLExecResponse} from "../api/cpql/types";
import EzBiApiClient from "../api/EzBiApiClient";
import ApiDispatcher, {ApiState} from "../dispatcher/ApiDispatcher";
import equals from "../utils/equals";

export type QueryExecutionState = ApiState<CPQLExecRequest, CPQLExecResponse>;

function getEmpty(): ApiState<CPQLExecRequest, CPQLExecResponse> {
    return {
        request: {
            query: "",
            offset: 0,
            limit: 50,
            parameters: {}
        }
    } as never;
}

export default class QueryExecutionDispatcher extends ApiDispatcher<CPQLExecRequest, CPQLExecResponse> {

    constructor() {
        super(EzBiApiClient.instance.cpql.exec as never, getEmpty());
        this.exec = this.exec.bind(this);
        this.setOffset = this.setOffset.bind(this);
    }

    exec(query: string, noWait?: boolean) {
        if (!query) {
            query = "";
        }
        query = query.trim();
        if (!query) {
            this.abort();
            return;
        }

        return this.call({
            ...this.value?.request,
            query
        } as CPQLExecRequest, noWait)
    }

    setOffset(offset: number) {
        const value = this.value as QueryExecutionState;
        if (equals(value.request.offset, offset) || (!value.request.offset && !offset)) {
            return;
        }

        value.request.offset = offset;
        const query = value.request.query
        if (!query) {
            return;
        }
        this.exec(query, false);
    }
}
