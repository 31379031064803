import React, {useEffect, useState} from "react";
import useTranslation from "../../../hooks/useTranslation";
import useDispatcher from "../../../hooks/useDispatcher";
import CPQLMappingDispatcher from "../../../dispatcher/CPQLMappingDispatcher";
import {Accordion, Tab, Tabs} from "react-bootstrap";
import {Template} from "./Template";
import {CPQLFunctionInfo, CPQLMappingResponse, CPQLType} from "../../../api/cpql/types";
import {
    arithmeticOperators,
    binaryOperators,
    comparisonOperators,
    conditionalOperators,
    constantTypes
} from "./constants";
import {useQueryEditorContext} from "../context/QueryEditorContext";

const onDragStart = (props: object, event: React.DragEvent<HTMLElement>) => {
    event.dataTransfer.setData('application/cpql', JSON.stringify(props));
    event.dataTransfer.effectAllowed = 'move';
};

type GroupedFunctions = Record<string, CPQLFunctionInfo[]>;

function typeToGroup(type: CPQLType, any?: string): string {
    switch (type) {
        case CPQLType.ANY:
            return any ?? type;
        case CPQLType.BOOLEAN:
            return "CONDITIONAL";
        case CPQLType.DATE:
        case CPQLType.DATETIME:
        case CPQLType.TIME:
            return "DATE"
        case CPQLType.NUMBER:
            return "NUMERIC";
        case CPQLType.STRING:
            return "STRING";
    }
}

function getFunctionGroup(func: CPQLFunctionInfo): string {
    if (func.aggregate) {
        return "AGGREGATE"
    }

    if (!func.parameters.length) {
        return typeToGroup(func.returnType, "UTILITY");
    }

    if (func.parameters[0].type === "ANY") {
        return typeToGroup(func.returnType, "UTILITY");
    }

    return typeToGroup(func.parameters[0].type, "UTILITY");
}

function toString(func: CPQLFunctionInfo, t: (...args: any[]) => any): string {
    return `${t(`mapping.function.${func.name}`, func.name)}(${
        func.parameters.map((param, idx, ary) => {
            let prefix = "";
            if (idx + 1 === ary.length && func.varArgs) {
                prefix = "...";
            }
            return `${prefix}${t(`mapping.name.${param.name}`, param.name)}: ${t(`mapping.type.${param.type}`, param.type)}`;
        }).join(", ")
    }): ${t(`mapping.type.${func.returnType}`, func.returnType)}`;
}

interface TableTabProps {
    mapping?: CPQLMappingResponse
}

function TableTab({mapping}: TableTabProps) {
    const [t] = useTranslation();
    const dispatcher = useQueryEditorContext();
    const [tables, setTables] = useState(() => mapping?.tables ?? []);

    useEffect(() => {
        return dispatcher.subscribeMapped(
            v => v.getNodesByType("table"),
            nodes => {
                const names = nodes?.map(v => v.data.object.name) ?? [];
                setTables((mapping?.tables ?? []).filter(v => !names.includes(v.name)));
            },
            true
        );
    }, [dispatcher, mapping?.tables]);

    if (!tables.length) {
        return <div className="alert alert-info small m-2">
            {t("pages.queryEditor.allTablesAdded", "All tables has been added to the designer.")}
        </div>;
    }

    return <>
        {
            tables.map(table =>
                <button key={table.name}
                        className="btn btn-link mapping-item"
                        onDragStart={e => onDragStart({type: "table", object: table}, e)}
                        draggable="true" onClick={e => e.preventDefault()}>
                    <span className="name">{t(`mapping.table.${table.name}.name`, table.name)}</span>
                    <span className="description">{t(`mapping.table.${table.name}.desc`, "")}</span>
                </button>)
        }
    </>;
}

export function QueryDesignerPanel() {
    const [t] = useTranslation();
    const [mapping] = useDispatcher(CPQLMappingDispatcher.instance);
    const [tabId, setTabId] = useState("tables");
    const [funcFilter, setFuncFilter] = useState("");
    const funcFilterTrimmed = funcFilter.trim().toLowerCase();
    const groupedFunctions: GroupedFunctions = (mapping?.functions ?? []).reduce((prev, cur) => {
        if (funcFilterTrimmed && !cur.name.toLowerCase().includes(funcFilterTrimmed)) {
            return prev;
        }

        const group = getFunctionGroup(cur);
        if (!(group in prev)) {
            prev[group] = [cur];
        } else {
            prev[group].push(cur);
        }
        return prev;
    }, {} as GroupedFunctions);


    return <div className="panel">
        <Tabs activeKey={tabId} onSelect={setTabId as never} justify>
            <Tab title={t("mapping.EXPRESSIONS", "Expressions")} eventKey="expressions" className="p-3">
                <Accordion>
                    <Accordion.Item eventKey="constants">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_CONSTANT", "Constant Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                constantTypes.map(type => <button key={type}
                                    className="btn btn-link mapping-item"
                                    onDragStart={e => onDragStart({type: "constant", object: {type}}, e)}
                                    draggable="true" onClick={e => e.preventDefault()}
                                    title={t(`mapping.expression.constant.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.constant.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.constant.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="arithmetic">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_ARITHMETIC", "Arithmetic Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                arithmeticOperators.map(type => <button key={type}
                                                                  className="btn btn-link mapping-item"
                                                                  onDragStart={e => onDragStart({type: "binary", title: t(`mapping.expression.binary.${type}.name`, type), object: {type}}, e)}
                                                                  draggable="true" onClick={e => e.preventDefault()}
                                                                  title={t(`mapping.expression.binary.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.binary.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.binary.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="binary">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_BINARY", "Binary Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                binaryOperators.map(type => <button key={type}
                                                                        className="btn btn-link mapping-item"
                                                                        onDragStart={e => onDragStart({type: "binary", title: t(`mapping.expression.binary.${type}.name`, type), object: {type}}, e)}
                                                                        draggable="true" onClick={e => e.preventDefault()}
                                                                        title={t(`mapping.expression.binary.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.binary.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.binary.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="comparison">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_COMPARISON", "Comparison Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                comparisonOperators.map(type => <button key={type}
                                                                    className="btn btn-link mapping-item"
                                                                    onDragStart={e => onDragStart({type: "comparison", title: t(`mapping.expression.comparison.${type}.name`, type), object: {type}}, e)}
                                                                    draggable="true" onClick={e => e.preventDefault()}
                                                                    title={t(`mapping.expression.comparison.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.comparison.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.comparison.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="conditional">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_CONDITIONAL", "Conditional Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                conditionalOperators.map(type => <button key={type}
                                                                        className="btn btn-link mapping-item"
                                                                        onDragStart={e => onDragStart({type: "condition", title: t(`mapping.expression.condition.${type}.name`, type), object: {type}}, e)}
                                                                        draggable="true" onClick={e => e.preventDefault()}
                                                                        title={t(`mapping.expression.condition.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.condition.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.condition.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="other">
                        <Accordion.Header>
                            <div className="small">
                                {t("mapping.EXP_OTHER", "Other Expressions")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mapping">
                            {
                                ["parenthesis", "param"].map(type => <button key={type}
                                                                         className="btn btn-link mapping-item"
                                                                         onDragStart={e => onDragStart({type, title: t(`mapping.expression.other.${type}.name`, type), object: {type}}, e)}
                                                                         draggable="true" onClick={e => e.preventDefault()}
                                                                         title={t(`mapping.expression.other.${type}.name`, type)}>
                                    <span className="name">{t(`mapping.expression.other.${type}.name`, type)}</span>
                                    <span className="description">{t(`mapping.expression.other.${type}.desc`, "")}</span>
                                </button>)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Tab>
            <Tab title={t("mapping.TABLES", "Tables")} eventKey="tables" className="mapping">
                <TableTab mapping={mapping as never} />
            </Tab>
            <Tab title={t("mapping.FUNCTIONS", "Functions")} eventKey="functions" className="p-3">
                <div className="mb-2">
                    <input className="form-control form-control-sm" type="search"
                           onInput={e => setFuncFilter(e.currentTarget.value)}
                           value={funcFilter}
                           placeholder={t('common.labels.search', 'Search...')}/>
                </div>
                <Accordion>
                {
                    Object.entries(groupedFunctions).map(([group, funcList]) =>
                        <Accordion.Item eventKey={group} key={group}>
                            <Accordion.Header>
                                <div className="d-flex align-items-center w-100 justify-content-between me-2 small">
                                    <div>{t(`mapping.group.${group}`, group)}</div>
                                    <div className="badge bg-success text-bg-success p-1">{funcList.length}</div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="mapping">
                                {
                                    funcList.sort((a, b) => a.name.localeCompare(b.name)).map((func, index) =>
                                        <button key={`${func.name}-${index}`}
                                                className="btn btn-link mapping-item"
                                                onDragStart={e => onDragStart({type: "function", object: func}, e)}
                                                draggable="true" onClick={e => e.preventDefault()}
                                                title={toString(func, t)}>
                                            <span className="name">
                                                <span>{t(`mapping.function.${func.name}.name`, func.name)}</span>
                                                <span className="opacity-75">(</span>
                                                {
                                                    func.parameters.map((param, idx) => <Template key={param.name}>
                                                        {
                                                            ((idx + 1) === func.parameters.length && func.varArgs) ?
                                                                <span className="opacity-50"
                                                                      key={`varargs-${idx}`}>...</span> : null
                                                        }
                                                        <span className="opacity-50">{t(`mapping.name.${param.name}`, param.name)}</span>
                                                        <span className="small opacity-25">: {t(`mapping.type.${param.type}`, param.type)}</span>
                                                        {
                                                            (idx + 1) < func.parameters.length ?
                                                                <span key={`comma-${idx}`}>, </span> : null
                                                        }
                                                    </Template>)
                                                }
                                                <span className="opacity-75">)</span>
                                                <span className="small opacity-25">: {t(`mapping.type.${func.returnType}`, func.returnType)}</span>
                                            </span>
                                            <span
                                                className="description">{t(`mapping.function.${func.name}.desc`, "")}</span>
                                        </button>)
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                }
                </Accordion>
            </Tab>
        </Tabs>
    </div>;
}
