import EzBiApiMethod from "../EzBiApiMethod";
import {AddDashboardRequest, AddDashboardResponse} from "./types";

export class AddDashboard extends EzBiApiMethod<AddDashboardRequest, AddDashboardResponse> {
    static readonly instance: AddDashboard = new AddDashboard();
    readonly isPrivate = true;
    readonly method = "POST";

    get path(): string {
        return "session/dashboards";
    }
}

export default AddDashboard;
