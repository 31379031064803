const isNotNumber = (() => {
    const reg = /^\D/;
    return reg.test.bind(reg);
})();

function sorter(a: string, b: string): number {
    return a.localeCompare(b);
}

export default function enumKeys(enumObj: Object, sorted?: boolean): string[] {
    const result = Object.keys(enumObj).filter(isNotNumber);
    if (sorted) {
        return result.sort(sorter);
    }
    return result;
}
