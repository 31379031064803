import Dispatcher from "./Dispatcher";
import storage from "../storage";

const LANG_KEY = "lang";
export const DEFAULT_LANGUAGE = "tr";

export default class LanguageDispatcher extends Dispatcher<string> {
    static readonly instance: LanguageDispatcher = new LanguageDispatcher(
        (navigator?.language ?? DEFAULT_LANGUAGE).split("-")[0].toLowerCase()
    );
}

LanguageDispatcher.instance.subscribe(v => {
    if (v) {
        storage.setValue(LANG_KEY, v);
        document.documentElement.setAttribute("lang", v);
    } else {
        storage.deleteValue(LANG_KEY);
        document.documentElement.removeAttribute("lang");
    }
});
storage.getValue<string>(LANG_KEY).then(v => LanguageDispatcher.instance.update(v ?? DEFAULT_LANGUAGE));
