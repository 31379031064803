import ApiDispatcher, {ApiState} from "../../../dispatcher/ApiDispatcher";
import {Query, QueryBase} from "../../../api/session/types";
import EzBiApiClient from "../../../api/EzBiApiClient";
import {createContext, useContext, useEffect} from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import equals from "../../../utils/equals";

export type QueryUpdaterState = ApiState<QueryBase, Query>;

export class QueryUpdaterDispatcher extends ApiDispatcher<QueryBase, Query> {
    constructor() {
        super(EzBiApiClient.instance.session.updateQuery as never, {} as never);
    }

    get delay(): number {
        return 1000;
    }

    exec(query: QueryBase, noWait?: boolean) {
        if (!query) {
            return;
        }

        const req: QueryBase = {
            query: query.query,
            id: query.id,
            designerData: query.designerData,
            name: query.name
        };

        if (!req.id || !this.lastRequest || equals(req, this.lastRequest)) {
            this.abort();
            return;
        }

        this.call(req, noWait);
    }

    init(req: QueryBase) {
        super.init(req?.id ? {
            query: req.query,
            id: req.id,
            designerData: req.designerData,
            name: req.name
        } : {} as never);
    }
}

export const QueryUpdaterContext = createContext<QueryUpdaterDispatcher>(null as never);

export const useQueryUpdaterContext = () => useContext(QueryUpdaterContext);

export function useQueryUpdaterDispatcher(): [QueryUpdaterState, QueryUpdaterDispatcher] {
    const ctx = useQueryUpdaterContext();

    if (!ctx) {
        throw new Error("QueryUpdaterContext.Provider not configured");
    }

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        return ctx.subscribe(forceUpdate);
    }, [forceUpdate, ctx]);

    return [ctx.value as QueryUpdaterState, ctx];
}

export default QueryUpdaterContext;
