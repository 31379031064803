import React, {useCallback, useEffect, useMemo, useState} from "react";
import useTranslation from "../../hooks/useTranslation";
import classNames from "classnames";
import {Button, ButtonGroup, Nav} from "react-bootstrap";
import If from "../If";
import NameInput from "../NameInput";
import {QueryItem} from "./QueryItem";
import {SortType} from "./types";
import {useQueryEditorContext} from "./context/QueryEditorContext";
import useDispatcher from "../../hooks/useDispatcher";
import QueryListDispatcher from "../../dispatcher/QueryListDispatcher";

export interface QueryItemListProps extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
}

export function QueryItemList({className, children, ...props}: QueryItemListProps) {
    const [t] = useTranslation();
    const [adding, setAdding] = useState(false);
    const [queryFilter, setQueryFilter] = useState('');
    const [sortMethod, setSortMethod] = useState(SortType.NONE);
    const queryDispatcher = useQueryEditorContext();
    useDispatcher(QueryListDispatcher.instance);

    useEffect(() => {
        return QueryListDispatcher.instance.subscribe(() => {
            setAdding(QueryListDispatcher.instance.isAdding);
        });
    }, [])

    const addQueryIfRequired = useCallback((name?: string) => {
        if (!name) {
            setAdding(false);
            return;
        }
        QueryListDispatcher.instance.doAdd({name});
    }, [setAdding]);

    const [activeQuery, setActiveQuery] = useState(0);

    const queries = useMemo(() => {
        let result = QueryListDispatcher.instance.items.slice();
        if (queryFilter) {
            const filter = queryFilter.toLocaleLowerCase();
            result = result.filter(v => v.name.toLocaleLowerCase().includes(filter));
        }
        switch (sortMethod) {
            case SortType.AZ:
                return result.sort((a, b) => a.name.localeCompare(b.name));
            case SortType.ZA:
                return result.sort((a, b) => b.name.localeCompare(a.name));
            case SortType.NA:
                return result.sort((a, b) => a.id - b.id);
            case SortType.ND:
                return result.sort((a, b) => b.id - a.id);
            default:
                return result;
        }
        // eslint-disable-next-line
    }, [QueryListDispatcher.instance.requestIndex, sortMethod, queryFilter]);

    const updateSortMethod = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
        const type: "name" | "id" = ev.currentTarget.dataset.sortType as "name" | "id";

        switch (type) {
            case "name":
                setSortMethod(sortMethod === SortType.AZ ? SortType.ZA : SortType.AZ);
                break
            case "id":
                setSortMethod(sortMethod === SortType.NA ? SortType.ND : SortType.NA);
                break;
        }

    }, [sortMethod]);

    const onNavSelect = useCallback((key: any) => {
        setActiveQuery(key);
    }, []);

    useEffect(() => {
        const first = QueryListDispatcher.instance.items[0];

        if (!first) {
            queryDispatcher.update(undefined as never);
            return;
        }

        let ac = activeQuery;

        if (ac && !QueryListDispatcher.instance.items.find(v => v.id === ac)) {
            ac = 0;
        }

        if (!ac) {
            const timer = requestAnimationFrame(() => onNavSelect(first.id));
            return () => cancelAnimationFrame(timer);
        }
        // eslint-disable-next-line
    }, [QueryListDispatcher.instance.requestIndex]);

    useEffect(() => {
        const id = Number(activeQuery);
        const query = QueryListDispatcher.instance.items.find(v => v.id === id);
        queryDispatcher.update({...query} as never);
    }, [activeQuery, queryDispatcher]);

    return <div className={classNames("query-list", className)} {...props}>
        <div className="card-header d-flex align-items-center gap-2">
            <div className="fw-bold small">{t('pages.queryEditor.queries', 'Queries')}</div>
            <input className="form-control form-control-sm" value={queryFilter}
                   onInput={e => setQueryFilter(e.currentTarget.value)}
                   placeholder={t("common.labels.search", "Search...")}/>
            <ButtonGroup size="sm" className="gap-2">
                <If condition={QueryListDispatcher.instance.isLoading}>
                    <Button variant="link" className="p-0 text-danger" onClick={QueryListDispatcher.instance.abort}>
                        <i className="bi bi-stop-fill"></i>
                    </Button>
                </If>
                <Button variant="link" className="p-0" disabled={QueryListDispatcher.instance.isLoading} data-sort-type="name"
                        onClick={updateSortMethod}>
                    <i className={classNames("bi", {
                        "bi-sort-alpha-down": sortMethod !== SortType.AZ,
                        "bi-sort-alpha-up": sortMethod === SortType.AZ
                    })}></i>
                </Button>
                <Button variant="link" className="p-0" disabled={QueryListDispatcher.instance.isLoading} data-sort-type="id"
                        onClick={updateSortMethod}>
                    <i className={classNames("bi", {
                        "bi-sort-numeric-down": sortMethod !== SortType.NA,
                        "bi-sort-numeric-up": sortMethod === SortType.NA
                    })}></i>
                </Button>
                <Button variant="link" className="p-0" disabled={QueryListDispatcher.instance.isLoading} onClick={QueryListDispatcher.instance.doRefresh}>
                    <i className="bi bi-arrow-clockwise"></i>
                </Button>
                <Button variant="link" className="p-0" disabled={adding || QueryListDispatcher.instance.isLoading}
                        onClick={() => setAdding(true)}>
                    <i className="bi bi-plus-circle-fill"></i>
                </Button>
            </ButtonGroup>
        </div>
        <div className="card-body">
            <Nav variant="pills" className="flex-column gap-2" activeKey={activeQuery} onSelect={onNavSelect}>
                <If condition={adding}>
                    <Nav.Item>
                        <NameInput onCancel={addQueryIfRequired} onAccept={addQueryIfRequired}
                                   loading={QueryListDispatcher.instance.isAdding}
                                   value={t('pages.queryEditor.newQuery', 'New Query')}/>
                    </Nav.Item>
                </If>
                <If condition={QueryListDispatcher.instance.isLoading}>
                    <Nav.Item className="d-flex align-items-center justify-content-center">
                        <div className="spinner-border spinner-border-sm"></div>
                    </Nav.Item>
                </If>
                <If condition={QueryListDispatcher.instance.hasError}>
                    <Nav.Item>
                        <div className="alert alert-danger">
                            {QueryListDispatcher.instance.formatError(t)}
                        </div>
                    </Nav.Item>
                </If>
                {
                    queries.map(query => <QueryItem query={query} key={query.id} />)
                }
                <If condition={QueryListDispatcher.instance.hasNotData && !adding}>
                    <div
                        className="alert alert-info d-flex flex-column align-items-center justify-content-center gap-2">
                        <span>{t('pages.queryEditor.noSavedQuery', 'There is no saved query in your account yet.')}</span>
                        <div>
                            <button className="btn btn-success d-flex align-items-center gap-2"
                                    onClick={() => setAdding(true)}>
                                <i className="bi bi-plus-circle-fill"></i>
                                <span>{t('pages.queryEditor.addNewQuery', 'Add new query')}</span>
                            </button>
                        </div>
                    </div>
                </If>
            </Nav>
        </div>
        {children}
    </div>;
}
