import {Variant} from "react-bootstrap/types";
import {DEFAULT_PERIOD, Period} from "../types";
import {useCallback, useEffect, useState} from "react";
import {DateOnly} from "../api/types";
import useTranslation from "../hooks/useTranslation";
import {useTheme} from "../dispatcher/ThemeDispatcher";
import addDays from "../utils/addDays";
import {Dropdown, DropdownMenu, DropdownToggle} from "react-bootstrap";
import ChartDateRange from "../component/ChartDateRange";
import {usePeriodContext} from "../context/PeriodContext";

export interface NamedDateRangeProps {
    variant?: Variant
    onChange?: (period: Period) => void
    defaultValue?: Period
}

export function NamedDateRange({variant, onChange: onChangeProp, defaultValue}: NamedDateRangeProps) {
    defaultValue ??= DEFAULT_PERIOD;

    const [startDate, setStartDate] = useState(DateOnly.diff(defaultValue.startDate));
    const [endDate, setEndDate] = useState(DateOnly.diff(defaultValue.endDate));
    const [t, lang] = useTranslation();
    const [theme] = useTheme();
    const {period} = usePeriodContext();

    const translateDateName = useCallback((startDate: number, endDate: number) => {
        if (startDate === endDate) {
            if (startDate === 0) {
                if (new Date().getDay() === 1) {
                    return t("charts.labels.inThisWeek", {default: "in this week"});
                }
                return t("charts.labels.today", {default: "today"});
            }
            return t("charts.labels.inDate", {default: "in {{date}}", args: {date: addDays(startDate)}})
        }

        if (endDate === 0) {
            if (DateOnly.isDiffBeginOfTheMonth(startDate)) {
                return t("charts.labels.inThisMonth", {default: "in this month"});
            }

            return t("charts.labels.inLastXDays", {default: "in the last {{count}} days", args: {count: -startDate}});
        }

        if (DateOnly.isDiffFullMonth(startDate, endDate)) {
            return t("charts.labels.inMonth", {
                default: "during {{month}} {{year}}",
                args: DateOnly.getMonthAndYear(endDate, lang)
            });
        }

        return t("charts.labels.inBetweenDate", {
            default: "between {{start}} and {{end}}",
            args: DateOnly.getStartAndEnd(startDate, endDate)
        });
    }, [t, lang]);

    const onChange = useCallback((date: Record<string, unknown>) => {
        const [from, to] = DateOnly.diffPeriod(date.from as never, date.to as never);
        setStartDate(from);
        setEndDate(to);

        const period: Period = {
            startDate: DateOnly.fromDiff(from),
            endDate: DateOnly.fromDiff(to)
        };

        if (typeof onChangeProp === "function") {
            onChangeProp(period);
        }
    }, [onChangeProp]);

    useEffect(() => {
        if (!period || period === DEFAULT_PERIOD) {
            return;
        }

        const [from, to] = DateOnly.diffPeriod(period.startDate, period.endDate);
        setStartDate(from);
        setEndDate(to);
    }, [period]);


    return <Dropdown className="d-inline filter">
        <DropdownToggle className="btn-sm d-inline text-lowercase"
                        variant={`outline-${theme === "light" ? "dark" : (variant ?? "primary")}`}>
            {translateDateName(startDate, endDate)}
        </DropdownToggle>
        <DropdownMenu className="position-fixed p-2">
            <ChartDateRange className="filter-body" startDate={startDate} endDate={endDate} onChange={onChange}/>
        </DropdownMenu>
    </Dropdown>
}