// noinspection JSAnnotator

import {ApiMethodExecutionResult, IApiError} from "ez-api-client/dist/api/types";
import {useEffect, useMemo} from "react";
import useForceUpdate from "./useForceUpdate";

type ResponseType<T extends unknown> =
    T extends ApiMethodExecutionResult<any, infer R> ? R : never;

let ID = 0;

class ApiExecutor<
    TFunc extends (...args: Parameters<TFunc>) => ReturnType<TFunc>
> {

    public readonly instanceId = ++ID;
    private lastResult?: ApiMethodExecutionResult<any, any>;
    private _data?: ResponseType<ReturnType<TFunc>>;
    private _error?: IApiError;
    private _loading = false;
    private _silent = false;
    private _ready = false;
    private _requestIndex = 0;
    private readonly _beginHandlers: Set<(...args: Parameters<TFunc>) => any> = new Set();
    private readonly _successHandlers: Set<(err: any) => any> = new Set();
    private readonly _failHandlers: Set<(data: any) => any> = new Set();


    constructor(private readonly func: TFunc, private updater?: () => void) {
        this.execute = this.execute.bind(this);
        this.abort = this.abort.bind(this);
    }

    modify(supplier: (value?: ResponseType<ReturnType<TFunc>>) => {value?: ResponseType<ReturnType<TFunc>>, changed?: boolean}) {
        const result = supplier(this._data);
        if (result.changed) {
            this._data = result.value;
            this._requestIndex++;
            this.update();
        }
    }

    get requestIndex(): number {
        return this._requestIndex;
    }

    getData(defaultValue: ResponseType<ReturnType<TFunc>>): ResponseType<ReturnType<TFunc>> {
        return this._data || defaultValue;
    }

    get error(): IApiError | undefined {
        return this._error;
    }

    get loading(): boolean {
        return this._loading;
    }

    get ready(): boolean {
        return this._ready;
    }

    execute(...args: Parameters<TFunc>): void {
        if (this._loading) {
            return;
        }
        this.setResult(this.func(...args) as never, args);
    }

    abort() {
        const last = this.lastResult;
        this.lastResult = undefined;
        last?.context?.abortController?.abort();

        if (this._loading) {
            this._loading = false;
            this.update();
        }
    }

    silent(): this {
        this._silent = true;
        return this;
    }

    onBegin(cb: (...args: Parameters<TFunc>) => any): this {
        this._beginHandlers.add(cb);
        return this;
    }

    onSuccess(cb: () => any): this {
        this._successHandlers.add(cb);
        return this;
    }

    onFail(cb: () => any): this {
        this._failHandlers.add(cb);
        return this;
    }

    private update(): void {
        if (this._silent) {
            return;
        }
        this.updater?.();
    }

    destroy(): void {
        this.updater = undefined;
        this._beginHandlers.clear();
        this._successHandlers.clear();
        this._failHandlers.clear();
        this.abort();
    }

    private setResult(result: ApiMethodExecutionResult<any, any>, args: Parameters<TFunc>) {
        result.then(v => v.succeed()).then(v => {
            if (this.lastResult) {
                this._data = v;
                this._ready = true;
                this._successHandlers.forEach(cb => cb(v));
            }
        }).catch(err => {
            if (this.lastResult) {
                this._error = err;
                this._failHandlers.forEach(cb => cb(err));
            }
        }).finally(() => {
            if (this.lastResult) {
                this._loading = false;
                this.lastResult = undefined;
                this._requestIndex++;
                this.update();
            }
        });

        this._loading = true;
        this._error = undefined;
        this.lastResult = result;
        this._ready = false;
        this._beginHandlers.forEach(cb => cb(...args))
        this.update();
    }
}

function same(a: any): any {
    return a;
}

export default function useApi<
    TFunc extends (...args: Parameters<TFunc>) => ReturnType<TFunc>
    >(api: TFunc, callArgs?: Parameters<TFunc>, cb?: (api: ApiExecutor<TFunc>) => ApiExecutor<TFunc>, dep?: any)
    : ApiExecutor<TFunc>
{
    const callback = cb ?? same;
    const forceUpdate = useForceUpdate();
    const executor = useMemo(
        () => callback(new ApiExecutor(api, forceUpdate)),
        // eslint-disable-next-line
        [api, forceUpdate, callback, dep]
    );

    useEffect(() => {
        if (callArgs) {
            executor.execute.apply(executor, callArgs);
        }
        return () => executor.destroy();
        // eslint-disable-next-line
    }, []);

    return executor as never;
}
