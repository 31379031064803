import {TableNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {useCallback, useMemo} from "react";
import {Handle, Position} from "reactflow";
import {hiddenHandleStyle} from "./constants";
import {Dropdown} from "react-bootstrap";
import classNames from "classnames";
import {Node} from "@reactflow/core/dist/esm/types";
import NodeToolBox, {ChangeProps} from "./NodeToolBox";
import useDispatcher from "../../../hooks/useDispatcher";
import CPQLMappingDispatcher from "../../../dispatcher/CPQLMappingDispatcher";
import {CPQLTableInfo} from "../../../api/cpql/types";
import {useQueryEditorContext} from "../context/QueryEditorContext";

export function TableNode({data: {object: table}, id, selected}: Node<TableNodeInfo>) {
    const [t] = useTranslation();
    const dispatcher = useQueryEditorContext();
    const onSelect = useCallback((key: string | null) => {
        if (!key) {
            return;
        }
        const column = table.columns.find(v => v.name === key) ?? {name: key};
        if (!column) {
            return;
        }
        dispatcher.addColumn(id, key, t(`mapping.table.${table.name}.column.${column.name}.name`, column.name));
    }, [dispatcher, id, table, t]);
    const [mapping] = useDispatcher(CPQLMappingDispatcher.instance);

    const name = table?.name ?? "";
    const added = dispatcher.getNodesByType<TableNodeInfo>("table").map(v => v.data.object.name);
    const tables = (mapping?.tables ?? []).filter(v => !added.includes(v.name));

    const changeProps: ChangeProps = useMemo(() => ({
        keys: tables.map(v => v.name),
        defaultKey: name,
        translate(key, t) {
            return t(`mapping.table.${key}.name`, key)
        },
        onSelect(id, key, dispatcher) {
            dispatcher.updateNode<TableNodeInfo>(id, node => {
                if (node?.data.object.name !== key) {
                    node.data.object = {...tables.find(v => v.name === key)} as CPQLTableInfo;
                    dispatcher.deleteConnectedNodesByType(node.id, "column");
                    return true;
                }
                return false;
            })
        }
    }) as ChangeProps, [name, tables]);

    const control = <Dropdown className="nodrag" onSelect={onSelect}>
        <Dropdown.Toggle variant="success" size="sm" className="d-flex align-items-center gap-1">
            <span>{t("mapping.SELECT", "Add Column")}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="query-table-columns" onWheelCapture={e => e.stopPropagation()}>
            {
                table.columns.map(column => <Dropdown.Item key={column.name} eventKey={column.name}>
                    <div className="d-flex flex-column p-2">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <div className="fw-bolder">
                                {t(`mapping.table.${table.name}.column.${column.name}.name`, column.name)}
                            </div>
                            <div className="badge bg-success">
                                {t(`mapping.type.${column.type}`, column.type)}
                            </div>
                        </div>
                        <div
                            className="small text-muted">{t(`mapping.table.${table.name}.column.${column.name}.desc`, "")}</div>
                    </div>
                </Dropdown.Item>)
            }
        </Dropdown.Menu>
    </Dropdown>;

    return <div className={classNames("query-item", {"query-item-active": selected})}>
        <Handle position={Position.Left} type="source" style={hiddenHandleStyle} id="column" isConnectable={false}/>
        <NodeToolBox nodeId={id} nodeSelected={selected ?? false} changeProps={changeProps} control={control}
                     canDuplicate={false} />
        <div className="alert alert-primary m-0 query-designer-table fw-bolder text-center small p-2">
            <span>{t(`mapping.table.${table.name}.name`, table.name)}</span>
        </div>
    </div>;
}
