import React, {HTMLAttributes, useCallback, useEffect, useRef} from "react";

export interface RemainingMillisecondsProps extends React.PropsWithoutRef<HTMLAttributes<HTMLSpanElement>> {
    date: number
}

export default function RemainingMilliseconds({date, ...props}: RemainingMillisecondsProps) {
    const ref = useRef<HTMLSpanElement>(null as never);
    const refCallback = useCallback((node: HTMLSpanElement) => {
        ref.current = node;
    }, []);

    useEffect(() => {
        let timer = 0;
        const next = () => {
            timer = requestAnimationFrame(() => {
                const now = Date.now();
                if (now > date) {
                    ref.current.innerText = (0).toFixed(2);
                    return;
                }
                if (ref.current) {
                    ref.current.innerText = ((date - now) / 1000).toFixed(2);
                }
                next();
            })
        };
        next();
        return () => cancelAnimationFrame(timer);
    }, [date]);

    return <span {...props} ref={refCallback}>{(0).toFixed(2)}</span>
}
