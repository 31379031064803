const RESIZE_STATE_TIMERS: Record<string, number> = {};
function saveResizeState(node: HTMLElement, prop?: string, value?: string) {
    const key = node.dataset.resizeKey;
    if (!key) {
        return;
    }
    clearTimeout(RESIZE_STATE_TIMERS[key]);
    RESIZE_STATE_TIMERS[key] = setTimeout(() => {
        const storageKey = `resizeKey::${key}`;
        if (!value) {
            return localStorage.removeItem(storageKey);
        }

        localStorage.setItem(storageKey, JSON.stringify({prop, value}));
    }, 150) as never;
}

function applyResizeState(node: HTMLElement) {
    const key = node.dataset.resizeKey;
    if (!key) {
        return;
    }
    const storageKey = `resizeKey::${key}`;
    const json = localStorage.getItem(storageKey);
    if (!json) {
        return;
    }
    try {
        const {prop, value} = JSON.parse(json);
        node.style[prop] = value;
    } catch (e) {
        // ignore
    }
}

function onMouseDown(ev: MouseEvent) {
    const element = ev.target as HTMLElement;
    const resizer = element.closest(".resize-handle");
    if (!resizer) {
        return;
    }

    const {x, y} = ev;
    const isHorizontal = resizer.closest(":where(.vertical, .horizontal)")?.matches(".horizontal");
    const sibling = resizer.previousSibling as HTMLElement;
    const boundingBox = sibling.getBoundingClientRect();

    const onMouseMove = (ev: MouseEvent) => {
        const {x: xLast, y: yLast} = ev;
        let prop: keyof CSSStyleDeclaration;
        let value: number;

        if (isHorizontal) {
            prop = "width";
            value = Math.ceil(boundingBox.width + xLast - x);
        } else {
            prop = "height";
            value = Math.ceil(boundingBox.height + yLast - y);
        }
        let strValue = `${value}px`
        sibling.style[prop] = strValue;
        if (Math.abs(sibling.getBoundingClientRect()[prop] - value) > 5) {
            sibling.style.removeProperty(prop);
            strValue = '';
        }

        saveResizeState(sibling, prop, strValue);
    };

    const onMouseUp = (ev: MouseEvent) => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
}

export function resizer() {
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("dragstart", ev => {
        const element = ev.target as HTMLElement;
        if (typeof element?.closest !== "function") {
            return;
        }

        const resizer = element?.closest(".resizable");
        if (!resizer || element.closest("[draggable]")) {
            return;
        }
        ev.preventDefault();
        ev.stopImmediatePropagation();
    });

    new MutationObserver(mutations => {
        const processedNodes = new Set();
        mutations.map(v => Array.from((v.target as HTMLElement).querySelectorAll(".resizable") as NodeListOf<HTMLElement>))
            .flat()
            .forEach(target => {
                if (processedNodes.has(target)) {
                    return;
                }
                processedNodes.add(target);
                const nodes = target.querySelectorAll("[data-resize-key]") as NodeListOf<HTMLElement>;
                nodes.forEach(node => {
                    if (processedNodes.has(node)) {
                        return;
                    }
                    processedNodes.add(node);
                    applyResizeState(node);
                });
                const initials = target.querySelectorAll("[data-resize-initial]") as NodeListOf<HTMLElement>;
                initials.forEach(node => {
                    const initial = Number(node.dataset.resizeInitial);
                    node.removeAttribute("data-resize-initial");
                    const parentElement = node.parentElement;
                    const prop = parentElement?.matches(".vertical") ? "height" : "width";
                    if (node.style[prop]) {
                        return;
                    }
                    const nextSiblingSize = node.nextElementSibling?.getBoundingClientRect()[prop] ?? 0;
                    const parentSize = (parentElement?.getBoundingClientRect()[prop] ?? 0) - nextSiblingSize;
                    node.style[prop] = `${Math.floor(parentSize * initial)}px`;
                });
            })
    }).observe(
        document.documentElement,
        {
            childList: true,
            subtree: true
        }
    );
}


