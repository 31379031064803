import useTranslation from "../hooks/useTranslation";
import React, {useCallback, useState} from "react";
import FocusTracker from "../utils/FocusTracker";
import KeyboardListener from "../utils/KeyboardListener";
import If from "./If";

export interface ShouldDeleteProperties {
    onCancel?: () => void
    onAccept?: () => void
    loading?: boolean
}

export default function ShouldDelete({onCancel, onAccept, loading}: React.PropsWithoutRef<ShouldDeleteProperties>) {
    const [t] = useTranslation();
    const [focusTracker] = useState(() => new FocusTracker());
    const [keyboardListener] = useState(() => new KeyboardListener());

    const doAccept = useCallback((): boolean => {
        if (onAccept instanceof Function) {
            onAccept();
            focusTracker.init();
            keyboardListener.init();
            return true;
        }
        return false;
    }, [onAccept, focusTracker, keyboardListener]);

    const doCancel = useCallback((): boolean => {
        if (onCancel instanceof Function) {
            onCancel();
        }
        focusTracker.init();
        keyboardListener.init();
        return true;
    }, [onCancel, focusTracker, keyboardListener]);

    const groupRef = useCallback((node: HTMLDivElement): void => {
        if (node) {
            node.focus();
        }

        keyboardListener.init(node, key => {
            switch (key) {
                case "Escape":
                    return doCancel();
                case "Enter":
                case "NumpadEnter":
                    return doAccept();
            }
            return false;
        });

        focusTracker.init(node, doCancel);
    }, [doCancel, doAccept, focusTracker, keyboardListener]);

    return <div className="alert alert-danger p-0 ps-3 py-0 d-flex align-items-center m-0 py-fix gap-2" tabIndex={-1} ref={groupRef}>
        <div className="small">{t('common.labels.shouldDelete', 'Are you sure want to delete?')}</div>
        <div className="m-auto"></div>
        <If condition={!loading}>
            <button className="btn btn-link btn-sm py-0 px-0 text-danger border-transparent" onClick={doAccept}>
                <i className="bi bi-check"></i>
            </button>
            <button className="btn btn-link btn-sm py-0 text-secondary border-transparent" onClick={doCancel}>
                <i className="bi bi-x"></i>
            </button>
        </If>
        <If condition={!!loading}>
            <div className="spinner-border spinner-border-sm me-2"></div>
        </If>
    </div>
}
