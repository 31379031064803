import EzBiApiMethod from "../EzBiApiMethod";
import {DateFilter, DateGroupListResponse} from "../types";

export class ActiveContracts extends EzBiApiMethod<DateFilter, DateGroupListResponse> {
    static readonly instance: ActiveContracts = new ActiveContracts();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "contracts/active?from={!from}&to={!to}";
    }

    get responseModifier() {
        return undefined;
    }
}

export default ActiveContracts;
