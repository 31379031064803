import {ColumnNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {useMemo} from "react";
import {Handle, Position} from "reactflow";
import {hiddenHandleStyle, leftHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import classNames from "classnames";
import NodeToolBox, {ChangeProps} from "./NodeToolBox";
import {CPQLNamedAndTyped} from "../../../api/cpql/types";
import useDispatcher from "../../../hooks/useDispatcher";
import CPQLMappingDispatcher from "../../../dispatcher/CPQLMappingDispatcher";

export function ColumnNode(node: Node<ColumnNodeInfo>) {
    const [t] = useTranslation();
    const table = node?.data.table;
    const column = node?.data.object;
    const [mapping] = useDispatcher(CPQLMappingDispatcher.instance);

    const columnName = column?.name ?? "";
    const tableName = table?.name;
    const columns = useMemo(
        () => (mapping?.tables ?? []).find(v => v.name === tableName)?.columns ?? [],
        [mapping?.tables, tableName]
    );

    const changeProps: ChangeProps = useMemo(() => ({
        keys: columns.map(v => v.name),
        defaultKey: columnName,
        translate(key, t) {
            return t(`mapping.table.${tableName}.column.${key}.name`, key)
        },
        onSelect(id, key, dispatcher) {
            dispatcher.updateNode<ColumnNodeInfo>(id, node => {
                if (node?.data.object.name !== key) {
                    node.data.object = {...columns.find(v => v.name === key)} as CPQLNamedAndTyped;
                    return true;
                }
                return false;
            })
        }
    }) as ChangeProps, [columnName, tableName, columns]);

    if (!node) {
        return <></>;
    }

    return <div className={classNames("query-item", {"query-item-active": node?.selected})}>
        <Handle position={Position.Right} style={hiddenHandleStyle} type="target" id="input" isConnectable={false}/>
        <Handle position={Position.Left} style={leftHandleStyle} type="source" id="output"/>
        <NodeToolBox nodeId={node.id} nodeSelected={node.selected ?? false} changeProps={changeProps} />
        <div className="alert alert-warning m-0 p-1 text-center query-designer-column fw-bolder small">
            <span>{t(`mapping.table.${table?.name}.column.${column?.name}.name`, column?.name)}</span>
        </div>
    </div>;
}
