import {InputGroup} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import React, {useCallback, useState} from "react";
import FocusTracker from "../utils/FocusTracker";
import KeyboardListener from "../utils/KeyboardListener";
import If from "./If";

export interface NameInputProperties {
    onCancel?: () => void
    onAccept?: (value: string) => void
    value?: string
    loading?: boolean
}

export default function NameInput({onCancel, onAccept, value: initialValue, loading}: React.PropsWithoutRef<NameInputProperties>) {
    const [t] = useTranslation();
    const [value, setValue] = useState(initialValue ?? '');
    const [focusTracker] = useState(() => new FocusTracker());
    const [keyboardListener] = useState(() => new KeyboardListener());

    const doAccept = useCallback((): boolean => {
        const v = value ? value.trim() : value;
        if (v && onAccept instanceof Function) {
            onAccept(v);
            focusTracker.init();
            keyboardListener.init();
            return true;
        }
        return false;
    }, [value, onAccept, focusTracker, keyboardListener]);

    const doCancel = useCallback((): boolean => {
        if (onCancel instanceof Function) {
            onCancel();
        }
        focusTracker.init();
        keyboardListener.init();
        return true;
    }, [onCancel, focusTracker, keyboardListener]);

    const inputRef = useCallback((node: HTMLInputElement): void => {
        node && requestAnimationFrame(() => {
            node.focus();
            node.select();
        });
    }, []);

    const groupRef = useCallback((node: HTMLDivElement): void => {
        keyboardListener.init(node, key => {
            switch (key) {
                case "Escape":
                    return doCancel();
                case "Enter":
                case "NumpadEnter":
                    return doAccept();
            }
            return false;
        });

        focusTracker.init(node, doCancel);
    }, [doCancel, doAccept, focusTracker, keyboardListener]);

    return <InputGroup className="custom-border custom-border-radius text-body-tertiary focus-ring2" size="sm" tabIndex={-1} ref={groupRef}>
        <input className="form-control border-0 no-outline"
               disabled={loading}
               placeholder={t('pages.common.pleaseEnterAName', 'Please enter a name')} value={value}
               onInput={ev => setValue(ev.currentTarget.value)} ref={inputRef}/>
        <If condition={!loading}>
            <button className="btn btn-outline-success input-group-text border-transparent" disabled={!value} onClick={doAccept}>
                <i className="bi bi-check"></i>
            </button>
            <button className="btn btn-outline-danger input-group-text border-transparent" onClick={doCancel}>
                <i className="bi bi-x"></i>
            </button>
        </If>
        <If condition={!!loading}>
            <InputGroup.Text>
                <div className="spinner-border spinner-border-sm"></div>
            </InputGroup.Text>
        </If>
    </InputGroup>
}
