import Dispatcher from "./Dispatcher";
import LanguageDispatcher, {DEFAULT_LANGUAGE} from "./LanguageDispatcher";

const NUMBER_FORMATS: Record<string, Intl.NumberFormat> = {};

const getNumberFormat = (lang?: string | null, options?: Intl.NumberFormatOptions) => {
    lang ??= DEFAULT_LANGUAGE;
    const key = `${lang}::${JSON.stringify(options ?? {})}`;
    return NUMBER_FORMATS[key] ?? (NUMBER_FORMATS[key] = new Intl.NumberFormat(lang, options));
}
export default class NumberFormatDispatcher extends Dispatcher<Intl.NumberFormat> {
    static readonly instance: NumberFormatDispatcher = new NumberFormatDispatcher(getNumberFormat());
}

const CURRENCY_OPTIONS = {currency: "TRY", style: "currency"};

export class CurrencyDispatcher extends Dispatcher<Intl.NumberFormat> {
    static readonly instance: CurrencyDispatcher = new CurrencyDispatcher(getNumberFormat(DEFAULT_LANGUAGE, CURRENCY_OPTIONS));
}

LanguageDispatcher.instance.subscribe(v => {
    NumberFormatDispatcher.instance.update(getNumberFormat(v))
    CurrencyDispatcher.instance.update(getNumberFormat(v, CURRENCY_OPTIONS))
});

