import {InputComponentProps} from "../types";
import React from "react";
import {Dropdown} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import useTranslation from "../hooks/useTranslation";
import classNames from "classnames";

export interface ThemePickerProps extends InputComponentProps<Variant> {

}

const variants: Variant[] = ["primary", "secondary", "danger", "warning", "info", "success", "dark", "light"]

function noOp() {}

export default function ThemePicker({id, value, onInput}: React.PropsWithoutRef<ThemePickerProps>) {
    const [t] = useTranslation();

    if (!(onInput instanceof Function)) {
        onInput = noOp;
    }

    return <Dropdown id={id} defaultValue={value} onSelect={onInput}>
        <Dropdown.Toggle variant={value as Variant} title={t("common.labels.choose", "Choose...")} size="sm"
                         className="w-100 d-flex align-items-center justify-content-between">
            {t(`theme.${value ?? "primary"}`, value)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {
                variants.map(variant =>
                    <Dropdown.Item key={variant} eventKey={variant}
                                   className={classNames(`bg-${variant}`, `text-bg-${variant}`)}>
                        {t(`theme.${variant}`, variant)}
                    </Dropdown.Item>
                )
            }
        </Dropdown.Menu>
    </Dropdown>
}
