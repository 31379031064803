import {useTheme} from "../dispatcher/ThemeDispatcher";
import classNames from "classnames";
import * as React from "react";

export default function ThemeButton({className, ...props}: React.HTMLAttributes<HTMLElement>) {
    const [theme, , toggleTheme] = useTheme();
    return <button className={classNames("btn", className)} onClick={toggleTheme} {...props}>
        <i className={classNames("bi", {
            "bi-sun-fill": theme === "dark",
            "bi-moon-fill": theme === "light"
        })}></i>
    </button>
}
