import React, {useCallback, useState} from "react";
import {Button, InputGroup, Nav} from "react-bootstrap";
import {Dashboard} from "../../api/session/types";
import {DashboardInfo} from "./types";
import useLanguage from "../../hooks/useLanguage";
import {useDispatcherMapped} from "../../hooks/useDispatcher";
import DashboardListDispatcher from "../../dispatcher/DashboardListDispatcher";
import DesignerModeDispatcher from "../../dispatcher/DesignerModeDispatcher";
import {DEFAULT_LANGUAGE} from "../../dispatcher/LanguageDispatcher";
import If from "../If";
import classNames from "classnames";
import AddOrEditDashboardNavItem from "./AddOrEditDashboardNavItem";
import ShouldDelete from "../ShouldDelete";

export interface DashboardListProps {
    dashboard: Dashboard
}

export function DashboardNavLink({dashboard}: React.PropsWithoutRef<DashboardListProps>) {
    const id = dashboard.id;
    const [lang] = useLanguage();
    const [[designerMode, editing, duplicating]] = useDispatcherMapped(
        DesignerModeDispatcher.instance,
        useCallback((d: DesignerModeDispatcher) => [d.active, d.editing === id, d.duplicating === id], [id])
    );
    const [deleting, setDeleting] = useState(false);
    const [isDashboardDeleting] = useDispatcherMapped(
        DashboardListDispatcher.instance,
        useCallback(() => DashboardListDispatcher.instance.isDeleting, [])
    );

    const onAction = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        const action = e.currentTarget.dataset.action;
        switch (action) {
            case "clone":
                DesignerModeDispatcher.instance.setDuplicating(id);
                break;
            case "edit":
                DesignerModeDispatcher.instance.setEditing(id);
                break;
            case "delete":
                setDeleting(true);
                break;
        }
        e.preventDefault();
        e.stopPropagation();
    }, [id]);

    const doDelete = useCallback(() => {
        DashboardListDispatcher.instance.doDelete(id);
    }, [id]);

    const info: DashboardInfo = {
        name: {},
        ...JSON.parse(dashboard.data)
    };

    if (editing || duplicating) {
        return <AddOrEditDashboardNavItem dashboard={dashboard} duplicate={duplicating} />
    }

    if (deleting) {
        return <Nav.Item className="my-fix-05">
            <ShouldDelete onCancel={() => setDeleting(false)} onAccept={doDelete}
                          loading={isDashboardDeleting}/>
        </Nav.Item>;
    }

    return <Nav.Link eventKey={`/dashboard/${dashboard.id}`} className="d-flex align-items-center gap-2">
        <If condition={Boolean(info.icon)}>
            <span className={classNames("bi", info.icon)}></span>
        </If>
        <span>
            {info.name[lang ?? DEFAULT_LANGUAGE] || dashboard.id}
        </span>
        <If condition={designerMode}>
            <span className="m-auto"></span>
            <InputGroup size="sm" className="w-fit-content">
                <Button className="btn-no-color" data-action="clone" onClick={onAction}>
                    <i className="ezbi ezbi-duplicate"></i>
                </Button>
                <Button className="btn-no-color" data-action="edit" onClick={onAction}>
                    <i className="bi bi-pen"></i>
                </Button>
                <Button className="btn-no-color" data-action="delete" onClick={onAction}>
                    <i className="bi bi-trash-fill"></i>
                </Button>
            </InputGroup>
        </If>
    </Nav.Link>;
}

export default function DashboardList() {
    const [dashboards, dispatcher] = useDispatcherMapped(
        DashboardListDispatcher.instance,
        useCallback((d: DashboardListDispatcher) => d.items, [])
    );

    if (!dashboards?.length) {
        return <></>;
    }

    return <>
        <If condition={dispatcher.isLoading}>
            <Nav.Item>
                <div className="d-flex align-items-center justify-content-center p-2">
                    <span className="spinner-border spinner-border-sm"></span>
                </div>
            </Nav.Item>
        </If>
        {
            dashboards.map(dashboard => <DashboardNavLink dashboard={dashboard} key={dashboard.id} />)
        }
    </>
}
