import EzBiApiMethod from "../EzBiApiMethod";
import {LocationRecordArray} from "./types";

export class Locations extends EzBiApiMethod<undefined, LocationRecordArray> {
    static readonly instance: Locations = new Locations();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "info/locations";
    }
}

export default Locations;
