import {Dropdown} from "react-bootstrap";
import "./icon-picker.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import If from "../If";
import useTranslation from "../../hooks/useTranslation";
import useSet from "../../hooks/useSet";
import classNames from "classnames";
import {InputComponentProps} from "../../types";
import ClearableInput from "../ClearableInput";

let iconList: Promise<string> | null = null;

export interface IconPickerProps extends InputComponentProps<string> {
}

export default function IconPicker({id, value: initialValue, onInput, disabled}: React.PropsWithoutRef<IconPickerProps>) {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState(() => new Set<string>());
    const [query, setQuery] = useState('');
    const [value, setValue] = useState(() => initialValue || "bi-question");

    const selectedCategories = useSet<string>(undefined, set => {
        if (!refObj.current) {
            return;
        }
        const hiddenNodes = refObj.current?.querySelectorAll("li.d-none.categorized") as NodeListOf<HTMLLIElement>;
        hiddenNodes.forEach(li => {
            if (!li.classList.contains("filtered")) {
                li.classList.remove("d-none");
            }
            li.classList.remove("categorized");
        });

        if (set.isEmpty) {
            return;
        }

        const filter = `li:not(${Array.from(set).map(v => `[data-categories="${v}"]`).join(",")})`;
        const selectedNodes = refObj.current.querySelectorAll(filter) as NodeListOf<HTMLLIElement>;

        selectedNodes.forEach(li => li.classList.add("d-none", "categorized"));
    });
    const [autoClose, setAutoClose] = useState<"outside" | boolean | undefined>("outside");
    const [dropDownVisible, setDropdownVisible] = useState(false);

    const refObj = useRef<HTMLDivElement | null>(null);
    const inputRefObj = useRef<HTMLInputElement | null>(null);

    const onIconClick = useCallback((ev: MouseEvent) => {
        if (!refObj.current) {
            return;
        }

        const target = (ev.target as HTMLElement).closest("li[data-name]") as HTMLLIElement;
        if (target && refObj.current?.contains(target)) {
            const v = `bi-${target.dataset.name ?? "question"}`;
            if (onInput instanceof Function) {
                onInput(v);
            }
            setValue(v);
            document.documentElement.click();
        }
    }, [onInput]);

    const iconContainerRef = useCallback((node: HTMLDivElement) => {
        if (!node && refObj.current) {
            refObj.current?.removeEventListener("click", onIconClick, true);
        }

        refObj.current = node;

        if (!node) {
            return;
        }

        node.addEventListener("click", onIconClick, true);

        if (!iconList) {
            iconList = fetch("/icons.html").then(v => v.text());
        }
        node.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
        if (!node.classList.contains("loading")) {
            node.classList.add("loading");
        }
        iconList.then(html => node.innerHTML = html).then(() => {
            node.classList.remove("loading");
            const catList = new Set<string>();

            (node.querySelectorAll("[data-categories]") as NodeListOf<HTMLElement>)
                .forEach(el => {
                    const category = el.dataset.categories;
                    if (category) {
                        catList.add(category);
                    }
                });

            (node.querySelectorAll("li") as NodeListOf<HTMLElement>)
                .forEach(el => {
                    const tags = (el.dataset.tags ?? "").toLocaleLowerCase().split(/\s+/g).filter(Boolean);
                    let serializedTags = '';
                    if (tags.length) {
                        serializedTags = `|${tags.join("|")}`;
                    }
                    el.dataset.tags = `|${el.dataset.name}|${(el.dataset.name ?? "").split(/\W+/g).join("|")}${serializedTags}`
                });

            setCategories(catList);
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const inputRef = useCallback((node: HTMLInputElement) => {
        inputRefObj.current = node;
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!refObj.current) {
                return;
            }
            const hiddenNodes = refObj.current.querySelectorAll("li.d-none.filtered") as NodeListOf<HTMLLIElement>;
            hiddenNodes.forEach(li => {
                if (!li.classList.contains("categorized")) {
                    li.classList.remove("d-none");
                }
                li.classList.remove("filtered");
            });

            const filterList = query.toLocaleLowerCase().split(/\s+/g)
                .filter(Boolean)
                .map(v => `:where([data-tags*="|${v}"])`);
            if (!filterList.length) {
                return;
            }

            const filter = `li:not(.d-none):not(${filterList.join("")})`;
            const allNodes = refObj.current.querySelectorAll(filter) as NodeListOf<HTMLLIElement>;

            allNodes.forEach(node => {
                node.classList.add("d-none", "filtered")
            });
        }, 300);

        return () => clearTimeout(timer);

    }, [query]);

    useEffect(() => {
        if (!dropDownVisible || !inputRefObj.current) {
            return;
        }
        const id = requestAnimationFrame(() => inputRefObj.current?.focus());
        return () => cancelAnimationFrame(id);
    }, [dropDownVisible, loading]);

    useEffect(() => {
        setValue(initialValue || "bi-question");
    }, [initialValue])

    return <div className="d-flex">
        <input className="form-control form-control-sm br-end-0" value={value} readOnly disabled={disabled ?? false}/>
        <Dropdown drop="end" autoClose={autoClose as never} id={id} onToggle={setDropdownVisible}>
            <Dropdown.Toggle size="sm" className="br-start-0" disabled={disabled ?? false}>
                <i className={classNames("bi", value)}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow position-fixed">
                <Dropdown.ItemText className="icon-picker-body">
                    <div className="d-flex align-items-center gap-2 icon-filter">
                        <ClearableInput ref={inputRef} size="sm"
                               value={query} onInput={e => setQuery(e.currentTarget.value)}
                               disabled={loading}
                               placeholder={t("common.labels.search", "Search...")}/>
                        <Dropdown autoClose="outside" onSelect={selectedCategories.toggle as never}  align={{sm:"end"}}
                                  onToggle={(show, _) => setAutoClose(show ? false : "outside")}>
                            <Dropdown.Toggle size="sm" disabled={loading} >
                                        <span>
                                            {t("common.labels.categories", "Categories")}
                                        </span>
                                <If condition={selectedCategories.hasItems}>
                                    <span className="badge bg-danger ms-1">{selectedCategories.size}</span>
                                </If>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="category-list">
                                {Array.from(categories).sort().map(cat => <Dropdown.Item key={cat} eventKey={cat} active={selectedCategories.has(cat)}>
                                    {
                                        t(
                                            `common.labels.C_${cat.replace(/\W+/g, "_").toUpperCase()}`,
                                            cat
                                        )
                                    }
                                </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="icon-container" ref={iconContainerRef}>
                    </div>
                </Dropdown.ItemText>
            </Dropdown.Menu>
        </Dropdown>
    </div>
}
