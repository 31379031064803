import {useQueryExecutionDispatcher} from "../../context/QueryExecutionContext";
import If from "../If";
import {Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useTranslation from "../../hooks/useTranslation";
import {CPQLNamedAndTyped, CPQLType} from "../../api/cpql/types";

import "./cpql-result-table.scss";

interface IApiError {
    httpCode: number;
    errorCode: string;
    errorMessage: string;
}

function convertToString(type: CPQLType, value: unknown) {
    switch (type) {
        case CPQLType.TIME:
            if (typeof value === "string") {
                value = Date.parse(`1970-01-01T${value}`);
            }
            return new Date(Number(value)).toLocaleTimeString();
        case CPQLType.DATE:
            if (typeof value === "string") {
                value = Date.parse(`${value}T00:00:00Z`);
            }
            return new Date(Number(value)).toLocaleDateString();
        case CPQLType.DATETIME:
            if (typeof value === "string") {
                value = Date.parse(value);
            }
            return new Date(Number(value)).toLocaleString();
    }

    return String(value);
}

export default function CPQLResultTable() {
    const [t] = useTranslation();
    const [state, dispatcher] = useQueryExecutionDispatcher();
    const error = state.error as IApiError;
    const [headers, setHeaders] = useState<CPQLNamedAndTyped[]>(() => state.result?.fields ?? []);
    const [data, setData] = useState<unknown[][]>(() => state.result?.data ?? []);
    const [page, setPage] = useState(() => 1);
    const [totalRecords, setTotalRecords] = useState(() => state.result?.totalRecords ?? 0);
    const [offset, setOffset] = useState(() => state.result?.offset ?? 0);
    const [limit, setLimit] = useState(() => state.result?.limit ?? 1);

    const records = totalRecords;
    const startIndex = offset + 1;

    const pageCount = Math.ceil(records / limit);
    const begin = startIndex;
    const end = Math.min(offset + limit, records);

    useEffect(() => {
        if (!state.result) {
            return;
        }

        setHeaders(state.result.fields);
        setData(state.result.data);
        setTotalRecords(state.result.totalRecords);
        setOffset(state.result.offset);
        setLimit(state.result.limit);

    }, [state.result]);

    useEffect(() => {
        dispatcher.setOffset(Math.floor((page - 1) * limit));
    }, [dispatcher, page, limit]);

    return <div className="h-100">
        <div className="table-with-pagination overflow-auto">
            <table className="table table-striped table-sm table-hover">
                <thead>
                <tr>
                    <th>#</th>
                    {
                        headers.map((field, index) => <th key={index}>{field.name}</th>)
                    }
                </tr>
                </thead>
                <tbody className="table-group-divider">
                <If condition={Boolean(error)}>
                    <tr>
                        <td colSpan={headers.length + 1}>
                            <Alert variant="danger" className="mb-0">
                                <Alert.Heading>
                                    {t([`error.${error?.errorCode}`, 'common.labels.error'], 'Error')}
                                </Alert.Heading>
                                <div>
                                    {error?.errorMessage}
                                </div>
                            </Alert>
                        </td>
                    </tr>
                </If>
                {
                    data.map((ary, index) => <tr key={startIndex + index}>
                        <td>{index + startIndex}</td>
                        {
                            ary.map((value, index) => <td key={(startIndex + index) * headers.length + index}>
                                {convertToString(headers[index].type, value)}
                            </td>)
                        }
                    </tr>)
                }
                </tbody>
            </table>
        </div>
        <nav className="pagination-container">
            <If condition={pageCount === 1}>
                <div className="text-muted small">
                    {t("common.labels.foundNRecords", {args: {records} })}
                </div>
            </If>
            <If condition={pageCount > 1}>
                <div className="text-muted small">
                    {t("common.labels.showingNRecords", {args: {begin, end, records} })}
                </div>
                <div className="d-flex gap-2 align-items-center">
                    <input type="number" className="text-center form-control form-control-sm w-auto" step={1} min={1}
                           max={pageCount} value={page}
                           onInput={e => setPage(Math.min(e.currentTarget.valueAsNumber || 1, pageCount))} />
                    <span className="no-wrap">/ {pageCount}</span>
                </div>
            </If>
        </nav>
    </div>
}
