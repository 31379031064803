export interface ConfigEnv {
    production: Config
    development: Config
    test: Config
}

export interface Config {
    ezBiApiHost: string
}

const configEnv: ConfigEnv = {
    production: {
        ezBiApiHost: "https://bi.voltrun.com/api/v1/"
    },
    development: {
        ezBiApiHost: "http://localhost:8080/api/v1/"
    },
    test: {
        ezBiApiHost: "http://localhost:8080/api/v1/"
    }
}

interface Self {
    process: {
        env: {
            NODE_ENV: "production" | "development" | "test"
        }
    }
}

const self: Self = global || {process: {env: {NODE_ENV: "production"}}};

const config: Config = configEnv[self.process?.env?.NODE_ENV || "production"];

export default config;
