import {Dropdown} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import {useCallback, useEffect, useState} from "react";
import enumKeys from "../utils/enumKeys";
import If from "./If";

import "./enum-dropdown.scss";

export interface EnumDropdownProps<T extends string | number> {
    value: T | undefined
    onChange: (value: T) => void
    enumObj: Record<string, T>
    translationCategory: string
    dropdownToggleSize?: "sm" | "lg"
}

interface EnumDropdownItemProps {
    name: string
    desc: string
    value: string | number
    active: boolean
}

function EnumDropdownItem({name, desc, value, active}: EnumDropdownItemProps) {
    return <Dropdown.Item eventKey={value} active={active}>
        <span className="name">{name}</span>
        <If condition={Boolean(desc)}>
            <span className="desc">{desc}</span>
        </If>
    </Dropdown.Item>;
}

export default function EnumDropdown<T extends string | number>({value: initialValue, dropdownToggleSize, translationCategory, enumObj, onChange}: EnumDropdownProps<T>) {
    const [t] = useTranslation();
    const [value, setValue] = useState(initialValue);

    const onSelect = useCallback((key: string | null) => {
        if (!onChange || key === null) {
            return;
        }

        const next: T = enumObj[enumObj[key]] as never;
        setValue(next)
        onChange(next);
    }, [onChange, enumObj])

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const sortedKeys = enumKeys(enumObj)
        .sort((a, b) =>
            t(`${translationCategory}.${a}.name`, a).localeCompare(t(`${translationCategory}.${b}.name`, b)));

    return <Dropdown onSelect={onSelect} className="enum-dropdown">
        <Dropdown.Toggle size={dropdownToggleSize}>
            {t(value ? `${translationCategory}.${value}.name` : "common.labels.choose", String(value))}
        </Dropdown.Toggle>
        <Dropdown.Menu className="shadow position-fixed">
            {
                sortedKeys.map(key =>
                    <EnumDropdownItem key={key} name={t(`${translationCategory}.${key}.name`, key)}
                                      desc={t(`${translationCategory}.${key}.desc`, "")}
                                      value={enumObj[key]} active={enumObj[key] === value} />)
            }
        </Dropdown.Menu>
    </Dropdown>
}
