import EzBiApiMethod from "../EzBiApiMethod";

export class Logout extends EzBiApiMethod<undefined, undefined> {
    static readonly instance: Logout = new Logout();
    readonly isPrivate = true;
    readonly method = "POST";

    get path(): string {
        return "session/logout";
    }
}

export default Logout;
