import {createContext, useContext, useEffect} from "react";
import QueryExecutionDispatcher, {QueryExecutionState} from "./QueryExecutionDispatcher";
import useForceUpdate from "../hooks/useForceUpdate";

export const QueryExecutionContext = createContext<QueryExecutionDispatcher>(null as never);
export const useQueryExecutionContext = () => useContext(QueryExecutionContext);

export function useQueryExecutionDispatcher(): [QueryExecutionState, QueryExecutionDispatcher] {
    const ctx = useQueryExecutionContext();
    if (!ctx) {
        throw new Error("QueryExecutionContext.Provider not configured");
    }
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        return ctx.subscribe(forceUpdate);
    }, [forceUpdate, ctx]);

    return [ctx.value as QueryExecutionState, ctx];
}

export default QueryExecutionContext;

