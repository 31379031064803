import ObjectDispatcher from "../../dispatcher/ObjectDispatcher";
import {DashboardInfo, DashboardItem} from "../dashboard/types";
import {Query} from "../../api/session/types";

export class DashboardDesignerDispatcher extends ObjectDispatcher<DashboardInfo> {
    public static readonly instance: DashboardDesignerDispatcher = new DashboardDesignerDispatcher({
        name: {}, items: [], icon: "", ha: "start", va: "start", j: "start", d: "row"
    });

    update(v?: DashboardInfo): DashboardInfo | undefined {
        return super.update({name: {}, items: [], icon: "", ha: "start", va: "start", j: "start", d: "row", ...v});
    }

    setItems(cb: (items: DashboardItem[]) => DashboardItem[]) {
        this.updateState({items: cb(this.value?.items ?? [])});
    }

    add(query: Query, w?: number, h?: number) {
        this.setItems(items => items.concat({id: Date.now(), q: query.id, w: w ?? 32, h: h ?? 18, fs: false}));
    }

    updateItem(id: number, data: Partial<Omit<DashboardItem, "id">>) {
        this.setItems(v => {
            const index = v.findIndex(q => q.id === id);
            if (index < 0) {
                return v;
            }
            v = v.slice();
            v[index] = Object.assign({}, v[index], data);
            return v;
        })
    }

    toJson(): string {
        return JSON.stringify(this.value ?? {});
    }
}
