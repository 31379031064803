import router from "../router";
import {getRouteById} from "../routes";
import ObjectDispatcher from "./ObjectDispatcher";
import storage from "../storage";
import RouteDispatcher from "./RouteDispatcher";

interface DesignerModeInfo {
    active: boolean
    adding: boolean
    editing: number
    duplicating: number
}

export default class DesignerModeDispatcher extends ObjectDispatcher<DesignerModeInfo> {
    static readonly instance: DesignerModeDispatcher =
        new DesignerModeDispatcher({active: false, adding: false, editing: 0, duplicating: 0});

    toggle() {
        this.updateState({active: !this.active, adding: false, editing: 0, duplicating: 0});
    }

    get inactive() {
        return !this.active;
    }

    get active() {
        return this.value?.active ?? false;
    }

    setActive(active: boolean) {
        this.updateState({active});
    }

    get adding() {
        return this.value?.adding ?? false;
    }

    setAdding(adding: boolean) {
        this.updateState({adding, editing: 0, duplicating: 0});
    }

    get editing(): number {
        return this.value?.editing ?? 0;
    }

    get duplicating(): number {
        return this.value?.duplicating ?? 0;
    }

    setEditing(editing: number) {
        this.updateState({adding: false, duplicating: 0, editing})
    }

    setDuplicating(duplicating: number) {
        this.updateState({adding: false, editing: 0, duplicating})
    }

    get addingOrEditing(): boolean {
        return this.adding || Boolean(this.editing);
    }

    resetState() {
        this.updateState({adding: false, editing: 0, duplicating: 0});
    }
}

DesignerModeDispatcher.instance.subscribeMapped(
    v => v.value?.active,
    value => {

        const currentRoute = RouteDispatcher.instance.value?.at(-1);
        if (!currentRoute) {
            return;
        }

        if (value) {
            storage.setValue("designerMode", true);
        } else {
            storage.deleteValue("designerMode");
        }

        let path: string;

        switch (currentRoute?.route?.id) {
            case "customDashboard":
                path = currentRoute.pathname;
                break;
            default:
                path = getRouteById(value ? "queryEditor" : "dashboard")?.fullPath as string;
                break;
        }

        if (!path) {
            return;
        }

        router.navigate(path, {replace: true}).then();
    }
);

storage.getValue<boolean | undefined>("designerMode")
    .then(v => v === true)
    .then(active => DesignerModeDispatcher.instance.updateState({active}))
    .catch(() => {});