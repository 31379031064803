import "./private-layout.scss";
import {Outlet, useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {Nav, Stack} from "react-bootstrap";
import useDispatcher from "../hooks/useDispatcher";
import LoggedInUserDispatcher from "../dispatcher/LoggedInUserDispatcher";
import LanguagePicker from "../component/LanguagePicker";
import TokenDispatcher from "../dispatcher/TokenDispatcher";
import ThemeButton from "../component/ThemeButton";
import useTranslation from "../hooks/useTranslation";
import React, {useCallback} from "react";
import {getRouteById} from "../routes";
import {RouteIdDispatcher} from "../dispatcher/RouteDispatcher";
import DesignerModeDispatcher from "../dispatcher/DesignerModeDispatcher";
import If from "../component/If";
import classNames from "classnames";
import DashboardList from "../component/dashboard/DashboardList";
import AddOrEditDashboardNavItem from "../component/dashboard/AddOrEditDashboardNavItem";

function UserInfo() {
    const [user] = useDispatcher(LoggedInUserDispatcher.instance);

    return <Stack direction="horizontal" gap={2} className="small">
        <i className="bi bi-person fs-4"></i>
        <Stack direction="vertical" gap={0} className="justify-content-center">
            <span className="fw-bold text-capitalize">{user?.username}</span>
            <span className="text-muted text-capitalize small">{user?.cpoName}</span>
        </Stack>
        <button className="btn btn-link m-0 p-0 ms-auto exit-button" onClick={() => TokenDispatcher.logout()}>
            <i className="bi bi-box-arrow-right"></i>
        </button>
    </Stack>
}

export default function PrivateLayout() {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const [routeId] = useDispatcher(RouteIdDispatcher.instance) as unknown as [string]
    useDispatcher(DesignerModeDispatcher.instance);

    const onNavSelect = useCallback((key: string | null) => {
        key ??= "dashboard";
        const fullPath = key.includes("/") ? key : getRouteById(key)?.fullPath;
        if (!fullPath) {
            return;
        }
        navigate(fullPath);
    }, [navigate]);

    const onDesignerModeClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        DesignerModeDispatcher.instance.toggle();
    }, []);

    return <div className="private-layout-container layout-container">
        <div className={classNames("sidebar bg-body-tertiary", {
            "with-editor": DesignerModeDispatcher.instance.addingOrEditing
        })}>
            <div className="mt-1">
                <Link to="/" className="sidebar-title d-flex align-items-center gap-1">
                    <i className="bi bi-graph-up"></i>
                    <span className="label">ezBI</span>
                    <div className="m-auto"></div>
                    <span className="small fse-60 text-muted fw-normal fst-italic">
                        <span className="small">
                        {t("common.labels.easyBusinessIntelligence", "Easy Business Intelligence")}
                        </span>
                    </span>
                    <button className="btn btn-link m-0 p-0"
                            onClick={onDesignerModeClick}>
                        <i className={classNames("bi", {
                            "bi-building-gear": !DesignerModeDispatcher.instance.active,
                            "bi-building-fill-gear": DesignerModeDispatcher.instance.active
                        })}></i>
                    </button>
                </Link>
                <hr className="mb-2 mt-2" />
            </div>
            <div className="sidebar-content">
                <Nav variant="pills" className="flex-column" activeKey={routeId} onSelect={onNavSelect}>
                    <If condition={DesignerModeDispatcher.instance.inactive}>
                        <Nav.Link eventKey="dashboard">
                            <i className="bi bi-bar-chart-fill me-2"></i>
                            <span className="label">{t("pages.dashboard.title", "Dashboard")}</span>
                        </Nav.Link>
                    </If>
                    <If condition={DesignerModeDispatcher.instance.active}>
                        <Nav.Link eventKey="queryEditor">
                            <i className="bi bi-database-gear me-2"></i>
                            <span className="label">{t("pages.queryEditor.title", "Query Editor")}</span>
                        </Nav.Link>
                        <AddOrEditDashboardNavItem />
                    </If>
                    <DashboardList />
                </Nav>
            </div>
            <div className="sidebar-footer">
                <hr className="mb-2" />
                <Stack direction="vertical" gap={1}>
                    <UserInfo />
                    <div className="d-flex gap-2 align-items-center">
                        <ThemeButton className="btn-link m-0 p-0" />
                        <div className="m-auto"></div>
                        <LanguagePicker color="outline" className="private-language-picker ms-auto"
                                        toggleClassName="btn-sm btn-link m-0 p-0" />
                    </div>
                </Stack>
            </div>
        </div>
        <div className="content-container">
            <Outlet />
        </div>
    </div>
}
