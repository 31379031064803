import Dispatcher from "./Dispatcher";
import LoggedInUserDispatcher from "./LoggedInUserDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import {LocationRecordArray} from "../api/info/types";
import equals from "../utils/equals";

export default class LocationDispatcher extends Dispatcher<LocationRecordArray> {
    static readonly instance: LocationDispatcher = new LocationDispatcher();

    equals(next?: LocationRecordArray): boolean {
        return equals(this.value, next);
    }
}

let TIMEOUT_ID = 0;
const DELAY = 60000;

function update() {
    clearTimeout(TIMEOUT_ID);
    EzBiApiClient.instance.info.getLocations()
        .then(v => v.succeed())
        .then(v => v.sort((a, b) => a.name.trim().localeCompare(b.name.trim())))
        .then(locations => {
            LocationDispatcher.instance.update(locations);
        })
        .finally(() => {
            if (!LoggedInUserDispatcher.instance.value) {
                return;
            }
            TIMEOUT_ID = setTimeout(update, DELAY) as never;
        });
}

LoggedInUserDispatcher.instance.subscribe(v => {
    if (v) {
        update();
    } else {
        clearTimeout(TIMEOUT_ID);
    }
});
