import Dispatcher from "./Dispatcher";
import storage from "../storage";
import useDispatcher from "../hooks/useDispatcher";

const THEME_KEY = "theme";
const LIGHT_THEME = "light";
const DARK_THEME = "dark";
export const DEFAULT_THEME = LIGHT_THEME;

const browserTheme = window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches ? DARK_THEME : LIGHT_THEME;

export default class ThemeDispatcher extends Dispatcher<string> {
    static readonly instance: ThemeDispatcher = new ThemeDispatcher(browserTheme);
}

ThemeDispatcher.instance.subscribe(v => {
    if (v) {
        storage.setValue(THEME_KEY, v);
        document.documentElement.dataset.bsTheme = v;
    } else {
        storage.deleteValue(THEME_KEY);
        document.documentElement.removeAttribute("data-bs-theme");
    }
});
storage.getValue<string>(THEME_KEY).then(v => ThemeDispatcher.instance.update(v ?? DEFAULT_THEME));

export function useTheme(): [string, (v?: string) => void, () => void] {
    const [_theme, setTheme] = useDispatcher(ThemeDispatcher.instance);
    const theme = _theme ?? DEFAULT_THEME;

    return [
        theme,
        setTheme,
        () => setTheme(_theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME)
    ];
}
