import {InputComponentProps} from "../types";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import languages from "../languages";
import {Form, InputGroup} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";

export type LocalizedString = Record<string, string>;

export interface MultiLangStringEditorProps extends InputComponentProps<LocalizedString> {
    multiline?: boolean
}

export default function MultiLangStringEditor({value, id, onInput, placeholder, multiline, disabled}: React.PropsWithoutRef<MultiLangStringEditorProps>) {
    const [t] = useTranslation();

    const [v, setValue] = useState<LocalizedString>(() => ({...value}));

    const onInputCallback = useCallback((e: FormEvent<HTMLTextAreaElement>) => {
        const element = e.currentTarget;
        v[element.lang] = element.value;
        if (onInput instanceof Function) {
            onInput(v);
        }
        setValue({...v});
    }, [v, onInput]);

    useEffect(() => {
        setValue({...value});
    }, [value]);

    const ph = placeholder ?? "";

    return <div id={id as never} className="d-flex flex-column gap-2">
        {
            languages.map(({name}) => <InputGroup key={name} size="sm">
                <InputGroup.Text className="text-uppercase">
                    {name}
                </InputGroup.Text>
                {multiline ? <Form.Control as="textarea" rows={3} className="no-resize" value={v[name] ?? ""} size="sm"
                                           onInput={onInputCallback} lang={name}
                                           placeholder={t(
                                               ph,
                                               {args: {lang: t(name)}}
                                           )} disabled={disabled ?? false} />
                : <Form.Control value={v[name] ?? ""} size="sm"
                                onInput={onInputCallback} lang={name}
                                placeholder={t(
                                    ph,
                                    {args: {lang: t(name)}, default: ph}
                                )} disabled={disabled ?? false} />}

            </InputGroup>)
        }
    </div>;
}
