import {AddQueryRequest, QueriesResponse, Query, QueryBase} from "../api/session/types";
import LoggedInDispatcher from "./LoggedInDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import ListDispatcher from "./ListDispatcher";

export default class QueryListDispatcher extends ListDispatcher<Query, AddQueryRequest, QueryBase> {
    public static instance: QueryListDispatcher = new QueryListDispatcher({action: undefined, items: [], requestIndex: 0});

    doRefresh() {
        this.run(EzBiApiClient.instance.session.queries(), "loading")
            .success((items: QueriesResponse) => this.updateState({items}));
    }

    doAdd(query: Partial<AddQueryRequest>) {
        this.run(EzBiApiClient.instance.session.addQuery(query), "adding")
            .success((query: Query) => this.updateState({items: [query, ...this.items]}));
    }

    doUpdate(query: QueryBase) {
        this.run(EzBiApiClient.instance.session.updateQuery(query), "updating")
            .success((query: Query) => {
                const items = this.items.slice();
                const current = items.find(v => v.id === query.id);
                if (!current) {
                    return;
                }
                Object.assign(current, query);
                this.updateState({items, [query.id]: undefined});
            });
        this.updateState({[query.id]: {updating: true}} as never);
    }

    replaceQuery(query: QueryBase) {
        const queries = this.items.slice();
        const current = queries.find(v => v.id === query.id);
        if (!current) {
            return;
        }
        Object.assign(current, query);
    }

    doDelete(id: number) {
        this.run(EzBiApiClient.instance.session.deleteQuery(id), "deleting")
            .success(() => this.updateState({items: this.items.filter(v => v.id !== id), [id]: undefined}))
        this.updateState({[id]: {deleting: true}} as never)
    }
}

LoggedInDispatcher.instance.subscribe(value => {
    if (!value) {
        return;
    }
    QueryListDispatcher.instance.doRefresh();
});
