import {BaseNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {FormEvent, useCallback} from "react";
import {Handle, Position} from "reactflow";
import {hiddenHandleStyle, rightHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import {useFlowIncomers} from "../context/QueryEditorContext";

export function OrderNode({id}: Node<BaseNodeInfo>) {
    const [t] = useTranslation();
    const [incomers, dispatcher] = useFlowIncomers(id);
    const onDeleteClick = (e: React.MouseEvent<HTMLElement>, id: string, incomerId: string) => {
        dispatcher.deleteOrder(incomerId, id);
        e.preventDefault();
        e.stopPropagation();
    };

    const onDescendingInput = useCallback((e: FormEvent<HTMLInputElement>) => {
        dispatcher.setOrderType(e.currentTarget.name, e.currentTarget.checked ? "DESC" : "ASC");
    }, [dispatcher]);

    return <div className="d-flex gap-2 align-items-center">
        <div className="alert alert-info m-0 query-designer-row-filter">
            <div
                className="alert-heading small fw-bold d-flex justify-content-between align-items-center no-line-height gap-2">
                <span>{t("mapping.ORDER", "Order By")}</span>
            </div>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
            {
                incomers.map(incomer => <div className="d-flex alert alert-info m-0 p-1 gap-1 align-items-center" key={incomer.id}>
                    <label className="form-label m-0 no-wrap d-flex align-items-center gap-1">
                        <input type="checkbox" className="form-check-input m-0" name={incomer.id} onInput={onDescendingInput}/>
                        <span>{t("mapping.DESCENDING", "Group")}</span>
                    </label>
                    <button onClick={e => onDeleteClick(e, id, incomer.id)}
                            className="btn btn-sm btn-link link-body-emphasis"><i
                        className="bi bi-trash-fill no-line-height"></i></button>
                    <Handle position={Position.Right} style={hiddenHandleStyle} type="target" id={incomer.id}
                            isConnectable={false}/>
                </div>)
            }
            <div className="alert alert-success fw-bolder h-min-content p-1 m-0 small">
                {t("mapping.ADD", "Add")}
                <Handle position={Position.Right} style={rightHandleStyle} type="target" id="input"/>
            </div>
        </div>
    </div>
}
