import type {IApiClientConfig} from "ez-api-client/dist/api/types";
import {toApiContext} from "ez-api-client/dist/utils";
import {ApiClient} from "ez-api-client/dist/api/ApiClient";

import config from "../config";
import AuthApiCategory from "./auth/AuthApiCategory";
import SessionApiCategory from "./session/SessionApiCategory";
import {retrieveAccessToken} from "../dispatcher/TokenDispatcher";
import ContractsApiCategory from "./contracts/ContractsApiCategory";
import UsersApiCategory from "./users/UsersApiCategory";
import StatsApiCategory from "./stats/StatsApiCategory";
import InfoApiCategory from "./info/InfoApiCategory";
import CPQLApiCategory from "./cpql/CPQLApiCategory";
import {cachingProviders} from "./caching";

const clientConfig: IApiClientConfig = {
    name: "ez-bi",
    baseUri: new URL(config.ezBiApiHost),
    requestModifier: {
        authorize(req: RequestInit): Promise<void> {
            return retrieveAccessToken()
                .then(token => {
                    if (!token?.access?.token) {
                        return;
                    }

                    const headers = req.headers as Headers;
                    headers.set("Authorization", `Bearer ${token.access.token}`);
                })
                .catch(() => {});
        }
    },
    cachingProvider: {
        async canLoadFromCache(endpoint: URL, requestBody: any): Promise<boolean> {
            for (const provider of cachingProviders) {
                if (await provider.canLoadFromCache(endpoint, requestBody)) {
                    return true;
                }
            }
            return false;
        },

        async loadFromCache(endpoint: URL, requestBody: any): Promise<any> {
            for (const provider of cachingProviders) {
                if (await provider.canLoadFromCache(endpoint, requestBody)) {
                    return await provider.loadFromCache(endpoint, requestBody);
                }
            }
        },

        async saveToCache(endpoint: URL, requestBody: any, response: any): Promise<void> {
            for (const provider of cachingProviders) {
                if (await provider.canLoadFromCache(endpoint, requestBody)) {
                    return await provider.saveToCache(endpoint, requestBody, response);
                }
            }
        }
    }

}

export default class EzBiApiClient extends ApiClient {
    static readonly instance = new EzBiApiClient();

    constructor() {
        super(() => toApiContext(clientConfig));
    }

    readonly auth = new AuthApiCategory(this);

    readonly session = new SessionApiCategory(this);

    readonly contracts = new ContractsApiCategory(this);

    readonly users = new UsersApiCategory(this);

    readonly stats = new StatsApiCategory(this);

    readonly info = new InfoApiCategory(this);

    readonly cpql = new CPQLApiCategory(this);
}
