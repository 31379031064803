import {AreaChart, ResponsiveContainer} from "recharts";
import {useQueryDispatcher} from "../query-editor/context/QueryEditorContext";
import createChartComponents from "./createChartComponents";

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export function EzBIAreaChart() {
    const [, queryDispatcher] = useQueryDispatcher();
    const components = createChartComponents(queryDispatcher);

    return <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data}>
            {components}
        </AreaChart>
    </ResponsiveContainer>
}
