import type {IApiExecutionContextFactory} from "ez-api-client/dist/api/types";
import {AMEResult, ResponseOnlyAMEResult} from "ez-api-client/dist/api/types";
import {CPQLCompileRequest, CPQLCompileResponse, CPQLExecRequest, CPQLExecResponse, CPQLMappingResponse} from "./types";
import Exec from "./Exec";
import Mapping from "./Mapping";
import Compile from "./Compile";

export default class CPQLApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.exec = this.exec.bind(this);
        this.mapping = this.mapping.bind(this);
        this.compile = this.compile.bind(this);
    }

    exec(req: CPQLExecRequest): AMEResult<CPQLExecRequest, CPQLExecResponse> {
        return Exec.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Exec.instance, req)
        );
    }

    mapping(): ResponseOnlyAMEResult<CPQLMappingResponse> {
        return Mapping.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Mapping.instance, undefined)
        );
    }

    compile(req: CPQLCompileRequest): AMEResult<CPQLCompileRequest, CPQLCompileResponse> {
        return Compile.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Compile.instance, req)
        );
    }
}
