import React, {useCallback, useState} from "react";
import {ChartFilterProps} from "./Chart";
import useDispatcher from "../hooks/useDispatcher";
import {CPOInfo} from "../api/info/types";
import useSet from "../hooks/useSet";
import LanguageDispatcher, {DEFAULT_LANGUAGE} from "../dispatcher/LanguageDispatcher";
import {InputGroup} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import If from "./If";
import equals from "../utils/equals";
import CPODispatcher from "../dispatcher/CPODispatcher";

export default function ChartCPOPicker({onChange}: ChartFilterProps) {
    const [cpos] = useDispatcher(CPODispatcher.instance);
    const selectedIds = useSet<number>([], set => {
        onChange?.({cpo_ids: [...set]}, (key, a, b) => key === "cpo_ids" && equals(a, b))
    });

    const [query, setQuery] = useState("");
    const [t] = useTranslation();

    const locale = LanguageDispatcher.instance.value ?? DEFAULT_LANGUAGE;

    const filteredOptions = cpos?.filter(cur => {
        if (selectedIds.has(cur.id)) {
            return false;
        }

        return !(query
            && !cur.name.toLocaleLowerCase(locale).includes(query));
    }) ?? [];

    const handleCPOClick = useCallback((e: React.MouseEvent<HTMLOptionElement>) => {
        e.preventDefault();
        e.stopPropagation();
        selectedIds.add(Number(e.currentTarget.value));
    }, [selectedIds]);

    function getSelectedItems(): CPOInfo[] {
        return cpos?.filter(cpo => selectedIds.has(cpo.id)) ?? [];
    }

    const handleItemRemove = useCallback((e: React.MouseEvent<HTMLElement>) => {
        selectedIds.delete(Number(e.currentTarget.dataset.id));
        e.preventDefault();
        e.stopPropagation();
    }, [selectedIds]);

    const handleSearchChange = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value.toLocaleLowerCase(LanguageDispatcher.instance.value ?? DEFAULT_LANGUAGE));
    }, []);

    return <form noValidate className="vstack gap-2" onSubmit={e => e.preventDefault()}>
        <fieldset>
            <legend className="small">{t("chart.labels.cpos", "CPOs")}</legend>
            <div className="d-flex gap-3 align-items-stretch">
                <InputGroup className="input-group-vertical search w-50">
                    <input type="text" className="form-control flex-grow-0"
                           placeholder={t("charts.labels.search", "Search...")}
                           value={query}
                           onInput={handleSearchChange} />
                    <select className="form-select flex-grow-1 flex-fill" size={7}>
                        {filteredOptions
                            .map(item => <option onClick={handleCPOClick}
                                                  value={item.id}
                                                  title={`${item.name}`}
                                                  key={item.id}>
                                {item.name}
                            </option>)}
                    </select>
                </InputGroup>
                <div className="w-50 d-flex flex-column align-items-stretch input-group-vertical">
                    <If condition={selectedIds.isEmpty}>
                        <div className="mb-0 small alert alert-secondary h-100 d-flex align-items-center justify-content-center">
                            {t("charts.labels.pickLocationsFromLocationList", "Please pick a location from location list.")}
                        </div>
                    </If>
                    <If condition={selectedIds.hasItems}>
                        <div className="alert alert-secondary bg-transparent h-100 m-0 d-flex flex-wrap flex-grow-0 align-items-center justify-content-center">
                            {
                                getSelectedItems().map(item => {
                                    return <button className="btn btn-link btn-sm" key={item.id}
                                                   data-id={item.id} onClick={handleItemRemove}>
                                        {item.name}
                                    </button>
                                })
                            }
                        </div>
                        <button className="btn btn-sm btn-primary d-flex gap-2 justify-content-center align-items-center" onClick={() => selectedIds.clear()}>
                            <i className="bi bi-trash"></i>
                            <span className="small">{t("charts.labels.clearAll", "Clear All")}</span>
                        </button>
                    </If>
                </div>
            </div>
        </fieldset>
    </form>
}
