import {QueriesResponse, Query} from "../../api/session/types";
import useTranslation from "../../hooks/useTranslation";
import useApi from "../../hooks/useApi";
import EzBiApiClient from "../../api/EzBiApiClient";
import React, {useCallback} from "react";
import {Dropdown} from "react-bootstrap";
import If from "../If";

export interface QueryPickerProps {
    onSelect(q: Query): void
}

export function QueryPicker({onSelect}: QueryPickerProps) {
    const [t] = useTranslation();
    const queriesApi = useApi(
        EzBiApiClient.instance.session.queries,
        []
    );

    const queries = (queriesApi.getData([]) as QueriesResponse)
        .sort((a, b) => a.name.localeCompare(b.name));

    const onDropDownSelect = useCallback((key: string | null) => {
        const id = Number(key);
        const query = queries.find(v => v.id === id);
        if (!query) {
            return;
        }
        onSelect(query);
    }, [queries, onSelect]);

    return <Dropdown onSelect={onDropDownSelect}>
        <Dropdown.Toggle size="sm" variant="outline-secondary">
            <i className="bi bi-plus-circle"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <If condition={queriesApi.loading}>
                <Dropdown.Header className="d-flex align-items-center justify-content-center p-2">
                    <span className="spinner-border spinner-border-sm"></span>
                </Dropdown.Header>
            </If>
            <If condition={Boolean(queriesApi.error)}>
                <Dropdown.Header className="d-flex align-items-center justify-content-center p-2">
                    <div className="alert alert-danger">
                        <div className="alert-heading">
                            {t("common.labels.error", "Error")}
                        </div>
                        <div>
                            {t(`error.${queriesApi.error?.errorCode}`, queriesApi.error?.errorMessage)}
                        </div>
                    </div>
                    <span className="spinner-border spinner-border-sm"></span>
                </Dropdown.Header>
            </If>
            <If condition={queriesApi.ready}>
                {
                    queries.map(q => <Dropdown.Item key={q.id} eventKey={String(q.id)}>
                        {q.name}
                    </Dropdown.Item>)
                }
            </If>
        </Dropdown.Menu>
    </Dropdown>
}
