import {InputComponentProps} from "../types";
import React, {useCallback, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useQueryEditorContext} from "./query-editor/context/QueryEditorContext";

export interface FieldPickerProps extends InputComponentProps<number> {

}

export default function FieldPicker({id, value: initialValue, onInput}: React.PropsWithoutRef<FieldPickerProps>) {
    const [value, setValue] = useState<number>(initialValue ?? 0);
    const dispatcher = useQueryEditorContext();
    const onSelectInput = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
        setValue(Number(e.currentTarget.value))
    }, []);
    const [fields, setFields] = useState<string[]>(() => []);

    useEffect(() => {
        if (onInput instanceof Function) {
            onInput(value);
        }
    }, [value, onInput]);

    useEffect(() => {
        return dispatcher.subscribeMapped(v => v.getSelectionFields(), (v) => {
            if (!v) {
                return;
            }
            setFields([...v]);
        }, true);
    }, [dispatcher]);

    return <Form.Select defaultValue={String(initialValue)} onInput={onSelectInput} id={id} size="sm">
        {
            fields.map((name, index) =>
                <option value={String(index)} key={index}>{name}</option>
            )
        }
    </Form.Select>
}
