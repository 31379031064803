import React, {useCallback, useEffect, useState} from "react";
import {Nav} from "react-bootstrap";
import If from "../If";
import MultiLangStringEditor, {LocalizedString} from "../MultiLangStringEditor";
import useTranslation from "../../hooks/useTranslation";
import languages from "../../languages";
import {DashboardInfo} from "./types";
import DashboardListDispatcher from "../../dispatcher/DashboardListDispatcher";
import IconPicker from "../icon-picker/IconPicker";
import {useDispatcherMapped} from "../../hooks/useDispatcher";
import DesignerModeDispatcher from "../../dispatcher/DesignerModeDispatcher";
import {Dashboard} from "../../api/session/types";

export interface AddOrEditDashboardNavItemProps {
    dashboard?: Dashboard
    duplicate?: boolean
}

export default function AddOrEditDashboardNavItem({dashboard, duplicate}: AddOrEditDashboardNavItemProps) {
    const data: DashboardInfo = dashboard?.data ? JSON.parse(dashboard.data) : {
        name: {},
        icon: ""
    } as DashboardInfo;

    const [t] = useTranslation();
    const [name, setName] = useState<LocalizedString>(data.name);
    const [icon, setIcon] = useState<string>(data.icon);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [adding] = useDispatcherMapped(
        DesignerModeDispatcher.instance,
        useCallback((d: DesignerModeDispatcher) => d.adding, [])
    );
    const [isBusy] = useDispatcherMapped(
        DashboardListDispatcher.instance,
        useCallback((d: DashboardListDispatcher) => d.isBusy, [])
    );

    const onAddClick = useCallback(() => DesignerModeDispatcher.instance.setAdding(true), []);
    const onCancelClick = useCallback(() => DesignerModeDispatcher.instance.setAdding(false), []);

    const onSaveClick = useCallback(() => {
        const dashboardInfo = {
            ...(dashboard?.data ? JSON.parse(dashboard.data) : undefined),
            name,
            icon
        } as DashboardInfo;

        const onDone = () => {
            DesignerModeDispatcher.instance.resetState();
        };

        if (dashboard && !duplicate) {
            DashboardListDispatcher.instance.doUpdate({
                id: dashboard.id,
                data: JSON.stringify(dashboardInfo)
            }, onDone);
        } else {
            DashboardListDispatcher.instance.doAdd(dashboardInfo, onDone)
        }
    }, [name, icon, dashboard, duplicate]);

    useEffect(() => {
        if (!name) {
            return;
        }
        setDisabled(!languages.map(v => name[v.name]).every(Boolean));
    }, [name]);

    const show = adding || Boolean(dashboard);

    return <Nav.Item>
        <If condition={show}>
            <div className="card shadow">
                <div className="d-flex flex-column card-body">
                    <div className="mb-3">
                        <label htmlFor="icon" className="form-label small text-capitalize mb-1">
                            {t(`common.labels.ICON`, "Icon")}
                        </label>
                        <IconPicker id="icon" value={icon} onInput={setIcon} disabled={isBusy} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label small text-capitalize mb-1">
                            {t(`common.labels.TITLE`, "Icon")}
                        </label>
                        <MultiLangStringEditor id="title" placeholder="common.labels.enterTitleIn" disabled={isBusy}
                                               value={name} onInput={setName} />
                    </div>
                    <div className="mb-3 d-flex justify-content-center align-items-center gap-2">
                        <button className="btn btn-danger btn-sm d-flex align-items-center gap-2" disabled={isBusy}
                                onClick={onCancelClick}>
                            <i className="bi bi-save no-line-height"></i>
                            <span>{t("common.labels.cancel", "Cancel")}</span>
                        </button>
                        <button className="btn btn-success btn-sm d-flex align-items-center gap-2"
                                disabled={disabled || isBusy}
                                onClick={onSaveClick}>
                            <If condition={isBusy}>
                                <span className="spinner-border spinner-border-sm"></span>
                            </If>
                            <i className="bi bi-save no-line-height"></i>
                            <span>{t("common.labels.save", "Save")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </If>
        <If condition={!show}>
            <button className="btn btn-link new-dashboard" onClick={onAddClick}>
                <i className="bi bi-plus-circle"></i>
            </button>
        </If>
    </Nav.Item>;
}
