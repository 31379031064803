import Dispatcher from "./Dispatcher";
import LanguageDispatcher, {DEFAULT_LANGUAGE} from "./LanguageDispatcher";
import languages from "../languages";
import isPlainObject from "../utils/isPlainObject";

type TranslationCache = Record<string, any>;

const translationCache: Record<string, TranslationCache> = {};

export default class TranslationsDispatcher extends Dispatcher<TranslationCache> {
    static readonly instance: TranslationsDispatcher = new TranslationsDispatcher({});
}

LanguageDispatcher.instance.subscribe(lang => {
    const _lang = lang ?? DEFAULT_LANGUAGE;
    if (isPlainObject(translationCache[_lang])) {
        TranslationsDispatcher.instance.update(translationCache[_lang]);
    }
});

languages.forEach(({name: _lang}) => {
    translationCache[_lang] =
        fetch(`/i18n/${_lang}.json`)
            .then(r => r.json())
            .catch(() => {
                translationCache[_lang] = {};
            })
            .then(records => {
                translationCache[_lang] = records;
            })
            .finally(() => {
                if (LanguageDispatcher.instance.value === _lang) {
                    LanguageDispatcher.instance.notify()
                }
            });
})
