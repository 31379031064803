export default function equals(a: unknown, b: unknown): boolean {
    if (a === b) {
        return true;
    }

    if (a === null || a === undefined || b === null || b === undefined) {
        return false;
    }

    if (typeof a !== typeof b) {
        return false;
    }

    switch (typeof a) {
        case "string":
        case "bigint":
        case "boolean":
        case "function":
        case "number":
        case "symbol":
        case "undefined":
            return a === b;
    }

    if (Object.getPrototypeOf(a) !== Object.getPrototypeOf(b)) {
        return false;
    }

    if (Array.isArray(a)) {
        const aryB: Array<unknown> = b as never;
        if (a.length !== aryB.length) {
            return false;
        }

        if (!a.length) {
            return true;
        }

        return a.every((item, i) => equals(item, aryB[i]));
    }

    const entriesA = Object.entries(a);
    const entriesB = Object.entries(b);

    if (entriesA.length !== entriesB.length) {
        return false;
    }

    if (!entriesA.length) {
        return true;
    }

    return entriesA.every(([key, value], i) => key === entriesB[i][0] && equals(value, entriesB[i][1]));
}
