import "./public-layout.scss";
import {Outlet} from "react-router";
import LanguagePicker from "../component/LanguagePicker";
import ThemeButton from "../component/ThemeButton";

export default function PublicLayout() {
    return <div className="public-layout-container layout-container">
        <Outlet />
        <div className="hstack gap-2 align-content-end language-picker-container">
            <ThemeButton className="btn-primary" />
            <LanguagePicker drop="up-centered" />
        </div>
    </div>
}
