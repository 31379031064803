import Dispatcher from "./Dispatcher";
import equals from "../utils/equals";

export default class ObjectDispatcher<T extends object> extends Dispatcher<T> {
    private readonly _updaters: Map<string, (...args: any[]) => any> = new Map();

    updateState(value: Partial<T>) {
        this.value = {...this.value, ...value} as never;
    }

    equals(next?: T): boolean {
        return equals(this.value, next);
    }

    asUpdater<
        TFunc extends (...args: Parameters<TFunc>) => ReturnType<TFunc>,
        Key extends keyof T,
        ValueType extends T[Key]
    >(key: keyof T, func: (...args: Parameters<TFunc>) => ValueType, id?: string): TFunc {
        id ??= key as string;

        if (this._updaters.has(id)) {
            return this._updaters.get(id) as TFunc;
        }

        const wrapper: TFunc = ((...args) => {
            this.updateState({[key]: func(...args)} as never);
        }) as TFunc;

        this._updaters.set(id, wrapper);

        return wrapper;
    }
}
