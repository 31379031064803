import equals from "../utils/equals";

export type DispatcherCallback<T> = (v?: T) => void;
export type DispatcherUnsubscribe = () => void;

export type MappingCallback<D, R> = (dispatcher: D) => R;

export default class Dispatcher<T = unknown> {
    private _value: T | undefined;
    private _listeners: Set<DispatcherCallback<T>>;
    private _timer: number = 0;

    constructor(value?: T) {
        this._value = value;
        this._listeners = new Set();
        this.update = this.update.bind(this);
        this.notify = this.notify.bind(this);
    }

    ifPresent(cb: (v: T) => boolean) {
        if (this.value !== undefined && cb instanceof Function) {
            cb(this.value) && this.notifyAsync();
        }
    }

    protected notifyAsync() {
        cancelAnimationFrame(this._timer);
        this._timer = requestAnimationFrame(this.notify);
    }

    map<V>(cb: (v: T) => V): V | undefined {
        if (this.value !== undefined && cb instanceof Function) {
            return cb(this.value);
        }

        return undefined;
    }

    notify(): void {
        const {value} = this;
        this._listeners.forEach(cb => cb(value));
    }

    get value(): T | undefined {
        return this._value;
    }

    set value(v: T | undefined) {
        if (!this.equals(v)) {
            this._value = v;
            this.onUpdate(v);
        }
    }

    protected onUpdate(v: T | undefined) {
        this.notify();
    }

    update(v?: T): T | undefined {
        this.value = v;
        return v;
    }

    equals(next?: T): boolean {
        return this._value === next;
    }

    subscribe(callback: DispatcherCallback<T>, trigger: boolean = false): DispatcherUnsubscribe {
        if (typeof callback === "function" && !this._listeners.has(callback)) {
            this._listeners.add(callback);
            if (trigger) {
                callback(this.value);
            }
        }

        return () => this._listeners.delete(callback);
    }

    subscribeMapped<D extends Dispatcher<T>, R = unknown>(
        this: D,
        mapping: MappingCallback<D, R>,
        callback: DispatcherCallback<R>,
        trigger: boolean = false
    ): DispatcherUnsubscribe {
        let last: R = mapping(this);
        let triggered = false;
        return this.subscribe(() => {
            const current = mapping(this as never);
            if (equals(current, last) && (!trigger || triggered)) {
                return;
            }
            callback(last = current);
            triggered = true;
        }, trigger);
    }
}
