import EzBiApiMethod from "../EzBiApiMethod";
import {CPQLMappingResponse} from "./types";

export class Mapping extends EzBiApiMethod<undefined, CPQLMappingResponse> {
    static readonly instance: Mapping = new Mapping();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "cpql/mapping";
    }
}

export default Mapping;
