import Dispatcher from "./Dispatcher";
import LoggedInUserDispatcher from "./LoggedInUserDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import {CPOInfoArray} from "../api/info/types";
import equals from "../utils/equals";

export default class CPODispatcher extends Dispatcher<CPOInfoArray> {
    static readonly instance: CPODispatcher = new CPODispatcher();

    equals(next?: CPOInfoArray): boolean {
        return equals(this.value, next);
    }
}

let TIMEOUT_ID = 0;
const DELAY = 60000;

function update() {
    clearTimeout(TIMEOUT_ID);
    EzBiApiClient.instance.info.getCPOs()
        .then(v => v.succeed())
        .then(v => v.sort((a, b) => a.name.trim().localeCompare(b.name.trim())))
        .then(locations => {
            CPODispatcher.instance.update(locations);
        })
        .finally(() => {
            if (!CPODispatcher.instance.value) {
                return;
            }
            TIMEOUT_ID = setTimeout(update, DELAY) as never;
        });
}

LoggedInUserDispatcher.instance.subscribe(v => {
    if (v) {
        update();
    } else {
        clearTimeout(TIMEOUT_ID);
    }
});
