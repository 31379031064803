export default function objectKey(value?: Record<any, any>): string {
    if (Array.isArray(value)) {
        return value.map(objectKey).join("@");
    }

    return Object.entries(value ?? {})
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([, v]) => v)
        .join("$")
}
