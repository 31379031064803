import EzBiApiMethod from "../EzBiApiMethod";
import {Query, QueryBase} from "./types";

export class UpdateQuery extends EzBiApiMethod<QueryBase, Query> {
    static readonly instance: UpdateQuery = new UpdateQuery();
    readonly isPrivate = true;
    readonly method = "PUT";

    get path(): string {
        return "session/queries";
    }
}

export default UpdateQuery;
