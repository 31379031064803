import EzBiApiMethod from "../EzBiApiMethod";
import {DeleteDashboardRequest} from "./types";

export class DeleteDashboard extends EzBiApiMethod<DeleteDashboardRequest, boolean> {
    static readonly instance: DeleteDashboard = new DeleteDashboard();
    readonly isPrivate = true;
    readonly method = "DELETE";

    get path(): string {
        return "session/dashboards?id={id}";
    }
}

export default DeleteDashboard;
