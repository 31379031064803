import EzBiApiMethod from "../EzBiApiMethod";
import {CPOInfoArray} from "./types";

export class CPOs extends EzBiApiMethod<undefined, CPOInfoArray> {
    static readonly instance: CPOs = new CPOs();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "info/cpos";
    }
}

export default CPOs;
