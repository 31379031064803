import If from "../../If";
import React, {useCallback} from "react";
import useTranslation, {TranslateFunction} from "../../../hooks/useTranslation";
import {Dropdown} from "react-bootstrap";
import QueryDispatcher from "../QueryDispatcher";
import {useQueryEditorContext} from "../context/QueryEditorContext";

export interface ChangeProps {
    keys: string[]
    translate: (key: string, func: TranslateFunction) => string
    defaultKey: string
    onSelect: (id: string, key: string, dispatcher: QueryDispatcher, title: string) => void
}

export interface NodeControlProps {
    nodeId: string
    nodeSelected: boolean
    canDelete?: boolean
    canDuplicate?: boolean
    changeProps?: ChangeProps
    control?: JSX.Element
}

export default function NodeToolBox({nodeId, nodeSelected, control, canDelete, canDuplicate, changeProps}: NodeControlProps) {
    const dispatcher = useQueryEditorContext();

    const [t] = useTranslation();
    const onDeleteClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        dispatcher.deleteNode(nodeId);
        e.preventDefault();
        e.stopPropagation();
    }, [dispatcher, nodeId]);

    const onDuplicateClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        dispatcher.duplicateNode(nodeId);
        e.preventDefault();
        e.stopPropagation();
    }, [dispatcher, nodeId]);

    const translate = changeProps?.translate;
    const onSelectProp = changeProps?.onSelect;

    const onSelect = useCallback((key: string) => {
        onSelectProp?.(nodeId, key, dispatcher, (translate?.(key, t) ?? dispatcher.getTitleById(nodeId)) as string);
    }, [nodeId, dispatcher, onSelectProp, t, translate]);

    if (!nodeSelected) {
        return <></>;
    }

    return <div className="d-flex align-items-center gap-1 w-100 justify-content-center toolbox nodrag">
        <If condition={Boolean(control)}>
            {control}
        </If>
        <If condition={Boolean(changeProps)}>
            <Dropdown onSelect={onSelect as never}>
                <Dropdown.Toggle variant="primary" size="sm">
                    {t("common.labels.change", "Change")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        (changeProps?.keys ?? []).filter(v => v !== changeProps?.defaultKey)
                            .map(type => <Dropdown.Item key={type} eventKey={type}>
                            {translate?.(type, t)}
                        </Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>
        </If>
        <If condition={canDuplicate ?? true}>
            <button className="btn btn-sm btn-success"
                    onClick={onDuplicateClick}>
                <i className="ezbi ezbi-duplicate"></i>
            </button>
        </If>
        <If condition={canDelete ?? true}>
            <button className="btn btn-sm btn-danger"
                    onClick={onDeleteClick}>
                <i className="bi bi-trash-fill"></i>
            </button>
        </If>
    </div>
}
