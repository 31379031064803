import Dispatcher from "./Dispatcher";
import {StatsResult} from "../api/stats/types";
import LoggedInUserDispatcher from "./LoggedInUserDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import equals from "../utils/equals";

export default class StatDispatcher extends Dispatcher<StatsResult> {
    static readonly instance: StatDispatcher = new StatDispatcher();

    equals(next?: StatsResult): boolean {
        return equals(this.value, next);
    }
}

let TIMEOUT_ID = 0;

function update() {
    clearTimeout(TIMEOUT_ID);
    EzBiApiClient.instance.stats.getStats()
        .then(v => v.succeed())
        .then(stats => {
            StatDispatcher.instance.update(stats);
        })
        .finally(() => {
            if (!LoggedInUserDispatcher.instance.value) {
                return;
            }
            TIMEOUT_ID = 0;
            //TIMEOUT_ID = setTimeout(update, DELAY) as never;
        });
}

LoggedInUserDispatcher.instance.subscribe(v => {
    if (v) {
        update();
    } else {
        clearTimeout(TIMEOUT_ID);
    }
});
