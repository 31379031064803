import If from "../If";
import React from "react";
import RemainingMilliseconds from "../RemainingMilliseconds";
import {OverlayTrigger, Popover} from "react-bootstrap";
import useTranslation from "../../hooks/useTranslation";
import {useQueryUpdaterDispatcher} from "./context/QueryUpdaterContext";

interface IApiError {
    httpCode: number;
    errorCode: string;
    errorMessage: string;
}

export default function QueryUpdateStateInfo() {
    const [t] = useTranslation();
    const [state] = useQueryUpdaterDispatcher();
    const error: IApiError = state.error as IApiError;

    return <>
        <If condition={Boolean(state.pending)}>
            <span className="small alert alert-warning p-1 m-0 d-flex align-items-center">
                <i className="bi bi-hourglass animation zoom-in-out d-block"></i>
                (<RemainingMilliseconds date={state.pending} />)
            </span>
        </If>
        <If condition={state.loading}>
            <span className="spinner-border spinner-border-sm"></span>
        </If>
        <If condition={Boolean(error)}>
            <OverlayTrigger overlay={(
                <Popover id="popover-basic">
                    <Popover.Header as="h3">{t([`error.${error?.errorCode}`, 'common.labels.error'], 'Error')}</Popover.Header>
                    <Popover.Body>
                        {error?.errorMessage}
                    </Popover.Body>
                </Popover>
            ) as never}>
                <i className="bi bi-exclamation-triangle-fill text-danger cursor-pointer" tabIndex={-1}></i>
            </OverlayTrigger>
        </If>
    </>;
}
