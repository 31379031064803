import EzBiApiMethod from "../EzBiApiMethod";
import {QueriesResponse} from "./types";

export class Queries extends EzBiApiMethod<undefined, QueriesResponse> {
    static readonly instance: Queries = new Queries();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "session/queries";
    }
}

export default Queries;
