import useDispatcher from "../../hooks/useDispatcher";
import React, {useCallback, useEffect} from "react";
import {Query} from "../../api/session/types";
import {DashboardItem} from "../dashboard/types";
import classNames from "classnames";
import {distributionStates, hAlignStates, justifyStates, vAlignStates} from "./constants";
import {StateButton} from "./StateButton";
import {QueryBlock} from "./QueryBlock";
import {QueryPicker} from "./QueryPicker";
import {QueryBlockAction} from "./types";
import {DashboardDesignerDispatcher} from "./DashboardDesignerDispatcher";

export interface CustomDashboardContentProps {
    designerMode: boolean
    data: string
}

export function CustomDashboardContent({designerMode, data}: CustomDashboardContentProps) {
    const [, , designer] = useDispatcher(DashboardDesignerDispatcher.instance);

    const onDashboardAction = useCallback((key: string, name: string) => {
        designer.updateState({[name]: key} as never);
    }, [designer]);

    const onQueryPicked = useCallback((query: Query) => {
        designer.add(query);
    }, [designer]);

    const onItemAction = useCallback((action: QueryBlockAction, id: number, d?: Partial<DashboardItem>) => {
        switch (action) {
            case "delete":
                designer.setItems(v => v.filter(q => q.id !== id));
                break;
            case "fs-enter":
                designer.updateItem(id, {fs: true});
                break;
            case "fs-exit":
                designer.updateItem(id, {fs: false});
                break;
            case "resize":
                if (d) {
                    designer.updateItem(id, d)
                }
                break;
            case "drag":
            {
                const source = d?.id as number;
                designer.setItems(items => {
                    const src = items.findIndex(v => v.id === source);
                    const dst = items.findIndex(v => v.id === id);
                    if (src === dst) {
                        return items;
                    }
                    const result = items.slice();
                    const tmp = result[src];
                    result[src] = result[dst];
                    result[dst] = tmp;
                    return result;
                });
                break;
            }
        }
    }, [designer]);

    useEffect(() => {
        designer.update({...JSON.parse(data)});
    }, [data, designer]);

    const items = <div className={classNames("custom-dashboard", {
        [`justify-content-${designer.map(v => v.j)}`]: Boolean(designer.map(v => v.j)),
        [`align-content-${designer.map(v => v.va)}`]: Boolean(designer.map(v => v.va)),
        [`align-items-${designer.map(v => v.ha)}`]: Boolean(designer.map(v => v.ha)),
        [`flex-${designer.map(v => v.d)}`]: Boolean(designer.map(v => v.d)),
    })}>
        {
            designer.value?.items?.map((item) => <QueryBlock
                key={item.id} item={item} onAction={onItemAction}/>)
        }
    </div>;

    if (designerMode) {
        return <div className="designer-container">
            <div className="d-flex gap-2 justify-content-center align-items-center">
                <StateButton states={hAlignStates} defaultValue={designer.map(v => v.ha) ?? "start"}
                             onSelect={onDashboardAction} name="ha"/>
                <StateButton states={vAlignStates} defaultValue={designer.map(v => v.va) ?? "start"}
                             onSelect={onDashboardAction} name="va"/>
                <StateButton states={justifyStates} defaultValue={designer.map(v => v.j) ?? "start"}
                             onSelect={onDashboardAction} name="j"/>
                <StateButton states={distributionStates} defaultValue={designer.map(v => v.d) ?? "start"}
                             onSelect={onDashboardAction} name="d"/>
                <QueryPicker onSelect={onQueryPicked}/>
            </div>
            {items}
        </div>
    }

    return items;
}
