import {BinaryNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {useMemo} from "react";
import {Handle, Position} from "reactflow";
import {allBinaryOperators, leftHandleStyle, rightHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import classNames from "classnames";
import If from "../../If";
import {Template} from "./Template";
import NodeToolBox, {ChangeProps} from "./NodeToolBox";
import {useConnectedEdges} from "../context/QueryEditorContext";

export function BinaryNode(node: Node<BinaryNodeInfo>) {
    const [t] = useTranslation();
    const [edges, dispatcher] = useConnectedEdges(node.id);

    const type = node?.data?.object?.type ?? "";

    const changeProps: ChangeProps = useMemo(() => ({
        keys: allBinaryOperators,
        defaultKey: type,
        translate(key, func) {
            return func(`mapping.expression.binary.${key}.name`, key);
        },
        onSelect(id, key, dispatcher, title) {
            dispatcher.updateNode<BinaryNodeInfo>(id, node => {
                if (node.data.object.type !== key) {
                    node.data.object.type = key as never;
                    node.data.title = title;
                    return true;
                }
                return false;
            })
        }
    } as ChangeProps), [type]);

    if (!node) {
        return <></>;
    }

    const data = node.data;

    return <div className={classNames("query-item", {"query-item-active": node.selected})}>
        <div className="d-flex align-items-center gap-2">
            <Handle position={Position.Left} style={leftHandleStyle} type="source" id="output"/>
            <NodeToolBox nodeId={node.id} nodeSelected={node.selected ?? false} canDelete={true}
                         changeProps={changeProps} />
            <If condition={Boolean(node)}>
                <div className={classNames("alert m-0 d-flex flex-column align-items-center gap-2", {
                    "alert-danger": data.min > edges.length,
                    "alert-success": data.min <= edges.length
                })}>
                    <span>{t(`mapping.expression.binary.${type}.name`, type)}</span>
                </div>
                <div className="d-flex flex-column gap-2 align-items-center">
                    {
                        data.inputs.map((input, index) => {
                            const edge = edges.find(v => v.targetHandle === input);
                            const hasIssue = index < data.min && !edge;

                            return <Template key={input}>
                                <div className={classNames("alert m-0 p-1 px-2 d-flex align-items-center small gap-2", {
                                    "alert-danger": hasIssue,
                                    "alert-success": !hasIssue
                                })}>
                                    <span>{t("mapping.name.value", "value")}</span>
                                    <If condition={!!edge}>
                                        <button className="btn btn-sm btn-link link-body-emphasis nodrag no-line-height m-0 p-0" onClick={() => dispatcher.deleteEdgeObj(edge)}>
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </If>
                                    <Handle position={Position.Right} style={rightHandleStyle} type="target" id={input} isConnectable={!edge}/>
                                </div>
                            </Template>
                        })
                    }
                </div>
            </If>
        </div>
    </div>;
}
