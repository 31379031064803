import {Outlet, RouteObject, useLocation, useNavigate} from "react-router";
import PublicLayout from "./layout/PublicLayout";
import Login from "./view/Login";
import useDispatcher from "./hooks/useDispatcher";
import LoggedInDispatcher from "./dispatcher/LoggedInDispatcher";
import {matchRoutes} from "react-router-dom";
import {useEffect} from "react";
import Dashboard from "./view/Dashboard";
import PrivateLayout from "./layout/PrivateLayout";
import RouteDispatcher from "./dispatcher/RouteDispatcher";
import QueryEditor from "./view/QueryEditor";
import DesignerModeDispatcher from "./dispatcher/DesignerModeDispatcher";
import CustomDashboard from "./view/CustomDashboard";

export type CustomRouteObject = Omit<RouteObject, "children"> & {
    children?: CustomRouteObject[],
    private?: boolean
    restricted?: boolean
    fullPath?: string
    parent?: CustomRouteObject
    designerMode?: boolean
};

function _getRouteById(id: string, routes?: CustomRouteObject[]): CustomRouteObject | null {
    if (!routes) {
        return null;
    }

    for (const route of routes) {
        if (route.id === id) {
            return route;
        }

        const child = _getRouteById(id, route.children);

        if (child) {
            return child;
        }
    }

    return null;
}

export function getRouteById(id: string): CustomRouteObject | null {
    return _getRouteById(id, routes);
}

const RouteHandler = () => {
    const location = useLocation();
    const [loggedIn] = useDispatcher(LoggedInDispatcher.instance);
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn === undefined) {
            return;
        }

        let isPrivate = false;
        let isRestricted = false;
        let designerModeItem = false;

        const routeList = matchRoutes(routes as never, location);
        routeList?.forEach(obj => {
            const route = obj.route as CustomRouteObject;

            if (route.private !== undefined) {
                isPrivate = route.private;
            }

            if (route.restricted !== undefined) {
                isRestricted = route.restricted;
            }

            if (route.designerMode !== undefined) {
                designerModeItem = route.designerMode;
            }
        });

        if (loggedIn) {
            if (isRestricted) {
                navigate(getRouteById("dashboard")?.fullPath as never, {replace: true});
                return;
            }

            if (designerModeItem && !DesignerModeDispatcher.instance.active) {
                navigate(getRouteById("dashboard")?.fullPath as never, {replace: true});
                return;
            }
        } else if (isPrivate) {
            navigate(getRouteById("login")?.fullPath as never, {replace: true});
            return;
        }

        RouteDispatcher.instance.update(routeList as never);

        // eslint-disable-next-line
    }, [navigate, location.pathname, loggedIn])

    return null;
}

const Index = () => {
    return <>
        <RouteHandler />
        <Outlet />
    </>;
};

const routes: CustomRouteObject[] = [
    {
        path: "/",
        Component: Index,
        private: true,
        restricted: true,
        children: [
            {
                Component: PrivateLayout,
                restricted: false,
                children: [
                    {
                        id: "dashboard",
                        path: "dashboard",
                        Component: Dashboard
                    },
                    {
                        id: "queryEditor",
                        path: "query-editor",
                        Component: QueryEditor,
                        designerMode: true
                    },
                    {
                        id: 'customDashboard',
                        path: "dashboard/:id",
                        Component: CustomDashboard
                    }
                ]
            },
            {
                path: "auth",
                Component: PublicLayout,
                children: [
                    {
                        id: "login",
                        path: "login",
                        Component: Login,
                    }
                ]
            }
        ]
    }
];

function updateParents(parent?: CustomRouteObject, children?: CustomRouteObject[]) {
    if (!children) {
        return;
    }

    const parentFullPath = parent?.fullPath ?? "";

    children.forEach(child => {
        child.parent = parent;
        child.fullPath = child.path?.startsWith("/")
            ? child.path
            : `${parentFullPath === "/" ? "" : parentFullPath}${child.path ? "/" : ""}${child.path ?? ""}`;
        updateParents(child, child.children);
    });
}

updateParents(undefined, routes);

export default routes;
