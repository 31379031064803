import type {AMEResult, IApiExecutionContextFactory, ResponseOnlyAMEResult} from "ez-api-client/dist/api/types";
import GetStats from "./GetStats";
import {StatsByResult, StatsRequest, StatsResult, UsageInfoArray} from "./types";
import {Usages, UsagesRequest} from "./Usages";
import GetStatsBy from "./GetStatsBy";

export default class StatsApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.getStats = this.getStats.bind(this);
        this.usages = this.usages.bind(this);
    }

    getStats(): ResponseOnlyAMEResult<StatsResult> {
        return GetStats.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(GetStats.instance, undefined)
        );
    }

    getStatsBy(request: StatsRequest): AMEResult<StatsRequest, StatsByResult> {
        return GetStatsBy.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(GetStatsBy.instance, request)
        );
    }

    usages(request?: UsagesRequest): AMEResult<UsagesRequest, UsageInfoArray> {
        request ??= {};
        return Usages.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Usages.instance, request)
        )
    }
}
