import type {IApiCachingProvider} from "ez-api-client/dist/types"
import {IndexedDbStorage} from "ez-api-client/dist/storage/IndexedDbStorage"

interface ExpirableCacheStorageData {
    data: unknown
    expiry: number
}

abstract class ExpirableCacheStorage extends IndexedDbStorage {
    private readonly syncRoot: Record<string, Promise<unknown>> = {};

    protected constructor(storeName: string, private readonly duration: number, dbName: string = "cache") {
        super(dbName, storeName);
    }

    async canRead(key: string): Promise<boolean> {
        await this.syncRoot[key];
        let resolve: any;
        this.syncRoot[key] = new Promise<unknown>(resolve1 => (resolve = resolve1));

        try {
            const value: ExpirableCacheStorageData = await super.getValue(key);

            if (!value || Date.now() > value.expiry) {
                await super.setValue(key, {data: undefined, expiry: Date.now() + 30000});
                return false;
            }

            return true;
        } finally {
            resolve();
        }
    }

    read(key: string): Promise<unknown> {
        return super.getValue<ExpirableCacheStorageData>(key).then(v => v.data)
            .then(v => {
                if (v === undefined) {
                    return new Promise(resolve => {
                        const channel = new BroadcastChannel(`${this.constructor.name}::${key}`);
                        channel.onmessage = () => {
                            resolve(this.read(key));
                            channel.onmessage = null;
                            channel.close();
                        };
                    });
                }
                return v;
            });
    }

    async setValue(key: string, value: unknown): Promise<void> {
        await super.setValue(key, {
            data: value,
            expiry: Date.now() + this.duration
        });
        const channel = new BroadcastChannel(`${this.constructor.name}::${key}`);
        channel.postMessage("set");
        channel.close();
    }
}

class CPQLExecCacheStorage extends ExpirableCacheStorage {
    constructor() {
        super("cpql_exec", 60000);
    }
}

class CPQLCompileCacheStorage extends ExpirableCacheStorage {
    constructor() {
        super("cpql_compile", 0xFFFFFF);
    }
}

const cpqlExecCacheStorage = new CPQLExecCacheStorage();
const cpqlCompileCacheStorage = new CPQLCompileCacheStorage();

export class CPQLExecCachingProvider implements IApiCachingProvider {

    async canLoadFromCache(endpoint: URL, requestBody: unknown): Promise<boolean> {
        if (!endpoint.pathname.endsWith("cpql/exec")) {
            return false;
        }

        return await cpqlExecCacheStorage.canRead(JSON.stringify(requestBody));
    }

    loadFromCache(endpoint: URL, requestBody: unknown): Promise<unknown> {
        return cpqlExecCacheStorage.read(JSON.stringify(requestBody));
    }

    saveToCache(endpoint: URL, requestBody: unknown, response: unknown): Promise<void> {
        return cpqlExecCacheStorage.setValue(JSON.stringify(requestBody), response);
    }
}

export class CPQLCompileCachingProvider implements IApiCachingProvider {

    async canLoadFromCache(endpoint: URL, requestBody: unknown): Promise<boolean> {
        if (!endpoint.pathname.endsWith("cpql/compile")) {
            return false;
        }

        return await cpqlCompileCacheStorage.canRead(JSON.stringify(requestBody));
    }

    loadFromCache(endpoint: URL, requestBody: unknown): Promise<unknown> {
        return cpqlCompileCacheStorage.read(JSON.stringify(requestBody));
    }

    saveToCache(endpoint: URL, requestBody: unknown, response: unknown): Promise<void> {
        return cpqlCompileCacheStorage.setValue(JSON.stringify(requestBody), response);
    }
}

export const cachingProviders: IApiCachingProvider[] = [
    new CPQLExecCachingProvider(),
    new CPQLCompileCachingProvider()
];

