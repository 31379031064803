import {Query} from "../../api/session/types";
import React, {useCallback, useEffect, useState} from "react";
import If from "../If";
import {Button, ButtonGroup, Nav} from "react-bootstrap";
import NameInput from "../NameInput";
import ShouldDelete from "../ShouldDelete";
import QueryListDispatcher from "../../dispatcher/QueryListDispatcher";
import {useDispatcherMapped} from "../../hooks/useDispatcher";

export interface QueryItemProps {
    query: Query
}

export function QueryItem({query}: React.PropsWithoutRef<QueryItemProps>) {
    const id = query.id;
    const [mode, setMode] = useState<"edit" | "delete" | "clone" | null>(null);
    const [{updating, deleting}] = useDispatcherMapped(
        QueryListDispatcher.instance,
        useCallback((d: QueryListDispatcher) => d.getItemStatus(id), [id])
    );
    const [adding] = useDispatcherMapped(
        QueryListDispatcher.instance,
        useCallback((d: QueryListDispatcher) => d.isAdding, [])
    );

    const setToNullMode = useCallback(() => setMode(null), []);

    useEffect(() => {
        if (!updating && !adding) {
            setToNullMode();
        }
    }, [updating, adding, setToNullMode]);

    const doUpdate = useCallback((value: string) => {
        if (value === query.name) {
            setToNullMode();
            return;
        }

        QueryListDispatcher.instance.doUpdate({...query, name: value} as never);
    }, [query, setToNullMode]);

    const doClone = useCallback((value: string) => {
        QueryListDispatcher.instance.doAdd({...query, name: value || query.name} as never);
    }, [query]);

    const doDelete = useCallback(() => {
        QueryListDispatcher.instance.doDelete(query.id);
    }, [query]);

    return <>
        <If condition={!(mode as never)}>
            <div className="query-item gap-1">
                <Nav.Link eventKey={query.id} className="py-1 text-muted">
                    {query.name}
                </Nav.Link>
                <ButtonGroup size="sm" className="gap-0">
                    <Button className="btn-no-color"
                            onClick={() => setMode("clone")}>
                        <i className="ezbi ezbi-duplicate"></i>
                    </Button>
                    <Button className="btn-no-color"
                            onClick={() => setMode("edit")}>
                        <i className="bi bi-pencil-fill"></i>
                    </Button>
                    <Button className="btn-no-color"
                            onClick={() => setMode("delete")}>
                        <i className="bi bi-trash"></i>
                    </Button>
                </ButtonGroup>
            </div>
        </If>
        <If condition={mode === "clone"}>
            <Nav.Item>
                <NameInput value={query.name} onCancel={setToNullMode} onAccept={doClone}
                           loading={adding}/>
            </Nav.Item>
        </If>
        <If condition={mode === "edit"}>
            <Nav.Item>
                <NameInput value={query.name} onCancel={setToNullMode} onAccept={doUpdate}
                           loading={updating}/>
            </Nav.Item>
        </If>
        <If condition={mode === "delete"}>
            <Nav.Item>
                <ShouldDelete onCancel={setToNullMode} onAccept={doDelete}
                              loading={deleting}/>
            </Nav.Item>
        </If>
    </>;
}
