import EzBiApiMethod from "../EzBiApiMethod";
import {CPQLCompileRequest, CPQLCompileResponse} from "./types";

export class Compile extends EzBiApiMethod<CPQLCompileRequest, CPQLCompileResponse> {
    static readonly instance: Compile = new Compile();
    readonly isPrivate = true;
    readonly method = "POST";

    get cacheable(): boolean {
        return true;
    }

    get path(): string {
        return "cpql/compile";
    }
}

export default Compile;
