import ObjectDispatcher from "./ObjectDispatcher";
import {ApiTask, IApiError, ReqParam, ResParam} from "./ApiDispatcher";
import {TranslateFunction} from "../hooks/useTranslation";

export type ListAction = "loading" | "adding" | "updating" | "deleting" | undefined;

export interface ListStatus {
    updating: boolean
    deleting: boolean
}

export interface ListItem {
    id: number
}

export interface ListDispatcherState<T extends ListItem> {
    items: T[]
    action: ListAction
    lastError?: IApiError
    requestIndex: number
    [K: number]: ListStatus
}

export default abstract class ListDispatcher<
    Q extends ListItem,
    TAdd,
    TUpdate
    >
    extends ObjectDispatcher<ListDispatcherState<Q>> {

    private lastTask?: ApiTask<any, any>;

    protected constructor(v: any) {
        super(v);
        this.abort = this.abort.bind(this);
        this.doRefresh = this.doRefresh.bind(this);
    }

    get lastError(): IApiError | undefined {
        return this.value?.lastError;
    }

    get hasError() {
        return Boolean(this.lastError);
    }

    formatError(t: TranslateFunction) {
        const err = this.lastError;
        if (!err) {
            return "";
        }

        return t(`error.${err.errorCode}`, err.errorMessage);
    }

    get hasData() {
        return !this.isBusy && !this.hasError && !!this.items.length;
    }

    get hasNotData() {
        return !this.isBusy && !this.hasError && !this.items.length;
    }

    get hasItems() {
        return Boolean(this.items.length);
    }

    get isBusy() {
        return !!this.value?.action;
    }

    get isLoading() {
        return this.action === "loading";
    }

    get isAdding() {
        return this.action === "adding";
    }

    get isUpdating() {
        return this.action === "updating";
    }

    get isDeleting() {
        return this.action === "deleting";
    }

    get action(): ListAction {
        return this.value?.action;
    }

    get requestIndex(): number {
        return this.value?.requestIndex ?? 0;
    }

    protected onUpdate(v: ListDispatcherState<Q> | undefined) {
        this.notifyAsync();
    }

    get items(): Q[] {
        return this.value?.items ?? [];
    }

    getItemById(id: number): Q | undefined {
        return this.items.find(v => v.id === id);
    }

    private beginAction(action: ListAction) {
        this.updateState({action, lastError: undefined});
    }

    private endAction(state?: Partial<ListDispatcherState<Q>>) {
        this.updateState({...state, action: undefined});
    }

    protected run<P extends any>(result: P, action: ListAction, done?: () => void): ApiTask<ReqParam<P>, ResParam<P>> {
        this.lastTask?.abort?.();
        this.lastTask = new ApiTask<any, any>(result as never);
        this.beginAction(action);
        return this.lastTask.fail(lastError => this.updateState({lastError}))
            .done(() => {
                this.endAction({requestIndex: this.requestIndex + 1});
                done?.();
            });
    }

    abort() {
        this.lastTask?.abort();
        this.endAction();
    }

    abstract doRefresh(done?: () => void): void

    abstract doAdd(item: Partial<TAdd>, done?: () => void): void;

    abstract doUpdate(item: TUpdate, done?: () => void): void;

    abstract doDelete(id: number, done?: () => void): void;

    getItemStatus(id: number): ListStatus {
        return {updating: false, deleting: false, ...this.value?.[id]};
    }
}
