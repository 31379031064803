import {Alert, Button, Card, Form, InputGroup, Spinner} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import {FormEvent, useState} from "react";
import type {IApiError} from "ez-api-client/dist/api/types";

import "./login.scss";
import If from "../component/If";
import EzBiApiClient from "../api/EzBiApiClient";
import TokenDispatcher from "../dispatcher/TokenDispatcher";
import AppLogo from "../component/AppLogo";

export default function Login() {
    const [t] = useTranslation();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null as IApiError | null);

    const handleSubmit = (e: FormEvent) => {
        const form: HTMLFormElement = e.currentTarget as never;
        setValidated(false);
        e.stopPropagation();
        e.preventDefault();

        const usernameElement = form.elements.namedItem("username") as HTMLInputElement;
        const companyCodeElement = form.elements.namedItem("companyCode") as HTMLInputElement;
        const passwordElement = form.elements.namedItem("password") as HTMLInputElement;

        if (!form.checkValidity()) {
            (form.querySelector("input:invalid") as HTMLElement)?.focus();
            setValidated(true);
            return;
        }

        setLoading(true);
        setError(null);
        EzBiApiClient.instance.auth.authenticate({
            companyCode: companyCodeElement?.value,
            username: usernameElement?.value,
            password: passwordElement?.value
        }).then(result => {
            let error = result.error;
            const data = result.data;

            if (!error) {
                if (data) {
                    TokenDispatcher.instance.update(data);
                    return;
                }

                error = {errorCode: "10001", errorMessage: "Unexpected result.", httpCode: 0};
            }

            setError(error);
            requestAnimationFrame(() => usernameElement?.focus());
            return;

        }).finally(() => setLoading(false));
    };

    return <Card className="login-form-container">
        <Card.Header>
            <div className="d-flex align-items-center gap-2 fs-3 fw-semibold">
                <span><AppLogo /></span>
                <span>{t("common.app.name", "ezBI")}</span>
                <span className="fs-6 text-muted ms-auto fst-italic">
                    <span className="small">{t("common.app.description", "Easy Business Intelligence")}</span>
                </span>
            </div>
        </Card.Header>
        <Card.Body>
            <Alert className="d-flex gap-2">
                <span><i className="bi bi-info-circle"></i></span>
                <span>{t("pages.login.title", "Log In")}</span>
            </Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <fieldset disabled={loading} className="d-flex flex-column gap-3">
                    <Form.Group>
                        <Form.Label>{t("pages.login.companyCode", "Company Code")}</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text><i className="bi bi-person"></i></InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder={t("pages.login.companyCode", "Company Code")}
                                autoFocus
                                name="companyCode"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("common.form.required", {args: {field: "pages.login.companyCode"}, default: "Company code field is required"})}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("pages.login.username", "Username")}</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text><i className="bi bi-person"></i></InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder={t("pages.login.username", "Username")}
                                name="username"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("common.form.required", {args: {field: "pages.login.username", default: "Username field is required"}})}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("pages.login.password", "Password")}</Form.Label>
                        <InputGroup>
                            <InputGroup.Text><i className="bi bi-key"></i></InputGroup.Text>
                            <Form.Control
                                required
                                type="password"
                                placeholder={t("pages.login.password", "Password")}
                                name="password"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("common.form.required", {args: {field: "pages.login.password", default: "Username field is required"}})}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <If condition={!loading}>
                            <Button type="submit">
                                <div className="d-flex gap-2">
                                    <i className="bi bi-unlock"></i>
                                    <span>
                                {t("pages.login.submit", "Log In")}
                            </span>
                                </div>
                            </Button>
                        </If>
                        <If condition={loading}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">{t("common.loading", "Loading...")}</span>
                            </Spinner>
                        </If>
                    </Form.Group>
                </fieldset>
            </Form>
        </Card.Body>
        <If condition={Boolean(error)}>
            <Card.Footer>
                <div className="d-flex align-content-center gap-2 text-danger">
                    <i className="bi bi-x-circle-fill"></i>
                    <span>{t(`error.${error?.errorCode}`, error?.errorMessage)}</span>
                </div>
            </Card.Footer>
        </If>
    </Card>;
}
