import React, {createContext, useContext} from "react";
import {Period} from "../types";

export interface PeriodContextProps {
    period?: Period
    setPeriod?: React.Dispatch<Period>
}

export const PeriodContext = createContext<PeriodContextProps>({});

export interface PeriodContextProviderProps {
    period?: Period
    setPeriod?: React.Dispatch<Period>
}

export function PeriodContextProvider({period, setPeriod, ...props}: React.PropsWithChildren<PeriodContextProviderProps>) {
    return <PeriodContext.Provider value={{period, setPeriod}} {...props} />;
}

export const usePeriodContext = () => useContext(PeriodContext);