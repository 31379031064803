import Dispatcher from "./Dispatcher";
import {AgnosticRouteMatch, AgnosticRouteObject} from "@remix-run/router/utils";
import {CustomRouteObject} from "../routes";
import equals from "../utils/equals";

export default class RouteDispatcher extends Dispatcher<AgnosticRouteMatch<string, AgnosticRouteObject & CustomRouteObject>[] | null> {
    static readonly instance: RouteDispatcher = new RouteDispatcher();

    equals(next?: AgnosticRouteMatch<string, AgnosticRouteObject & CustomRouteObject>[] | null): boolean {
        return equals(this.value, next);
    }
}

export class RouteIdDispatcher extends Dispatcher<string> {
    static readonly instance: RouteIdDispatcher = new RouteIdDispatcher();
}

RouteDispatcher.instance.subscribe(list => {
    if (!list) {
        return;
    }

    const routeMatch = list?.filter(x => Boolean(x?.route?.id)).at(-1);
    if (!routeMatch) {
        return;
    }

    let id = routeMatch.route.id;
    if (id === "customDashboard") {
       id = routeMatch.pathname;
    }

    RouteIdDispatcher.instance.update(id);
});
