import {FunctionNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React from "react";
import {Handle, Position} from "reactflow";
import {leftHandleStyle, rightHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import classNames from "classnames";
import {Template} from "./Template";
import If from "../../If";
import NodeToolBox from "./NodeToolBox";
import {useConnectedEdges} from "../context/QueryEditorContext";

export function FunctionNode({data: {object: func}, selected, id}: Node<FunctionNodeInfo>) {
    const [t] = useTranslation();
    const [edges, dispatcher] = useConnectedEdges(id);

    const allRequiredConnected = (func.varArgs ? func.parameters.slice(0, -1) : func.parameters)
        .every(v => edges.some(q => q.targetHandle === v.name));

    const varArgIndex = func.varArgs ? func.parameters.length - 1 : Number.MAX_SAFE_INTEGER;

    return <div className={classNames("query-item", {"query-item-active": selected})}>
        <Handle position={Position.Left} style={leftHandleStyle} type="source" id={`f-output-${id}`}/>
        <NodeToolBox nodeId={id} nodeSelected={selected ?? false} />
        <div className="d-flex align-items-center gap-1">
            <div className={classNames("alert m-0 query-designer-function", {
                "alert-danger": !allRequiredConnected,
                "alert-success": allRequiredConnected
            })}>
                <div
                    className="alert-heading small fw-bold d-flex justify-content-between align-items-center no-line-height gap-2">
                    <span>{t(`mapping.function.${func.name}.name`, func.name)}</span>
                </div>
            </div>
            <div className="d-flex flex-column gap-3">
                {
                    func.parameters.map((param, index) => {
                        const edge = edges.find(v => v.targetHandle === param.name);
                        return <Template key={param.name}>
                            <div className={classNames("alert small fw-bolder p-1 m-0 d-flex align-items-center justify-content-between gap-2", {
                                "alert-danger":  (index < varArgIndex) && !Boolean(edge),
                                "alert-success": (index >= varArgIndex) || Boolean(edge)
                            })}>
                                <span>{t(`mapping.name.${param.name.split(/[|@]/)[0]}`, param.name.split(/[|@]/)[0])}</span>
                                <If condition={Boolean(edge)}>
                                    <button className="btn btn-sm btn-link link-body-emphasis nodrag no-line-height m-0 p-0"
                                            onClick={() => dispatcher.deleteEdgeObj(edge)}>
                                        <i className="bi bi-trash-fill"></i>
                                    </button>
                                </If>
                                <Handle position={Position.Right} style={rightHandleStyle} type="target" id={param.name}
                                        isConnectable={!Boolean(edge)} />
                            </div>
                        </Template>;
                    })
                }
            </div>
        </div>
    </div>;
}
