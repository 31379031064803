import EzBiApiMethod from "../EzBiApiMethod";
import {DateFilter, DateGroupListResponse} from "../types";

export class Contracts extends EzBiApiMethod<DateFilter, DateGroupListResponse> {
    static readonly instance: Contracts = new Contracts();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "contracts?from={!from}&to={!to}";
    }

    get responseModifier() {
        return undefined;
    }
}

export default Contracts;
