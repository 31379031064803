import useTranslation from "../../hooks/useTranslation";
import QueryExecutionStateInfo from "./QueryExecutionStateInfo";
import React from "react";
import CPQLResultTable from "../cpql-result-table/CPQLResultTable";

export function QueryRawResults() {
    const [t] = useTranslation();

    return <div className="card shadow">
        <div className="card-header d-flex align-items-center">
            <div
                className="fw-bold small">{t("pages.queryEditor.rawQueryResult", "Raw Query Result")}</div>
            <div className="m-auto"></div>
            <QueryExecutionStateInfo/>
        </div>
        <div className="card-body h-0 overflow-hidden">
            <CPQLResultTable />
        </div>
    </div>;
}
