import {InputComponent} from "../../types";
import {PresentationProp, PresentationPropType, PresentationType} from "./types";
import IconPicker from "../icon-picker/IconPicker";
import MultiLangStringEditor from "../MultiLangStringEditor";
import ShortPeriodPicker from "../ShortPeriodPicker";
import useTranslation from "../../hooks/useTranslation";
import {useQueryEditorContext} from "./context/QueryEditorContext";
import React, {useCallback} from "react";
import ThemePicker from "../ThemePicker";
import FieldPicker from "../FieldPicker";
import enumKeys from "../../utils/enumKeys";
import {useDispatcherMapped} from "../../hooks/useDispatcher";
import QueryDispatcher from "./QueryDispatcher";
import ChartComponentsSettings from "../charts/ChartComponentsSettings";
import If from "../If";
import {CHART_COMPONENTS_PROP_NAME} from "../charts/constants";

const ChartComponentsProp: PresentationProp = {
    key: CHART_COMPONENTS_PROP_NAME,
    type: PresentationPropType.CHART_COMPONENTS,
    name: "CHART_COMPONENTS",
    showLabel: false,
    legend: true
};

export const PRESENTATION_TYPE_PROPS: Record<string, PresentationProp[]> = {
    [PresentationType.TABLE]: [],
    [PresentationType.COUNTER]: [
        {
            key: "field",
            name: "FIELD",
            type: PresentationPropType.FIELD
        },
        {
            key: "format",
            name: "FORMAT",
            type: PresentationPropType.STRING,
            placeholder: "(value) (name)"
        },
        {
            key: "icon",
            name: "ICON",
            type: PresentationPropType.ICON
        },
        {
            key: "theme",
            name: "THEME",
            type: PresentationPropType.THEME
        },
        {
            key: "title",
            name: "TITLE",
            type: PresentationPropType.STRING,
            placeholder: "common.labels.enterTitleIn"
        },
        {
            key: "description",
            name: "DESCRIPTION",
            type: PresentationPropType.STRING,
            placeholder: "common.labels.enterDescriptionIn",
            multiline: true
        },
        {
            key: "reloadPeriod",
            name: "REFRESH_FREQUENCY",
            type: PresentationPropType.FREQUENCY
        }
    ],
    [PresentationType.AREA_CHART]: [
        {
            ...ChartComponentsProp,
            cartesianGrid: true,
            area: Number.MAX_SAFE_INTEGER
        }
    ]
}
export const PROPERTY_EDITOR_MAPPING: Record<number, InputComponent> = {
    [PresentationPropType.ICON]: IconPicker,
    [PresentationPropType.STRING]: MultiLangStringEditor,
    [PresentationPropType.FREQUENCY]: ShortPeriodPicker,
    [PresentationPropType.THEME]: ThemePicker,
    [PresentationPropType.FIELD]: FieldPicker,
    [PresentationPropType.CHART_COMPONENTS]: ChartComponentsSettings,
}

export function QueryDesignerSettings() {
    const [t] = useTranslation();
    const [{type, itemProps}, dispatcher] = useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => ({
            id: d.map(v => v.id),
            type: d.type,
            itemProps: d.getProps()
        }), [])
    );

    return <div className="settings p-3 d-flex flex-column align-items-stretch">
        <div>
            <label htmlFor="presentationType" className="form-label small">
                {t('common.labels.PRESENTATION_TYPE', 'Presentation Type')}
            </label>
            <select id="presentationType" className="form-select form-select-sm text-capitalize"
                    value={type}
                    onInput={e => dispatcher.setType(e.currentTarget.value as never)}>
                {
                    enumKeys(PresentationType, true)
                        .map(key => <option key={key} value={key} className="text-capitalize">{
                            t(
                                `pages.queryEditor.PT_${key}`,
                                key.replace(/_+/g, " ").toLocaleLowerCase()
                            )
                        }</option>)
                }
            </select>
        </div>
        {
            (PRESENTATION_TYPE_PROPS[type] ?? [])
                .map(({key, type, placeholder, name, showLabel, ...props}) => {
                    const Component: InputComponent = PROPERTY_EDITOR_MAPPING[type];

                    return <div key={key} className="mt-3">
                        <If condition={showLabel ?? true}>
                            <label htmlFor={key} className="form-label small text-capitalize mb-1">
                                {t(`common.labels.${name}`, name.toLowerCase().replace(/_+/g, " "))}
                            </label>
                        </If>
                        <Component id={key} placeholder={placeholder as string}
                                   value={itemProps[key]}
                                   onInput={v => dispatcher.setProp(key, v)} {...props} />
                    </div>
                })
        }
    </div>
}
