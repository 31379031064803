import useTranslation from "../../hooks/useTranslation";
import React, {useEffect} from "react";
import {QueryDesignerSettings} from "./QueryDesignerSettings";
import {QueryDesignerCanvas} from "./flow/QueryDesignerCanvas";
import {QueryDesignerPanel} from "./flow/QueryDesignerPanel";
import {useQueryEditorContext} from "./context/QueryEditorContext";
import {useQueryCompilationContext} from "./context/QueryCompilationContext";
import QueryUpdateStateInfo from "./QueryUpdateStateInfo";

import "reactflow/dist/style.css";

export function QueryDesigner() {
    const [t] = useTranslation();

    const queryDispatcher = useQueryEditorContext();
    const queryCompilationDispatcher = useQueryCompilationContext();

    useEffect(() => {
        return queryDispatcher.subscribeMapped(
            d => d.createQueryAst(),
            ast => {
                if (!ast) {
                    queryCompilationDispatcher.setResponse({query: ""});
                    return;
                }
                queryCompilationDispatcher.call(ast as never, true);
            }
        );
    }, [queryDispatcher, queryCompilationDispatcher]);

    return <div className="designer card shadow" data-resize-key="queryEditor.designer" data-resize-initial=".5">
        <div className="card-header d-flex align-items-center">
            <div className="fw-bold small">{t("pages.queryEditor.designer", "Designer")}</div>
            <div className="m-auto"></div>
            <QueryUpdateStateInfo />
        </div>
        <div className="card-body designer-body">
            <QueryDesignerSettings/>
            <QueryDesignerCanvas/>
            <QueryDesignerPanel/>
        </div>
    </div>;
}
