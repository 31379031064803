import Dispatcher from "./Dispatcher";
import storage from "../storage";
import {AuthenticateResponse, TokenInfo} from "../api/auth/types";
import EzBiApiClient from "../api/EzBiApiClient";

const TOKEN_KEY = "token";

export default class TokenDispatcher extends Dispatcher<AuthenticateResponse> {
    static readonly instance: TokenDispatcher = new TokenDispatcher(null as never);

    static tokenInfoEquals(a?: TokenInfo, b?: TokenInfo): boolean {
        return a?.token === b?.token && a?.expires_at === b?.expires_at;
    }

    equals(next?: AuthenticateResponse): boolean {
        if (this.value === null) {
            return false;
        }
        return TokenDispatcher.tokenInfoEquals(this.value?.access, next?.access)
            && TokenDispatcher.tokenInfoEquals(this.value?.refresh, next?.refresh);
    }

    static logout() {
        EzBiApiClient.instance.session.logout().then(result => result.succeed()).then(TokenDispatcher.instance.update);
    }

    static reset() {
        TokenDispatcher.instance.update(null as never);
    }
}

TokenDispatcher.instance.subscribe(v => v ? storage.setValue(TOKEN_KEY, v) : storage.deleteValue(TOKEN_KEY));

export function retrieveAccessToken(): Promise<AuthenticateResponse | undefined> {
    return storage.getValue<AuthenticateResponse>(TOKEN_KEY).then(v => {
        if (v) {
            if ((v.access?.expires_at ?? 0) < Date.now()) {
                if ((v.refresh?.expires_at ?? 0) < Date.now()) {
                    return undefined;
                }

                return EzBiApiClient.instance.auth.refreshToken({
                    token: v.refresh.token
                }).then(result => result.error ? undefined : result.data)
            }
        }

        return v;
    }).then(v => TokenDispatcher.instance.update(v));
}

retrieveAccessToken().then();
