import Dispatcher from "../dispatcher/Dispatcher";
import equals from "../utils/equals";
import {ApiMethodExecutionResult} from "ez-api-client/src/api/types";
import type {Dictionary} from "ez-api-client/dist/api/types";

export type ApiRequest = Dictionary | undefined;
export type ApiResponse = Dictionary | Dictionary[] | undefined;

export interface IApiError {
    httpCode: number;
    errorCode: string;
    errorMessage: string;
}

export interface ApiState<TReq extends ApiRequest, TRes extends ApiResponse> {
    pending: number
    loading: boolean
    request: TReq
    error?: IApiError
    result?: TRes
}

export enum ApiTaskState {
    Pending,
    Succeed,
    Failed,
    Aborted
}

export class ApiTask<TReq extends ApiRequest, TRes extends ApiResponse> {
    private _state = ApiTaskState.Pending;
    private _onSuccess?: (response: TRes) => void;
    private _onError?: (error: IApiError) => void;
    private _onDone?: () => void;

    constructor(public readonly result: ApiMethodExecutionResult<TReq, TRes>) {
        result.then(v => v.succeed())
            .then(value => {
                if (this._state !== ApiTaskState.Pending) {
                    return;
                }
                this._state = ApiTaskState.Succeed;
                if (this._onSuccess) {
                    this._onSuccess(value);
                }
            })
            .catch(error => {
                if (this._state !== ApiTaskState.Pending) {
                    return;
                }
                this._state = ApiTaskState.Failed;
                if (this._onError) {
                    this._onError(error);
                }
            })
            .finally(() => {
                if (this._onDone) {
                    this._onDone();
                }
            })
    }

    abort() {
        if (this._state !== ApiTaskState.Pending) {
            return;
        }
        this._state = ApiTaskState.Aborted;
        const abortController: AbortController = this.result?.context?.abortController as never;
        abortController?.abort();
    }

    success(cb: (response: TRes) => void): this {
        this._onSuccess = cb;
        return this;
    }

    fail(cb: (error: IApiError) => void): this {
        this._onError = cb;
        return this;
    }

    done(cb: () => void): this {
        this._onDone = cb;
        return this;
    }
}

export default class ApiDispatcher<TReq extends ApiRequest, TRes extends ApiResponse>
    extends Dispatcher<ApiState<TReq, TRes>> {
    private _executionTimer = 0;
    private _lastTask?: ApiTask<TReq, TRes>;
    private _lastRequest: TReq | undefined = undefined;

    constructor(private readonly runner: (req: TReq) => ApiMethodExecutionResult<TReq, TRes>, private readonly emptyRequest?: ApiState<TReq, TRes>) {
        super(emptyRequest);
        this.call = this.call.bind(this);
    }

    get lastRequest(): TReq | undefined {
        return this._lastRequest;
    }

    equals(next?: ApiState<TReq, TRes>): boolean {
        return equals(next, this.value);
    }

    set value(v: ApiState<TReq, TRes> | undefined) {
        super.value = v ?? this.emptyRequest;
    }

    get value(): ApiState<TReq, TRes> | undefined {
        return super.value;
    }

    private updateState(state: Partial<ApiState<TReq, TRes>>) {
        this.value = {...this.value, ...state} as never;
    }

    protected onUpdate(v: ApiState<TReq, TRes> | undefined) {
        this.notifyAsync();
    }

    abort() {
        this._lastTask?.abort();
        clearTimeout(this._executionTimer);
        if (!this._lastTask && this.value && this.value.pending) {
            this.updateState({pending: 0});
        } else {
            delete this._lastTask;
        }
    }

    get delay(): number {
        return 1000;
    }

    call(req: TReq, noWait?: boolean) {
        this.abort();
        this.updateState({pending: Date.now() + this.delay, error: undefined});

        const doExec = () => {
            const value: Partial<ApiState<TReq, TRes>> = {};

            this._lastTask = new ApiTask(this.runner(req))
                .success(result => (value.result = result as never))
                .fail(error => (value.error = error))
                .done(() => {
                    value.loading = false;
                    this._lastRequest = req;
                    this.updateState(value);
                }) as never;

            this.updateState({pending: 0, loading: true, request: req});
        };

        if (noWait) {
            this._executionTimer = setTimeout(doExec, 1) as never;
        } else {
            this.notifyAsync();
            this._executionTimer = setTimeout(doExec, this.delay) as never;
        }
    }

    setResponse(res: TRes) {
        this.abort();
        const value = this.value as ApiState<TReq, TRes>;
        if (equals(value.result, res)) {
            return;
        }
        this.updateState({result: res});
    }

    modifyResponse(cb: (v?: TRes) => TRes) {
        const result = cb(this.value?.result);
        if (equals(this.value?.result, result)) {
            return;
        }
        this.updateState({result});
    }

    init(req: TReq) {
        this._lastRequest = req;
    }

    reply(noWait?: boolean) {
        this.call((this.value?.request as TReq ?? this.emptyRequest) as never, noWait);
    }
}
export type ReqParam<T extends any> = T extends ApiMethodExecutionResult<infer TReq, any> ? TReq extends ApiRequest ? TReq : never : never;
export type ResParam<T extends any> = T extends ApiMethodExecutionResult<any, infer TRes> ? TRes extends ApiResponse ? TRes : never : never;
