import {BaseNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {FormEvent, useCallback} from "react";
import {Handle, Position} from "reactflow";
import {hiddenHandleStyle, rightHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import {useFlowIncomers} from "../context/QueryEditorContext";
import If from "../../If";

export function SelectionNode({id, selected}: Node<BaseNodeInfo>) {
    const [t] = useTranslation();
    const [incomers, dispatcher] = useFlowIncomers(id);
    const onDeleteClick = (e: React.MouseEvent<HTMLElement>, id: string, incomerId: string) => {
        dispatcher.deleteSelection(incomerId, id);
        e.preventDefault();
        e.stopPropagation();
    };

    const onGroupInput = useCallback((e: FormEvent<HTMLInputElement>) => {
        dispatcher.setGrouped(e.currentTarget.name, e.currentTarget.checked);
    }, [dispatcher]);

    const onAliasInput = useCallback((e: FormEvent<HTMLInputElement>) => {
        dispatcher.setAlias(e.currentTarget.name, e.currentTarget.value);
    }, [dispatcher])

    return <div className="d-flex gap-2 align-items-center">
        <div className="alert alert-primary fw-bolder h-min-content p-1 m-0 small">
            {t("mapping.OUTPUT", "Output")}
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
            {
                incomers.map(incomer => <div className="d-flex flex-column alert alert-primary m-0 p-2 gap-2 align-items-center" key={incomer.id}>
                    <If condition={dispatcher.isGroupable(incomer.id)}>
                        <label className="form-label m-0 no-wrap d-flex align-items-center gap-1">
                            <input type="checkbox" className="form-check-input m-0" onInput={onGroupInput}
                                   name={incomer.id}/>
                            <span>{t("mapping.GROUP", "Group")}</span>
                        </label>
                    </If>
                    <div className="d-flex gap-1">
                        <input className="form-control form-control-sm alias" onInput={onAliasInput}
                               name={incomer.id}
                               placeholder={t("mapping.ALIAS", "Alias")}/>
                        <button onClick={e => onDeleteClick(e, id, incomer.id)}
                                className="btn btn-sm btn-link link-body-emphasis"><i
                            className="bi bi-trash-fill no-line-height"></i></button>
                    </div>
                    <Handle position={Position.Right} style={hiddenHandleStyle} type="target" id={incomer.id}
                            isConnectable={false}/>
                </div>)
            }
            <div className="alert alert-success fw-bolder h-min-content p-1 m-0 small">
                {t("mapping.ADD", "Add")}
                <Handle position={Position.Right} style={rightHandleStyle} type="target" id="input"/>
            </div>

        </div>
    </div>;
}
