import type {IApiExecutionContextFactory} from "ez-api-client/dist/api/types";
import {AMEResult, PlainAMEResult, ResponseOnlyAMEResult} from "ez-api-client/dist/api/types";
import {
    AddDashboardRequest,
    AddDashboardResponse,
    AddQueryRequest,
    AddQueryResponse,
    Dashboard,
    DashboardsResponse,
    DeleteDashboardRequest,
    DeleteQueryRequest,
    MeResponse,
    QueriesResponse,
    Query,
    QueryBase
} from "./types";
import Me from "./Me";
import Logout from "./Logout";
import Queries from "./Queries";
import AddQuery from "./AddQuery";
import UpdateQuery from "./UpdateQuery";
import DeleteQuery from "./DeleteQuery";
import Dashboards from "./Dashboards";
import AddDashboard from "./AddDashboard";
import UpdateDashboard from "./UpdateDashboard";
import DeleteDashboard from "./DeleteDashboard";

export default class SessionApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.me = this.me.bind(this);
        this.logout = this.logout.bind(this);
        this.queries = this.queries.bind(this);
        this.addQuery = this.addQuery.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.deleteQuery = this.deleteQuery.bind(this);
        this.dashboards = this.dashboards.bind(this);
        this.addDashboard = this.addDashboard.bind(this);
        this.updateDashboard = this.updateDashboard.bind(this);
        this.deleteDashboard = this.deleteDashboard.bind(this);
    }

    me(): ResponseOnlyAMEResult<MeResponse> {
        return Me.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Me.instance, undefined)
        );
    }

    logout(): PlainAMEResult {
        return Logout.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Logout.instance, undefined)
        );
    }

    queries(): ResponseOnlyAMEResult<QueriesResponse> {
        return Queries.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Queries.instance, undefined)
        );
    }

    addQuery(req: Partial<AddQueryRequest>): AMEResult<AddQueryRequest, AddQueryResponse> {
        return AddQuery.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(AddQuery.instance,
                {name: req.name, query: req.query, designerData: req.designerData} as AddQueryRequest)
        );
    }

    updateQuery(query: QueryBase): AMEResult<QueryBase, Query> {
        return UpdateQuery.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(UpdateQuery.instance, query)
        );
    }

    deleteQuery(id: number): AMEResult<DeleteQueryRequest, boolean> {
        return DeleteQuery.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(DeleteQuery.instance, {id})
        );
    }

    dashboards(): ResponseOnlyAMEResult<DashboardsResponse> {
        return Dashboards.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Dashboards.instance, undefined)
        );
    }

    addDashboard(data: String): AMEResult<AddDashboardRequest, AddDashboardResponse> {
        return AddDashboard.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(AddDashboard.instance, {data} as AddDashboardRequest)
        );
    }

    updateDashboard(dashboard: Dashboard): AMEResult<Dashboard, Dashboard> {
        return UpdateDashboard.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(UpdateDashboard.instance, dashboard)
        );
    }

    deleteDashboard(id: number): AMEResult<DeleteDashboardRequest, boolean> {
        return DeleteDashboard.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(DeleteDashboard.instance, {id})
        );
    }
}
