import {ConstantNodeInfo} from "./types";
import React, {useEffect, useMemo, useState} from "react";
import {Handle, Position} from "reactflow";
import {constantTypes, leftHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import classNames from "classnames";
import If from "../../If";
import useTranslation from "../../../hooks/useTranslation";
import NodeToolBox, {ChangeProps} from "./NodeToolBox";
import {useQueryEditorContext} from "../context/QueryEditorContext";

export function ConstantNode(node: Node<ConstantNodeInfo>) {
    const {data: {object: {type}, value}, selected, id} = node;
    const [t] = useTranslation();
    const dispatcher = useQueryEditorContext();

    const changeProps: ChangeProps = useMemo(() => ({
        keys: constantTypes,
        defaultKey: type,
        translate(key, func) {
            return func(`mapping.expression.constant.${key}.name`, key);
        },
        onSelect(id, key, dispatcher, title) {
            dispatcher.updateNode<ConstantNodeInfo>(id, node => {
                if (node.data.object.type !== key) {
                    node.data.object.type = key as never;
                    node.data.value = undefined as never;
                    node.data.title = title;
                    setRef2(null);
                    setRef(null);
                    return true;
                }
                return false;
            })
        }
    } as ChangeProps), [type]);

    const [ref, setRef] = useState<HTMLInputElement | null>(null);
    const [ref2, setRef2] = useState<HTMLInputElement | null>(null);

    if (type === "NULL") {
        dispatcher.setConstantValue(id, "NULL");
    }

    useEffect(() => {
        if (!ref) {
            return;
        }

        switch (type) {
            case "DATETIME":
                if (ref2) {
                    if (!ref.valueAsDate) {
                        ref.valueAsDate = value ? new Date(Date.parse(value)) : new Date();
                        ref2.valueAsDate = value ? new Date(Date.parse(value)) : new Date();
                    }
                }
                break;
            case "DATE":
            case "TIME":
                if (!ref.valueAsDate) {
                    ref.valueAsDate = value ? new Date(Date.parse(value)) : new Date();
                }
                break
            case "INTEGER":
            case "DECIMAL":
                if (!ref.valueAsNumber) {
                    ref.valueAsNumber = value ? Number(value) : 0;
                }
                break;
            case "BOOLEAN":
                ref.checked = value === "TRUE";
                break;
            case "STRING":
                ref.value = value ?? t("mapping.expression.constant.STRING.name", "String");
                break
        }

        const onInput = (e: {target: HTMLInputElement}) => {
            let value: string = "";

            switch (type) {
                case "STRING":
                    value = e.target.value;
                    break;
                case "BOOLEAN":
                    value = e.target.checked ? "TRUE" : "FALSE";
                    break;
                case "DATETIME":
                    value = `${(ref.valueAsDate ?? new Date()).toISOString().split('T')[0]}T${(ref2?.valueAsDate ?? new Date()).toISOString().split('T')[1]}`;
                    break;
                case "TIME":
                    value = (e.target.valueAsDate ?? new Date()).toISOString().split("T")[1];
                    break;
                case "DATE":
                    value = (e.target.valueAsDate ?? new Date()).toISOString().split("T")[0];
                    break;
                case "DECIMAL":
                    value = (e.target.valueAsNumber ?? 0).toFixed(6);
                    break;
                case "INTEGER":
                    value = String(Math.floor(e.target.valueAsNumber));
                    break;
            }

            dispatcher.setConstantValue(id, value, typeof (e as any)["preventDefault"] === "function");
        };

        onInput({target: ref});

        ref.addEventListener("input", onInput as never, true);
        if (ref2) {
            ref2.addEventListener("input", onInput as never, true);
        }

        return () => {
            ref.removeEventListener("input", onInput as never, true);;
            if (ref2) {
                ref2.removeEventListener("input", onInput as never, true);;
            }
        }
    }, [ref, ref2, dispatcher, id, t, type, value]);

    return <div className={classNames("query-item position-relative", {"query-item-active": selected})}>
        <Handle position={Position.Left} style={leftHandleStyle} type="source" id="output"/>
        <NodeToolBox nodeId={id} nodeSelected={selected ?? false} canDelete={true} changeProps={changeProps} />
        <div className="alert alert-warning m-0 p-1">
            <div className="alert-heading small fw-bold">{t(`mapping.expression.constant.${type}.name`, type)}</div>
            <div className="d-flex align-items-center justify-content-between gap-2 flex-column">
                <If condition={type === "STRING"}>
                    <input className="form-control form-control-sm m-0 nodrag" ref={setRef}  />
                </If>
                <If condition={type === "INTEGER"}>
                    <input className="form-control form-control-sm m-0 nodrag" type="number" step={1} ref={setRef}  />
                </If>
                <If condition={type === "DECIMAL"}>
                    <input className="form-control form-control-sm m-0 nodrag" step={0.01} type="number" ref={setRef}  />
                </If>
                <If condition={type === "TIME"}>
                    <input className="form-control form-control-sm m-0 nodrag" type="time" ref={setRef}  />
                </If>
                <If condition={type === "DATE"}>
                    <input className="form-control form-control-sm m-0 nodrag" type="date" ref={setRef}  />
                </If>
                <If condition={type === "DATETIME"}>
                    <input className="form-control form-control-sm m-0 nodrag" type="date" ref={setRef}  />
                    <input className="form-control form-control-sm m-0 nodrag" type="time" ref={setRef2}  />
                </If>
                <If condition={type === "BOOLEAN"}>
                    <label className="form-label d-flex align-items-center gap-1 p-0 m-0 nodrag">
                        <span className="small">{t("common.labels.IS_TRUE", "Is true?")}</span>
                        <input className="form-check-input m-0 " type="checkbox" ref={setRef} value="" />
                    </label>
                </If>
            </div>
        </div>
    </div>;
}

