import {Dropdown, DropdownProps} from "react-bootstrap";
import useLanguage from "../hooks/useLanguage";
import {SelectCallback} from "@restart/ui/types";
import languages from "../languages";

export default function LanguagePicker({onSelect, color, toggleClassName, ...props}:
    Omit<DropdownProps, "children"> & {toggleClassName?: string}) {
    const [language, setLanguage] = useLanguage();

    const handleSelect: SelectCallback = (eventKey, e) => {
        if (eventKey) {
            setLanguage(eventKey);
        }

        if (typeof onSelect === "function") {
            onSelect(eventKey, e);
        }
    }

    return <Dropdown onSelect={handleSelect} {...props}>
        <Dropdown.Toggle variant={color} className={toggleClassName}>
            <i className="bi bi-translate me-1"></i>
            <span>{languages.find(v => v.name === language)?.displayName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {
                languages.map(languageInfo => (
                    <Dropdown.Item key={languageInfo.name}
                                   active={languageInfo.name === language}
                                   eventKey={languageInfo.name}>
                        {languageInfo.displayName}
                    </Dropdown.Item>
                ))
            }
        </Dropdown.Menu>
    </Dropdown>
}
