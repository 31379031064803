import React, {useCallback} from "react";
import {StateButtonState} from "./types";

export interface StateButtonItemProps {
    state: StateButtonState
    onSelect: (key: string, name: string) => void
    active: boolean
    name: string
    id: string
}

export function StateButtonItem({state, onSelect, name, id, active}: StateButtonItemProps) {
    const onInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            onSelect(state.key, name);
        }
    }, [onSelect, name, state.key]);

    return <>
        <input className="btn-check" type="radio" name={name} id={id} autoComplete="off" checked={active}
               onChange={onInput}/>
        <label className="btn btn-outline-secondary" htmlFor={id}>
            <i className={`bi bi-${state.icon}`}></i>
        </label>
    </>
}
