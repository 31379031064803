import EzBiApiMethod from "../EzBiApiMethod";
import {UsageInfoArray} from "./types";
import {DateFilter} from "../types";

export interface UsagesRequest extends DateFilter {
    location_ids?: number[]
    cpo_ids?: number[]
}

export class Usages extends EzBiApiMethod<UsagesRequest, UsageInfoArray> {
    static readonly instance: Usages = new Usages();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "stats/usages?from={!from}&to={!to}{!location_ids}{!cpo_ids}";
    }

    get responseModifier() {
        return undefined;
    }
}

export default Usages;
