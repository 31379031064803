import {createContext, useCallback, useContext, useEffect} from "react";
import {Query} from "../../../api/session/types";
import QueryDispatcher from "../QueryDispatcher";
import useForceUpdate from "../../../hooks/useForceUpdate";
import {Node} from "@reactflow/core/dist/esm/types";
import {NodeInfo} from "../flow/types";
import {useDispatcherMapped} from "../../../hooks/useDispatcher";
import {Edge} from "reactflow";

export const QueryEditorContext = createContext<QueryDispatcher>(null as never);

export const useQueryEditorContext = () => useContext(QueryEditorContext);

export const EMPTY_QUERY = {} as Query;

export function useQueryDispatcher(): [Query, QueryDispatcher] {
    const ctx = useQueryEditorContext();
    if (!ctx) {
        throw new Error("QueryEditorContext.Provider not configured");
    }
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        return ctx.subscribe(forceUpdate);
    }, [forceUpdate, ctx]);

    return [ctx.value ?? EMPTY_QUERY, ctx];
}

export function useFlowNode(id: string): [Node<NodeInfo>, QueryDispatcher] {
    return useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => JSON.parse(JSON.stringify(d.getNode(id))), [id])
    );
}

export function useFlowObject(id: string): [object, QueryDispatcher] {
    return useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => d.getNode(id)?.data?.object, [id])
    );
}

export function useFlowIncomers(id: string): [Node<NodeInfo>[], QueryDispatcher] {
    return useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => d.getIncomers(id), [id])
    );
}

export function useConnectedEdges(id: string): [Edge[], QueryDispatcher] {
    return useDispatcherMapped(
        useQueryEditorContext(),
        useCallback((d: QueryDispatcher) => d.getConnectedEdges(id), [id])
    );
}

export default QueryEditorContext;
