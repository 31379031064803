import React, {useCallback, useState} from "react";
import {ChartFilterProps} from "./Chart";
import useDispatcher from "../hooks/useDispatcher";
import LocationDispatcher from "../dispatcher/LocationDispatcher";
import {LocationRecord, LocationRecordArray} from "../api/info/types";
import useSet from "../hooks/useSet";
import LanguageDispatcher, {DEFAULT_LANGUAGE} from "../dispatcher/LanguageDispatcher";
import {Dropdown, InputGroup} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import If from "./If";
import equals from "../utils/equals";

interface GroupedLocationItem {
    options: LocationRecordArray
    group: string
}

export default function ChartLocationPicker({onChange}: ChartFilterProps) {
    const [locations] = useDispatcher(LocationDispatcher.instance);
    const selectedIds = useSet<number>([], set => {
        onChange?.({location_ids: [...set]}, (key, a, b) => key === "location_ids" && equals(a, b))
    });

    const [query, setQuery] = useState("");
    const [t] = useTranslation();

    const locale = LanguageDispatcher.instance.value ?? DEFAULT_LANGUAGE;

    const groupedOptions = locations?.reduce((prev, cur) => {
        if (selectedIds.has(cur.id)) {
            return prev;
        }

        if (query
            && !cur.name.toLocaleLowerCase(locale).includes(query)
            && !cur.city.toLocaleLowerCase(locale).includes(query)
            && !cur.district.toLocaleLowerCase(locale).includes(query)) {
            return prev;
        }

        const group = cur.city.trim();

        if (group in prev) {
            (prev as any)[group].options.push(cur);
        } else {
            const item = {
                group: group,
                options: [cur]
            } as GroupedLocationItem;

            (prev as any)[group] = item;
            prev.push(item);
        }
        return prev;
    }, [] as Array<GroupedLocationItem>);

    const handleLocationClick = useCallback((e: React.MouseEvent<HTMLOptionElement>) => {
        e.preventDefault();
        e.stopPropagation();
        selectedIds.add(Number(e.currentTarget.value));
    }, [selectedIds]);

    const handleGroupClick = useCallback((e: React.MouseEvent<HTMLOptGroupElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const group = (groupedOptions as never)[e.currentTarget.label] as GroupedLocationItem;
        if (!group || !group.options.length) {
            return
        }
        selectedIds.addRange(...group.options.map(v => v.id));
    }, [selectedIds, groupedOptions]);

    const handleSearchChange = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value.toLocaleLowerCase(LanguageDispatcher.instance.value ?? DEFAULT_LANGUAGE));
    }, []);

    const handleItemRemove = useCallback((e: React.MouseEvent<HTMLElement>) => {
        selectedIds.delete(Number(e.currentTarget.dataset.id));
        e.preventDefault();
        e.stopPropagation();
    }, [selectedIds]);

    function getSelectedItems(): (GroupedLocationItem | LocationRecord)[] {
        const groups: Record<string, LocationRecord[]> = {};

        selectedIds.forEach(id => {
            const location = locations?.find(v => v.id === id);
            if (!location) {
                return;
            }
            if (location.city in groups) {
                groups[location.city].push(location);
            } else {
                groups[location.city] = [location];
            }
        });

        return Object.entries(groups).map(([group, options]) => {
            if (options.length === 1) {
                return options[0];
            }
            return {
                group,
                options
            };
        })
    }

    return <form noValidate className="vstack gap-2" onSubmit={e => e.preventDefault()}>
        <fieldset>
            <legend className="small">{t("chart.labels.location", "Locations")}</legend>
            <div className="d-flex gap-3 align-items-stretch">
                <InputGroup className="input-group-vertical search w-50">
                    <input type="text" className="form-control flex-grow-0"
                           placeholder={t("charts.labels.search", "Search...")}
                           value={query}
                           onInput={handleSearchChange} />
                    <select className="form-select flex-grow-1 flex-fill" size={7}>
                        {groupedOptions?.sort((a, b) => a.group.localeCompare(b.group))
                            .map(group => <optgroup key={group.group} label={group.group} onClick={handleGroupClick}>
                                {
                                    group.options.map(loc => <option onClick={handleLocationClick}
                                                                     value={loc.id}
                                                                     title={`${loc.name} (${loc.district})`}
                                                                     key={loc.id}>
                                        {loc.name} ({loc.district})
                                    </option>)
                                }
                            </optgroup>)}
                    </select>
                </InputGroup>
                <div className="w-50 d-flex flex-column align-items-stretch input-group-vertical">
                    <If condition={selectedIds.isEmpty}>
                        <div className="mb-0 small alert alert-secondary h-100 d-flex align-items-center justify-content-center">
                            {t("charts.labels.pickLocationsFromLocationList", "Please pick a location from location list.")}
                        </div>
                    </If>
                    <If condition={selectedIds.hasItems}>
                        <div className="alert alert-secondary bg-transparent h-100 m-0 d-flex flex-wrap flex-grow-0 align-items-center justify-content-center">
                            {
                                getSelectedItems().map(item => {
                                    const group = item as GroupedLocationItem;
                                    if (group.options) {
                                        return <Dropdown key={group.group}>
                                            <Dropdown.Toggle variant="link" size="sm">
                                                <span>{item.group as never}</span>
                                                <span className="badge rounded-pill bg-primary text-bg-primary ms-1">{group.options.length}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    group.options.map(option =>
                                                        <Dropdown.Item key={`${group.group}-${option.id}`}
                                                                       onClick={handleItemRemove} data-id={option.id}>
                                                            <span className="small">
                                                                {option.name} ({option.district})
                                                            </span>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                <Dropdown.Divider />
                                                <Dropdown.Item className="d-flex gap-2 align-items-center"
                                                               onClick={e => {
                                                                   selectedIds.removeRange(...group.options.map(v => v.id));
                                                                   e.preventDefault();
                                                                   e.stopPropagation();
                                                               }}>
                                                    <i className="bi bi-trash"></i>
                                                    <span className="small">{t("charts.labels.clearAll", "Clear All")}</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }

                                    const location = item as LocationRecord;
                                    return <button className="btn btn-link btn-sm" key={location.id}
                                                   data-id={location.id} onClick={handleItemRemove}>
                                        {location.name} ({location.district})
                                    </button>
                                })
                            }
                        </div>
                        <button className="btn btn-sm btn-primary d-flex gap-2 justify-content-center align-items-center" onClick={() => selectedIds.clear()}>
                            <i className="bi bi-trash"></i>
                            <span className="small">{t("charts.labels.clearAll", "Clear All")}</span>
                        </button>
                    </If>
                </div>
            </div>
        </fieldset>
    </form>
}
