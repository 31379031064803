import React, {useEffect, useMemo} from "react";
import QueryEditorContext from "../component/query-editor/context/QueryEditorContext";
import QueryDispatcher from "../component/query-editor/QueryDispatcher";
import QueryExecutionDispatcher from "../context/QueryExecutionDispatcher";
import {QueryManager} from "../component/query-editor/QueryManager";
import QueryExecutionContext from "../context/QueryExecutionContext";
import QueryCompilationContext, {
    QueryCompilationDispatcher
} from "../component/query-editor/context/QueryCompilationContext";
import disposer from "../utils/disposer";
import "./query-editor.scss";
import {QueryUpdaterContext, QueryUpdaterDispatcher} from "../component/query-editor/context/QueryUpdaterContext";
import QueryListDispatcher from "../dispatcher/QueryListDispatcher";

export default function QueryEditor() {
    const dispatcher = useMemo(() => new QueryDispatcher(), []);
    const execution = useMemo(() => new QueryExecutionDispatcher(), []);
    const compilation = useMemo(() => new QueryCompilationDispatcher(), []);
    const updater = useMemo(() => new QueryUpdaterDispatcher(), []);

    useEffect(() => {
        return disposer(
            dispatcher.subscribeMapped(
                d => d.map(v => v.id),
                () => {
                    updater.init(dispatcher.value as never)
                }
            ),

            compilation.subscribe(value => {
                if (value?.result?.query === undefined) {
                    return;
                }
                dispatcher.setQuery(value.result.query);
            }),

            dispatcher.subscribeMapped(
                d => d.value?.query,
                value => {
                    if (!value) {
                        return;
                    }
                    execution.exec(value);
                }
            ),

            dispatcher.subscribeMapped(
                d => d.map(v => [v.query, v.designerData]),
                () => {
                    const query = dispatcher.value;
                    if (!query) {
                        return;
                    }
                    updater.exec(query);
                    QueryListDispatcher.instance.replaceQuery(query);
                }
            )
        );
    }, [dispatcher, execution, compilation, updater]);

    return <QueryEditorContext.Provider value={dispatcher}>
        <QueryExecutionContext.Provider value={execution}>
            <QueryCompilationContext.Provider value={compilation}>
                <QueryUpdaterContext.Provider value={updater}>
                    <QueryManager />
                </QueryUpdaterContext.Provider>
            </QueryCompilationContext.Provider>
        </QueryExecutionContext.Provider>
    </QueryEditorContext.Provider>;
}
