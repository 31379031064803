import {PresentationType} from "./types";
import React from "react";
import Counter from "../counter/Counter";
import {EzBIAreaChart} from "../charts/EzBIAreaChart";

const presentationTypeControls: Record<string, React.FunctionComponent> = {
    [PresentationType.COUNTER]: Counter,
    [PresentationType.AREA_CHART]: EzBIAreaChart
};

export default presentationTypeControls;
