import React from "react";
import {StateButtonItem} from "./StateButtonItem";
import {StateButtonState} from "./types";

export interface StateButtonProps {
    states: StateButtonState[]
    defaultValue: string
    onSelect: (key: string, name: string) => void
    name: string
}

export function StateButton({states, defaultValue, onSelect, name}: StateButtonProps) {
    return <div className="btn-group btn-group-sm">
        {
            states.map((state, idx) =>
                <StateButtonItem state={state} onSelect={onSelect} name={name} id={`${name}_${idx}`}
                                 active={state.key === defaultValue} key={state.key}/>)
        }
    </div>
}
