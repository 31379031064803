export type KeyboardListenerCallback = (key: string) => boolean;

export default class KeyboardListener {
    constructor(private node?: HTMLElement, private callback?: KeyboardListenerCallback) {
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    private onKeyDown(e: KeyboardEvent): void {
        if (!this.callback || !this.node?.matches(":focus-within")) {
            return;
        }

        if (this.callback(e.code)) {
            this.init();
        }
    }

    public init(node?: HTMLElement, callback?: KeyboardListenerCallback) {
        if (this.node) {
            document.removeEventListener("keydown", this.onKeyDown, true);
        }

        this.node = node;

        if (this.node) {
            document.addEventListener("keydown", this.onKeyDown, true);
        }

        this.callback = callback;
    }
}
