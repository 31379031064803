import type {IApiExecutionContextFactory, ResponseOnlyAMEResult} from "ez-api-client/dist/api/types";
import Locations from "./Locations";
import {CPOInfoArray, LocationRecordArray} from "./types";
import CPOs from "./CPOs";

export default class InfoApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.getLocations = this.getLocations.bind(this);
        this.getCPOs = this.getCPOs.bind(this);
    }

    getLocations(): ResponseOnlyAMEResult<LocationRecordArray> {
        return Locations.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Locations.instance, undefined)
        );
    }

    getCPOs(): ResponseOnlyAMEResult<CPOInfoArray> {
        return CPOs.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(CPOs.instance, undefined)
        );
    }
}
