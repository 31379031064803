import Dispatcher from "./Dispatcher";
import TokenDispatcher from "./TokenDispatcher";

export default class LoggedInDispatcher extends Dispatcher<boolean> {
    static readonly instance: LoggedInDispatcher = new LoggedInDispatcher();
}

TokenDispatcher.instance.subscribe(response => {
    LoggedInDispatcher.instance.update((response?.access?.expires_at ?? 0) > Date.now());
});
