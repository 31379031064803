import QueryDispatcher from "../query-editor/QueryDispatcher";
import {ChartStackArray, ChartStackType} from "./types";
import {CHART_COMPONENTS_PROP_NAME} from "./constants";
import {Area, CartesianGrid, Legend} from "recharts";

const StackTypeOrder = {
    [ChartStackType.CartesianGrid]: 0,
    [ChartStackType.Legend]: 1,
    [ChartStackType.Area]: 2,
};

export default function createChartComponents(queryDispatcher: QueryDispatcher) {
    const stackArray: ChartStackArray = queryDispatcher.getProp(CHART_COMPONENTS_PROP_NAME) ?? [];

    return stackArray.sort((a, b) => StackTypeOrder[a.$st] - StackTypeOrder[b.$st]).map(({$st, id, props}) => {
        switch ($st) {
            case ChartStackType.CartesianGrid:
                return <CartesianGrid key={id} {...props as any} />
            case ChartStackType.Area:
                return <Area key={id} dataKey="uv" {...props as any} />
            case ChartStackType.Legend:
                return <Legend key={id} {...props as any} />
            default:
                return undefined;
        }
    }).filter(Boolean);
}
