import React, {FormEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {Button, ButtonGroup, Card, DropdownButton, Stack} from "react-bootstrap";
import If from "./If";
import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import useTranslation from "../hooks/useTranslation";
import type {Formatter} from "recharts/types/component/DefaultLegendContent";
import "./charts.scss"
import {DateOnly} from "../api/types";
import {usePeriodContext} from "../context/PeriodContext";
import {DEFAULT_PERIOD} from "../types";

export interface AxisInfo {
    key: string
    name: string
    default: string
    stroke?: string
    orientation?: "start" | "end"
    label?: {
        name: string,
        default: string
        angle: number
        position: string
    }
    category?: DataCategory
    groupKeys?: string[]
}

export interface DataCategory extends Record<string, unknown>{
    id: string
    name: string
    default: string
    selected: boolean
    x: Record<string, AxisInfo>
    y: Record<string, AxisInfo>
    series?: Record<string, AxisInfo>
    component: Function
}

export interface DataItem extends Record<string, unknown> {
}

export interface AbortableDataRequest {
    abort(): void
    response: Promise<DataItem[]>
}

export type AbortableDataRequestFactory<
    TFilter extends Record<string, any> = Record<string, unknown>,
    TDataCategory extends DataCategory = DataCategory
> =(filter: TFilter, dataCategories: TDataCategory[], mergeBy: string) => AbortableDataRequest

export type FilterComponent = (props: React.PropsWithoutRef<ChartFilterProps>) => JSX.Element;

export interface ChartInfo {
    dataFactory: AbortableDataRequestFactory
    dataCategories: DataCategory[]

    filters: FilterComponent[]
    title: JSX.Element
    mergeBy: string
    hideRefreshButton?: boolean
    hideFilter?: boolean,
    info?: (filters: Record<string, unknown>, dataCategories: DataCategory[]) => Promise<string | unknown>
}

export type CompareFilter = (key: string, a: unknown, b: unknown) => boolean;

export interface ChartFilterProps {
    onChange?: (value: Record<string, unknown>, comparison: CompareFilter) => void
    className?: string
}

interface AxisMap {
    xKeys: AxisInfo[]
    yKeys: AxisInfo[]
    map: Record<string, boolean>
}

export default function Chart(
    {
        title,
        hideRefreshButton,
        hideFilter,
        filters,
        dataCategories,
        dataFactory,
        mergeBy,
        info
    }: React.PropsWithoutRef<ChartInfo>
) {
    const [t] = useTranslation();
    const [filter, setFilter] = useState((() => ({
        from: DateOnly.fromOrToday().resetTime().beginOfTheMonth(),
        to: DateOnly.fromOrToday().resetTime()
    })) as () => Record<string, unknown>);
    const [filterRefreshIndex, onFilterUpdate] = useReducer(x => x + 1, 0);
    const [data, setData] = useState(() => [] as DataItem[]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const requestRef = useRef<AbortableDataRequest | null>(null);
    const [infoTip, setInfoTip] = useState<any>(null);

    const {xKeys, yKeys} = useMemo(() => dataCategories.reduce((prev, cur) => {
        const xKeys = Object.values(cur.x);
        const yKeys = Object.values(cur.y);

        xKeys.forEach(axis => {
            const xKey = `x:${axis.key}`;
            axis.category = cur;
            if (!(xKey in prev.map)) {
                prev.map[xKey] = true;
                prev.xKeys.push(axis);
            }
        });

        yKeys.forEach(axis => {
            const yKey = `y:${axis.key}`;
            axis.category = cur;

            if (!(yKey in prev.map)) {
                prev.map[yKey] = true;
                prev.yKeys.push(axis);
            }
        })

        return prev;
    }, {xKeys: [], yKeys: [], map: {}} as AxisMap), [dataCategories]);

    const retrieveData = useCallback(() => {
        onFilterUpdate();
        requestRef.current?.abort();
        const req = requestRef.current = dataFactory(filter, dataCategories?.filter(v => v.selected), mergeBy);
        setLoading(true);
        setError("");
        req.response
            .then(data => {
                setData(data);
                if (!data.length) {
                    return Promise.reject("charts.labels.noData")
                }
                return data;
            })
            .catch(err => {
                setError(
                    typeof err === "string"
                        ? err
                        : err?.code
                            ? `error.${err.code}`
                            : err?.message ?? err?.errorMessage ?? "charts.labels.noData"
                );
            })
            .finally(() => setLoading(false));
    }, [filter, dataCategories, dataFactory, requestRef, mergeBy]);

    const onRefresh = useCallback(() => {
        retrieveData();
    }, [retrieveData]);

    const handleFilterChange = useCallback((values: Record<string, unknown>, comparison: CompareFilter) => {
        if (!values) {
            return;
        }

        let changed = false;
        Object.entries(values).forEach(([key, value]) => {
            if (!comparison(key, value, filter[key])) {
                filter[key] = value;
                changed = true;
            }
        });

        if (!changed) {
            return;
        }

        retrieveData();
    }, [filter, retrieveData]);

    const legendFormatter: Formatter = useCallback(((_, entry) => {
        const category = dataCategories.find(c => c.id === (entry.payload as any).yAxisId);
        if (!category) {
            return entry.value;
        }

        const handleInput = (e: FormEvent<HTMLInputElement>) => {
            category.selected = e.currentTarget.checked;
            onFilterUpdate();
            retrieveData();
        };

        return <label className="cursor-pointer">
            <input type="checkbox" className="form-check-input"
                   defaultChecked={category.selected}
                   onInput={handleInput} />&nbsp;
            {entry.value}
            {infoTip?.[category.id] && <span>&nbsp;({infoTip[category.id]})</span>}
        </label>
    }), [dataCategories, retrieveData, infoTip])

    const {period} = usePeriodContext();

    useEffect(() => {
        requestAnimationFrame(retrieveData);
    }, [retrieveData]);

    useEffect(() => {
        if (!info) {
            return
        }

        info(filter, dataCategories).then(setInfoTip).catch();
    }, [dataCategories, info, filterRefreshIndex, filter, filters]);

    useEffect(() => {
        if (!period || period === DEFAULT_PERIOD) {
            return;
        }

        setFilter(v => ({...v, from: period.startDate, to: period.endDate}));
    }, [period]);

    return <Card className="chart shadow">
        <Card.Header className="d-flex align-items-center bg-gradient">
            <span className="fw-bold">{title}{infoTip && <span> (<span className="text-danger">{infoTip?.chart}</span>)</span>}</span>
            <span className="ms-auto"></span>
            <ButtonGroup size="sm">
                <If condition={loading}>
                    <Button variant="link" className="text-danger text-decoration-none"
                            onClick={() => requestRef?.current?.abort()}>
                        <i className="bi bi-stop-fill"></i>
                    </Button>
                </If>
                <If condition={!hideRefreshButton}>
                    <Button variant="link" onClick={onRefresh} disabled={loading}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </Button>
                </If>
                <If condition={!hideFilter && Boolean(filters?.length)}>
                    <DropdownButton title={<i className="bi bi-filter" />} size="sm" variant="link" className="filter">
                        <Stack direction="vertical" gap={2} className="filter-body">
                            {
                                filters.reduce((prev, Filter, i) => {
                                    prev.push(<Filter key={prev.length} onChange={handleFilterChange}/>);
                                    if (i + 1 < filters.length) {
                                        prev.push(<div key={prev.length} className="dropdown-divider"></div>);
                                    }
                                    return prev;
                                }, [] as JSX.Element[])
                            }
                        </Stack>
                    </DropdownButton>
                </If>
            </ButtonGroup>
        </Card.Header>
        <Card.Body className="chart-container position-relative">
            <ResponsiveContainer>
                <ComposedChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    {
                        xKeys.map(({key, name, default: def, orientation, label}) =>
                            <XAxis key={key}
                                   dataKey={key}
                                   name={t(name, def)}
                                   orientation={(orientation ?? "end") === "start" ? "top" : "bottom"}
                                   label={label && {
                                       ...label,
                                       value: t(label.name, label.default)
                                   }}
                            />)
                    }
                    {
                        yKeys.map(({key, orientation, label}) =>
                            <YAxis key={key}
                                   dataKey={key}
                                   orientation={(orientation ?? "start") === "start" ? "left" : "right"}
                                   yAxisId={key}
                                   label={label && {
                                       ...label,
                                       value: t(label.name, label.default)
                                   }}
                            />)
                    }
                    <Tooltip />
                    <Legend formatter={legendFormatter} />
                    {
                        yKeys.map(({key, name, default: def, stroke, category}) => {
                            const Component = category?.component ?? Line;
                            return <Component key={key}
                                              type="monotone"
                                              stroke={stroke}
                                              fill={stroke}
                                              dataKey={key}
                                              activeDot={{r: 8}}
                                              name={t(name, def)}
                                              yAxisId={key}
                            />
                        })
                    }
                </ComposedChart>
            </ResponsiveContainer>
            <If condition={loading}>
                <div className="position-absolute start-0 top-0 w-100 h-100 bg-opacity-50 bg-dark d-flex align-items-center justify-content-center">
                    <span className="spinner-border"></span>
                </div>
            </If>
        </Card.Body>
        <If condition={!!error}>
            <Card.Footer className="p-0">
                <span className="d-block alert alert-danger alert-dismissible m-0 rounded-top-0">
                    <i className="bi bi-exclamation-triangle me-1"></i>
                    <span>
                        {t(error)}
                    </span>
                </span>
            </Card.Footer>
        </If>
    </Card>
}
