import EzBiApiMethod from "../EzBiApiMethod";
import {DateFilter, DateGroupListResponse} from "../types";

export class Users extends EzBiApiMethod<DateFilter, DateGroupListResponse> {
    static readonly instance: Users = new Users();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "users?from={!from}&to={!to}";
    }

    get responseModifier() {
        return undefined;
    }
}

export default Users;
