import React from "react";
import {DateOnly} from "./api/types";

export interface LanguageInfo {
    name: string
    displayName: string
}

export interface InputComponentProps<T = any> extends Record<string, any>{
    id?: string
    value?: T
    onInput?: (value?: T, id?: string) => void
    placeholder?: string
    disabled?: boolean
}

export type InputComponent = React.FunctionComponent<InputComponentProps>

export interface Period {
    startDate: DateOnly
    endDate: DateOnly
}

export const DEFAULT_PERIOD: Readonly<Period> = Object.freeze({
    startDate: DateOnly.fromDiff(-90),
    endDate: DateOnly.fromDiff(0)
});