import {useParams} from "react-router";
import {useDispatcherMapped} from "../hooks/useDispatcher";
import DashboardListDispatcher from "../dispatcher/DashboardListDispatcher";
import React, {useCallback, useEffect} from "react";
import DesignerModeDispatcher from "../dispatcher/DesignerModeDispatcher";
import disposer from "../utils/disposer";
import {CustomDashboardContent} from "../component/custom-dashboard/CustomDashboardContent";
import {DashboardDesignerDispatcher} from "../component/custom-dashboard/DashboardDesignerDispatcher";
import "./custom-dashboard.scss";

export default function CustomDashboard() {
    const {id} = useParams();
    const [designerMode] = useDispatcherMapped(
        DesignerModeDispatcher.instance,
        useCallback((d: DesignerModeDispatcher) => d.active, [])
    );

    useDispatcherMapped(
        DashboardListDispatcher.instance,
        useCallback((d: DashboardListDispatcher) => d.isLoading, [])
    );

    useEffect(() => {
        if (!designerMode) {
            return;
        }

        let timer = 0;

        return disposer(
            DashboardDesignerDispatcher.instance.subscribe(() => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    DashboardListDispatcher.instance.doUpdate({
                        id: Number(id),
                        data: DashboardDesignerDispatcher.instance.toJson()
                    })
                }, 1000) as never;
            }),
            () => clearTimeout(timer)
        );
    }, [id, designerMode])

    const dashboard = DashboardListDispatcher.instance.getItemById(Number(id));

    if (!dashboard) {
        return <div className="d-flex w-100 h-100 align-items-center justify-content-center">
            <span className="spinner-border"></span>
        </div>
    }

    return <CustomDashboardContent designerMode={designerMode} data={dashboard.data || "{}"} />
}
