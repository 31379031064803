import {ChartFilterProps} from "./Chart";
import Translated from "./Translated";
import {FormEvent, MouseEvent, useCallback, useEffect, useRef} from "react";
import {DateOnly} from "../api/types";
import {TranslateOptions} from "../hooks/useTranslation";
import classNames from "classnames";
import addDays from "../utils/addDays";
import {usePeriodContext} from "../context/PeriodContext";

export interface DateRange extends TranslateOptions {
    name: string
    default: string
    from: () => string
    to: () => string
}

function getToday(): string {
    return new Date().toISOString().split("T")[0];
}

function firstDayOfWeek() {

    const date = new Date();
    const dayOfWeek = date.getDay(),
        diff = dayOfWeek >= 1 ? dayOfWeek - 1 : 6 - dayOfWeek

    date.setDate(date.getDate() - diff)

    return date.toISOString().split("T")[0];
}

function firstDayOfMonth() {
    return `${getToday().slice(0, 8)}01`;
}

function lastDayOfLastMonth() {
    const date = new Date();
    date.setDate(0);
    return date.toISOString().split("T")[0];
}

function firstDayOfLastMonth() {
    return`${lastDayOfLastMonth().slice(0, 8)}01`;
}

function lastDays(count: number) {
    const date = new Date();
    date.setDate(date.getDate() - count);
    const result = date.toISOString().split("T")[0];
    return () => result;
}

const dateRanges: DateRange[] = [
    {
        name: "charts.labels.thisWeek",
        default: "This week",
        from: firstDayOfWeek,
        to: getToday
    },
    {
        name: "charts.labels.thisMonth",
        default: "This month",
        from: firstDayOfMonth,
        to: getToday
    },
    {
        name: "charts.labels.lastMonth",
        default: "Last month",
        from: firstDayOfLastMonth,
        to: lastDayOfLastMonth
    },
    {
        name: "charts.labels.lastXDays",
        args: {count: 3},
        default: "Last 3 days",
        from: lastDays(3),
        to: getToday
    },
    {
        name: "charts.labels.lastXDays",
        args: {count: 7},
        default: "Last 7 days",
        from: lastDays(7),
        to: getToday
    },
    {
        name: "charts.labels.lastXDays",
        args: {count: 15},
        default: "Last 15 days",
        from: lastDays(15),
        to: getToday
    },
    {
        name: "charts.labels.lastXDays",
        args: {count: 30},
        default: "Last 30 days",
        from: lastDays(30),
        to: getToday
    },
    {
        name: "charts.labels.lastXDays",
        args: {count: 90},
        default: "Last 90 days",
        from: lastDays(90),
        to: getToday
    },
];

const dateComparison = (key: string, a?: Date, b?: Date) => {
    return (key === "from" || key === "to") && a?.getTime?.() === b?.getTime?.()
}

export interface ChartDateRangeProps extends ChartFilterProps {
    startDate?: number
    endDate?: number
}

export default function ChartDateRange({onChange, className, startDate, endDate}: ChartDateRangeProps) {
    const startRef = useRef<HTMLInputElement|null>(null);
    const endRef = useRef<HTMLInputElement|null>(null);
    const formRef = useRef<HTMLFormElement|null>(null);

    const setRef = useCallback((node: HTMLInputElement | HTMLFormElement) => {
        if (!node) {
            return;
        }
        if (node.name === "from") {
            startRef.current = node as HTMLInputElement;
        } else if (node.name === "to") {
            endRef.current = node as HTMLInputElement;
        } else if (node.name === "form") {
            formRef.current = node as HTMLFormElement
        }
    }, [startRef, endRef]);

    const dispatchChange = (from?: Date | null, to?: Date | null) => {
        if (typeof onChange !== "function") {
            return;
        }

        const record: Record<string, unknown> = {};
        if (from) {
            record.from = DateOnly.from(from)
        }

        if (to) {
            record.to = DateOnly.from(to);
        }

        onChange(
            record,
            dateComparison as never
        );
    };

    const handleChange = (e: FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        const eFrom = form.elements.namedItem("from") as HTMLInputElement;
        const eTo = form.elements.namedItem("to") as HTMLInputElement;
        let from = eFrom?.valueAsDate ?? undefined;
        const to = eTo?.valueAsDate ?? undefined;

        if (to) {
            eFrom.max = to.toISOString().split("T")[0];
        }

        if (from && to && from > to) {
            from = to;
            eFrom.valueAsDate = to;
        }

        dispatchChange(DateOnly.from(from), DateOnly.from(to));
    }

    const updateDate = (e: MouseEvent<HTMLButtonElement>) => {
        const range = dateRanges[e.currentTarget.dataset.range as never];
        if (!range) {
            return;
        }

        const form = e.currentTarget.form;
        if (!form) {
            return;
        }

        const eFrom = form.elements.namedItem("from") as HTMLInputElement;
        const eTo = form.elements.namedItem("to") as HTMLInputElement;
        if (eFrom) {
            eFrom.value = range.from();
        }

        if (eTo) {
            eTo.value = range.to();
        }

        dispatchChange(DateOnly.from(eFrom?.valueAsDate), DateOnly.from(eTo?.valueAsDate));
    };

    const today = new Date().toISOString().split("T")[0];
    const firstDayOfTheMonth = `${today.slice(0, 8)}01`;

    const {period} = usePeriodContext();

    useEffect(() => {
        if (!period) {
            return;
        }

        if (startRef.current) {
            startRef.current.valueAsDate = period.startDate;
        }

        if (endRef.current) {
            endRef.current.valueAsDate = period.endDate;
        }

        if (formRef.current) {
            formRef.current.dispatchEvent(new Event("change"))
        }

    }, [period]);

    return <form noValidate name="form" ref={setRef as never} onChange={handleChange} className={classNames("vstack gap-2", className)}>
        <fieldset className="align-items-center d-flex text-capitalize">
            <span className="flex-fill d-flex flex-column gap-2">
                <div className="text-muted small text-start">
                    <Translated caption="charts.labels.fromDate" default="from date" />
                </div>
                <input type="date" ref={setRef as never} className="form-control" name="from" defaultValue={startDate !== undefined ? addDays(startDate) : firstDayOfTheMonth} max={today} />
            </span>
                <span className="vr mx-2"></span>
                <span className="flex-fill d-flex flex-column gap-2">
                <div className="text-muted small text-end">
                    <Translated caption="charts.labels.toDate" default="to date" />
                </div>
                <input type="date" ref={setRef as never} className="form-control" name="to" defaultValue={endDate !== undefined ? addDays(endDate) : today} max={today} />
            </span>
        </fieldset>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
            {dateRanges.map((range, i) =>
                <button type="button" className="btn btn-link text-capitalize small btn-sm text-decoration-none"
                        key={i} data-range={i} onClick={updateDate}>
                    <Translated caption={range.name} default={range.default} args={range.args} />
                </button>
            )}
        </div>
    </form>
}
