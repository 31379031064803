import EzBiApiMethod from "../EzBiApiMethod";
import {StatsResult} from "./types";

export class GetStats extends EzBiApiMethod<undefined, StatsResult> {
    static readonly instance: GetStats = new GetStats();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "stats";
    }
}

export default GetStats;
