import type {IApiExecutionContextFactory, AMEResult} from "ez-api-client/dist/api/types";
import Contracts from "./Contracts";
import {DateFilter, DateGroupListResponse} from "../types";
import ActiveContracts from "./ActiveContracts";

export default class ContractsApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.contracts = this.contracts.bind(this);
        this.activeContracts = this.activeContracts.bind(this);
    }

    contracts(request?: DateFilter): AMEResult<DateFilter, DateGroupListResponse> {
        request ??= {};
        return Contracts.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Contracts.instance, request)
        );
    }

    activeContracts(request?: DateFilter): AMEResult<DateFilter, DateGroupListResponse> {
        request ??= {};
        return ActiveContracts.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(ActiveContracts.instance, request)
        );
    }
}
