import {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import Dispatcher from "../dispatcher/Dispatcher";
import {StatsResult} from "../api/stats/types";
import EzBiApiClient from "../api/EzBiApiClient";
import NumericStatViewer from "../component/NumericStatViewer";
import Translated, {TranslatedProps} from "../component/Translated";
import {Variant} from "react-bootstrap/types";
import {DEFAULT_PERIOD} from "../types";
import {NamedDateRange} from "./NamedDateRange";
import {usePeriodContext} from "../context/PeriodContext";

export interface DateFilterableNumericStatViewProps {
    title: TranslatedProps
    description: TranslatedProps
    icon: ReactNode
    variant: Variant
    stateKey: keyof StatsResult
    currency?: boolean
}

export function DateFilterableNumericStatView({
                                                  title,
                                                  description,
                                                  icon,
                                                  variant,
                                                  stateKey,
                                                  currency
                                              }: DateFilterableNumericStatViewProps) {

    const [loading, setLoading] = useState(false);
    const dispatcher = useMemo(() => new Dispatcher<StatsResult>({} as never), []);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const keyRef = useRef(0);

    const {period: periodContextProp} = usePeriodContext();

    useEffect(() => {
        if (!periodContextProp || periodContextProp === DEFAULT_PERIOD) {
            return;
        }
        setPeriod(periodContextProp);
    }, [periodContextProp]);

    useEffect(() => {
        setLoading(true);
        const response = EzBiApiClient.instance.stats.getStatsBy({
            type: stateKey,
            ...period
        });

        let done = false;

        response.then(v => v.succeed())
            .then(({value}) => dispatcher.update({[stateKey]: value} as never))
            .catch(() => dispatcher.update({[stateKey]: 0} as never))
            .finally(() => {
                done = true;
                setLoading(false);
            });

        return () => {
            if (done) {
                return;
            }

            response.context?.abortController?.abort();
        }
    }, [stateKey, dispatcher, period]);

    const datePickerArgs = useMemo(() => ({
        datePicker: <NamedDateRange variant={variant} onChange={setPeriod} key={`named-${++keyRef.current}`} />
    }), [variant, keyRef]);

    return <NumericStatViewer title={<Translated {...title} />} className="chart"
                              description={<Translated {...description} args={datePickerArgs}/>}
                              icon={icon}
                              variant={variant}
                              dispatcher={dispatcher}
                              statKey={stateKey} loading={loading} currency={currency}/>
}