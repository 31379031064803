import useDispatcher from "./useDispatcher";
import TranslationsDispatcher from "../dispatcher/TranslationsDispatcher";
import LanguageDispatcher from "../dispatcher/LanguageDispatcher";
import {useCallback} from "react";

export interface TranslateOptions {
    args?: Record<string, unknown>
    default?: string
}

export type TranslateFunction = (keys: string | string[], options?: string | TranslateOptions) => string;

export default function useTranslation(): [TranslateFunction, string?] {

    const [translations] = useDispatcher(TranslationsDispatcher.instance);

    const translate = useCallback((keys: string | string[], options?: string | TranslateOptions): string => {
        let defaultValue: string;

        if (!Array.isArray(keys)) {
            keys = [keys];
        }

        if (typeof options === "string") {
            options = {default: options};
        }

        if (!options) {
            options = {default: keys[0]};
        }

        defaultValue = options.default as never;

        for (const key of keys) {
            const parts = key.split(/\./g);
            const last = parts.pop();
            if (!last) {
                continue;
            }

            const host = parts.reduce((prev, subKey) => {
                if (typeof prev !== "object" || !prev || !(subKey in prev)) {
                    return null;
                }

                return prev[subKey];
            }, translations);

            let result = host?.[last];

            if (typeof result !== "string" || !result) {
                continue;
            }

            const optionsRef: TranslateOptions = options;
            const {args} = optionsRef;

            result = result.replace(/\{\{(.+?)}}/g, (_: string, group: string) => {
                if (!args || !(group in args)) {
                    return translate(group);
                }

                const value = String(args[group]);
                return translate(value, {...optionsRef, default: value});
            });

            const componentMatcher = /component::(\w+)/g;
            const componentList = [];
            let index = 0;
            let m: RegExpMatchArray | null = null;
            while ((m = componentMatcher.exec(result))) {
                const nextIndex = m.index ?? index;
                if (index < nextIndex) {
                    componentList.push(result.substring(index, nextIndex));
                }

                const component = options?.args?.[m[1]];
                if (component !== undefined) {
                    componentList.push(component);
                }
                index = nextIndex + m[0].length;
            }

            if (index < result.length) {
                componentList.push(result.substring(index));
            }

            return componentList.length === 1 ? componentList[0] : componentList;
        }

        return defaultValue;
    }, [translations]);

    return [translate, LanguageDispatcher.instance.value];
}
