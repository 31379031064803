import {CartesianGridSettings} from "./cartesian-grid/CartesianGridSettings";
import {AreaSettings} from "./area/AreaSettings";
import {LegendSettings} from "./legend/LegendSettings";

export enum ChartStackType {
    Area,
    Legend,
    CartesianGrid,
}

export interface ChartStack {
    $st: ChartStackType
    id: string
}

export interface CartesianGridChartStack extends ChartStack {
    $st: ChartStackType.CartesianGrid
    props: CartesianGridSettings
}

export interface AreaChartStack extends ChartStack {
    $st: ChartStackType.Area
    props: AreaSettings
}

export interface LegendChartStack extends ChartStack {
    $st: ChartStackType.Legend
    props: LegendSettings
}

export type ChartStackArray = (CartesianGridChartStack | AreaChartStack | LegendChartStack)[];

export enum LegendType {
    line = "line",
    plainline = "plainline",
    square = "square",
    rect = "rect",
    circle = "circle",
    cross = "cross",
    diamond = "diamond",
    star = "star",
    triangle = "triangle",
    wye = "wye",
    none = "none"
}

export enum Layout {
    horizontal = "horizontal",
    vertical = "vertical"
}

export enum Align {
    left = "left",
    center = "center",
    right = "right"
}

export enum VerticalAlign {
    top = "top",
    middle = "middle",
    bottom = "bottom"
}
