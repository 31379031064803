import EzBiApiMethod from "../EzBiApiMethod";
import {Dashboard} from "./types";

export class UpdateDashboard extends EzBiApiMethod<Dashboard, Dashboard> {
    static readonly instance: UpdateDashboard = new UpdateDashboard();
    readonly isPrivate = true;
    readonly method = "PUT";

    get path(): string {
        return "session/dashboards";
    }
}

export default UpdateDashboard;
