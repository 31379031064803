import type {IApiRequest, RequestFormatter} from "ez-api-client/dist/api/types";
import EzBiApiMethod from "../EzBiApiMethod";
import {AuthenticateResponse} from "./types";

export interface RefreshTokenRequest extends IApiRequest {
    token: string
}

export class RefreshToken extends EzBiApiMethod<RefreshTokenRequest, AuthenticateResponse> {
    static readonly instance: RefreshToken = new RefreshToken();
    readonly isPrivate = false;
    readonly method = "POST";

    get requestFormatter(): RequestFormatter {
        const old = super.requestFormatter;
        return (requestInit, req) => old.apply(this, [requestInit, req])
            .then(() => {
                (requestInit.headers as Headers).set(
                    "Authorization",
                    `Bearer ${(req as RefreshTokenRequest).token}`
                )
            });
    }

    get path(): string {
        return "auth/refresh-token";
    }
}

export default RefreshToken;
