import EzBiApiMethod from "../EzBiApiMethod";
import {DeleteQueryRequest} from "./types";

export class DeleteQuery extends EzBiApiMethod<DeleteQueryRequest, boolean> {
    static readonly instance: DeleteQuery = new DeleteQuery();
    readonly isPrivate = true;
    readonly method = "DELETE";

    get path(): string {
        return "session/queries?id={id}";
    }
}

export default DeleteQuery;
