import {useState} from "react";
import useForceUpdate from "./useForceUpdate";

export interface CustomSet<T> {
    addRange(...items: T[]): void

    removeRange(...items: T[]): void

    get isEmpty(): boolean

    get hasItems(): boolean

    toggle(value: T): void
}

class ObservableSet<T> extends Set<T> implements CustomSet<T>{
    constructor(private readonly updater: (set: ObservableSet<T>) => void, items: T[]) {
        super(items);
        this.toggle = this.toggle.bind(this);
    }

    add(value: T): this {
        if (!super.has(value)) {
            super.add(value);
            this.updater(this);
        }
        return this;
    }

    delete(value: T): boolean {
        if (super.delete(value)) {
            this.updater(this);
            return true;
        }

        return false;
    }

    clear() {
        if (!this.size) {
            return;
        }
        super.clear();
        this.updater(this);
    }

    addRange(...items: T[]): void {
        let updated = false;

        items.forEach(item => {
            if (!super.has(item)) {
                super.add(item);
                updated = true;
            }
        });

        if (updated) {
            this.updater(this);
        }
    }

    removeRange(...items: T[]) {
        let updated = false;
        items.forEach(item => {
            if (super.has(item)) {
                super.delete(item);
                updated = true;
            }
        });
        if (updated) {
            this.updater(this);
        }
    }

    get isEmpty(): boolean {
        return this.size < 1;
    }

    get hasItems(): boolean {
        return this.size > 0;
    }

    toggle(value: T): void {
        if (this.has(value)) {
            super.delete(value);
        } else {
            super.add(value);
        }
        this.updater(this);
    }
}

export type CustomSetImpl<T> = Set<T> & CustomSet<T>;

export default function useSet<T>(items?: T[], changeHandler?: (set: CustomSetImpl<T>) => void): CustomSetImpl<T> {
    const forceUpdate = useForceUpdate();
    const [set] = useState(() => new ObservableSet<T>(set => {
        changeHandler?.(set);
        forceUpdate();
    }, items ?? []));

    return set;
}
