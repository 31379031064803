import Dispatcher from "./Dispatcher";
import LoggedInUserDispatcher from "./LoggedInUserDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import equals from "../utils/equals";
import {CPQLMappingResponse} from "../api/cpql/types";

export default class CPQLMappingDispatcher extends Dispatcher<CPQLMappingResponse> {
    static readonly instance: CPQLMappingDispatcher = new CPQLMappingDispatcher({tables: [], functions: []});

    equals(next?: CPQLMappingResponse): boolean {
        return equals(this.value, next);
    }
}

function update() {
    EzBiApiClient.instance.cpql.mapping()
        .then(v => v.succeed())
        .then(mapping => {
            CPQLMappingDispatcher.instance.update(mapping);
        });
}

LoggedInUserDispatcher.instance.subscribe(v => {
    if (v) {
        update();
    }
});
