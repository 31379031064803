import EzBiApiMethod from "../EzBiApiMethod";
import {DashboardsResponse} from "./types";

export class Dashboards extends EzBiApiMethod<undefined, DashboardsResponse> {
    static readonly instance: Dashboards = new Dashboards();
    readonly isPrivate = true;
    readonly method = "GET";

    get path(): string {
        return "session/dashboards";
    }
}

export default Dashboards;
