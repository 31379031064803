import {ComparisonNodeInfo} from "./types";
import useTranslation from "../../../hooks/useTranslation";
import React, {useMemo} from "react";
import {Handle, Position} from "reactflow";
import {bottomHandleStyle, comparisonOperators, leftHandleStyle, rightHandleStyle} from "./constants";
import {Node} from "@reactflow/core/dist/esm/types";
import classNames from "classnames";
import If from "../../If";
import {CPQLComparisonOperator} from "../../../api/cpql/types";
import NodeToolBox, {ChangeProps} from "./NodeToolBox";
import {useQueryEditorContext} from "../context/QueryEditorContext";

interface ComparisonHandleProps {
    node: Node<ComparisonNodeInfo>
    type: CPQLComparisonOperator
    left: boolean
    index: number
}

function ComparisonHandle({node, type, left, index}: ComparisonHandleProps) {
    const [t] = useTranslation();
    const dispatcher = useQueryEditorContext();

    if (!node || (!left && (type === "IS_NULL" || type === "IS_NOT_NULL"))) {
        return <></>;
    }

    const edges = dispatcher.getConnectedEdges(node.id).filter(v => v.target === node.id);
    const data = node.data;
    const input = data.inputs[index];
    const edge = edges.find(v => v.targetHandle === input);
    const hasIssue = !edge;

    return <div className="d-flex flex-column gap-2 align-items-center">
        <div className={classNames("alert m-0 p-1 px-2 d-flex align-items-center small gap-2", {
            "alert-danger": hasIssue,
            "alert-success": !hasIssue
        })}>
            <span>{t(`mapping.name.${left ? "left" : "right"}`, left ? "left" : "right")}</span>
            <If condition={!!edge}>
                <button className="btn btn-sm btn-link link-body-emphasis nodrag no-line-height m-0 p-0" onClick={() => dispatcher.deleteEdgeObj(edge)}>
                    <i className="bi bi-trash-fill"></i>
                </button>
            </If>
            <Handle position={left ? Position.Left : Position.Right}
                    style={left ? leftHandleStyle : rightHandleStyle}
                    type="target" id={input} isConnectable={!edge}/>
        </div>
    </div>
}

export function ComparisonNode(node: Node<ComparisonNodeInfo>) {
    const [t] = useTranslation();
    const dispatcher = useQueryEditorContext();

    const type: CPQLComparisonOperator = (node?.data?.object?.type ?? "") as never;

    const changeProps: ChangeProps = useMemo(() => ({
        keys: comparisonOperators,
        defaultKey: type,
        translate(key, func) {
            return func(`mapping.expression.comparison.${key}.name`, key);
        },
        onSelect(id, key, dispatcher, title) {
            dispatcher.updateNode<ComparisonNodeInfo>(id, node => {
                if (node.data.object.type !== key) {
                    node.data.object.type = key as never;
                    node.data.title = title;
                    return true;
                }
                return false;
            })
        }
    } as ChangeProps), [type]);

    if (!node) {
        return <></>;
    }

    const edges = dispatcher.getConnectedEdges(node.id).filter(v => v.target === node.id);
    const data = node.data;

    return <div className={classNames("query-item", {"query-item-active": node.selected})}>
        <div className="d-flex align-items-center gap-2">
            <NodeToolBox nodeId={node.id} nodeSelected={node.selected ?? false} changeProps={changeProps}
                         canDelete={true} />
            <ComparisonHandle node={node} type={type} left={true} index={0} />
            <div className={classNames("alert m-0 d-flex align-items-center flex-column gap-2 fw-bolder small", {
                "alert-danger": data.min > edges.length,
                "alert-success": data.min <= edges.length
            })}>
                <span>{t(`mapping.expression.comparison.${type}.name`, type)}</span>
            </div>
            <div className="d-flex flex-column gap-2">
                {
                    data.inputs.slice(1).map((v, index) =>
                        (<ComparisonHandle key={index} node={node} type={type} left={false} index={index + 1} />))
                }
            </div>
            <Handle position={Position.Bottom} style={bottomHandleStyle} type="source" id="output"/>
        </div>
    </div>;
}
