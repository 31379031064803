import React, {useCallback, useEffect, useState} from "react";
import CartesianGridSettingsControl from "./cartesian-grid/CartesianGridSettingsControl";
import {InputComponentProps} from "../../types";
import {Dropdown} from "react-bootstrap";
import useTranslation from "../../hooks/useTranslation";
import If from "../If";
import {Template} from "../query-editor/flow/Template";
import {ChartStackArray, ChartStackType} from "./types";
import AreaSettingsControl from "./area/AreaSettingsControl";
import LegendSettingsControl from "./legend/LegendSettingsControl";

export interface ChartComponentsSettingsProps extends InputComponentProps<ChartStackArray> {
    cartesianGrid?: boolean
    legend?: boolean
    area?: number
}

let ID = Date.now();

interface SettingsBoxProps extends React.PropsWithChildren {
    label: string
    id: string
    onDelete: (id: string) => void
}

function SettingsBox({label, children, id, onDelete}: SettingsBoxProps) {

    const onClick = useCallback(() => {
        if (!onDelete) {
            return;
        }
        onDelete(id);
    }, [id, onDelete])

    return <div className="card">
        <div className="d-flex align-items-center justify-content-between small card-header">
            <label className="fw-bolder small">{label}</label>
            <button className="btn btn-sm btn-link p-0 m-0" onClick={onClick}>
                <i className="bi bi-trash"></i>
            </button>
        </div>
        <div className="card-body">
            {children}
        </div>
    </div>
}

export default function ChartComponentsSettings({cartesianGrid, area, legend, value, id, onInput}: ChartComponentsSettingsProps) {

    const [t] = useTranslation();
    const [stackArray, setStackArray] = useState(value ?? []);

    const onSettingsChanged = useCallback((value?: unknown, id?: string) => {
        setStackArray(old => old.map(v => {
            if (v.id !== id) {
                return v;
            }
            if (typeof value === "object") {
                return {...v, props: {...v.props, ...value}} as never;
            }
            return {...v, props: value} as never;
        }))
    }, []);

    const onAddComponent = useCallback((key: string | null) => {
        if (key === null) {
            return;
        }

        setStackArray(old => [...old, {$st: ChartStackType[key as never], id: String(++ID)} as never])
    }, []);

    const onDeleteComponent = useCallback((id: string) => {
        setStackArray(old => (old.filter(v => v.id !== id)));
    }, []);

    useEffect(() => {
        setStackArray(value ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (!onInput) {
            return;
        }

        const current = [...stackArray];

        const timer = requestAnimationFrame(() => {
            onInput(current, id);
        });

        return () => cancelAnimationFrame(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, onInput, stackArray])

    const canAddCartesianGrid = (cartesianGrid ?? false)
        && stackArray.every(v => v.$st !== ChartStackType.CartesianGrid);

    const canAddArea = (typeof area === "number")
        && stackArray.filter(v => v.$st === ChartStackType.Area).length < area;

    const canAddLegend = (legend ?? false)
        && stackArray.every(v => v.$st !== ChartStackType.Legend);

    return <div className="d-flex flex-column gap-3">
        <Dropdown onSelect={onAddComponent}>
            <Dropdown.Toggle size="sm" className="d-flex gap-2 align-items-center w-100 justify-content-between">
                <span className="d-flex gap-2">
                    <i className="bi bi-plus-circle"></i>
                    {t("charts.labels.addComponent", "Add Component")}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow position-fixed">
                <If condition={canAddCartesianGrid}>
                    <Dropdown.Item eventKey={ChartStackType[ChartStackType.CartesianGrid]} className="small">
                        {t("charts.labels.CARTESIAN_GRID", "Cartesian Grid")}
                    </Dropdown.Item>
                </If>
                <If condition={canAddArea}>
                    <Dropdown.Item eventKey={ChartStackType[ChartStackType.Area]} className="small">
                        {t("charts.labels.AREA", "Area")}
                    </Dropdown.Item>
                </If>
                <If condition={canAddLegend}>
                    <Dropdown.Item eventKey={ChartStackType[ChartStackType.Legend]} className="small">
                        {t("charts.labels.LEGEND", "Legend")}
                    </Dropdown.Item>
                </If>
            </Dropdown.Menu>
        </Dropdown>
        {
            stackArray.sort((a, b) => a.$st - b.$st).map(stack => {
                const id = stack.id;

                switch (stack.$st) {
                    case ChartStackType.CartesianGrid:
                        return <SettingsBox key={id} label={t("charts.labels.CARTESIAN_GRID", "Cartesian Grid")}
                                            id={id}
                                            onDelete={onDeleteComponent}>
                            <CartesianGridSettingsControl id={id} onInput={onSettingsChanged}
                                                          value={stack.props} />
                        </SettingsBox>;
                    case ChartStackType.Area:
                        return <SettingsBox key={id} label={t("charts.labels.AREA", "Area")}
                                            id={id}
                                            onDelete={onDeleteComponent}>
                            <AreaSettingsControl id={id} onInput={onSettingsChanged}
                                                   value={stack.props} />
                        </SettingsBox>;
                    case ChartStackType.Legend:
                        return <SettingsBox key={id} label={t("charts.labels.LEGEND", "Legend")}
                                            id={id}
                                            onDelete={onDeleteComponent}>
                            <LegendSettingsControl id={id} onInput={onSettingsChanged}
                                                   value={stack.props} />
                        </SettingsBox>;
                }
                return <Template key={`temp_${id}`} />;
            })
        }
    </div>
}
