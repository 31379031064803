import React, {useCallback, useEffect, useRef, useState} from "react";
import {ReactFlowInstance} from "@reactflow/core/dist/esm/types/instance";
import {Background, ConnectionLineType, Controls, Edge, EdgeChange, MiniMap, NodeChange, ReactFlow} from "reactflow";
import {NodeInfo, TableNodeInfo} from "./types";
import {Node} from "@reactflow/core/dist/esm/types";
import {edgeTypes, nodeTypes} from "./constants";
import {Viewport} from "@reactflow/core/dist/esm/types/general";
import {useQueryEditorContext} from "../context/QueryEditorContext";
import disposer from "../../../utils/disposer";

export function QueryDesignerCanvas() {
    const reactFlowWrapper = useRef<HTMLElement>(null as never);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>(null as never);
    const queryDispatcher = useQueryEditorContext();
    const [nodes, setNodes] = useState<Node<NodeInfo>[]>(() => []);
    const [edges, setEdges] = useState<Edge[]>(() => []);

    const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";

    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLElement>) => {
            event.preventDefault();
            if (!reactFlowWrapper.current) {
                return;
            }

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            let dragDropData: NodeInfo;
            try {
                dragDropData = JSON.parse(event.dataTransfer.getData('application/cpql'));
                if (!dragDropData?.type) {
                    return;
                }
            } catch (e) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            queryDispatcher.addNode({
                type: dragDropData.type,
                position,
                data: dragDropData
            } as Node<TableNodeInfo>);

        },
        [reactFlowInstance, queryDispatcher]
    );

    const onMove = useCallback((event: MouseEvent | TouchEvent, viewport: Viewport) => {
        queryDispatcher.setViewport(viewport);
    }, [queryDispatcher]);

    useEffect(() => {
        if (!reactFlowInstance) {
            return;
        }

        return disposer(
            queryDispatcher.subscribeMapped(
                d => d.map(v => v.id),
                () => {
                    reactFlowInstance.setViewport(queryDispatcher.getViewport(), {duration: 500})
                },
                true
            ),
            queryDispatcher.subscribeMapped(
                d => d.getNodes(),
                setNodes as never,
                true
            ),
            queryDispatcher.subscribeMapped(
                d => d.getEdges(),
                setEdges as never,
                true
            )
        );
    }, [reactFlowInstance, queryDispatcher]);

    const onNodesChanged = useCallback((changes: NodeChange[]) => {
        queryDispatcher.applyChanges(changes, []);
    }, [queryDispatcher]);

    const onEdgesChanged = useCallback((changes: EdgeChange[]) => {
        queryDispatcher.applyChanges([], changes);
    }, [queryDispatcher]);

    return <div className="canvas" ref={reactFlowWrapper as never}>
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChanged}
            onEdgesChange={onEdgesChanged}
            onConnect={queryDispatcher.addEdge}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onMove={onMove}
            nodeTypes={nodeTypes as never}
            edgeTypes={edgeTypes as never}
            connectionLineType={ConnectionLineType.SmoothStep}
            snapToGrid={true}
        >
            <MiniMap />
            <Background/>
            <Controls/>
        </ReactFlow>
    </div>;
}
