import LoggedInUserDispatcher from "./LoggedInUserDispatcher";
import EzBiApiClient from "../api/EzBiApiClient";
import {Dashboard, DashboardsResponse} from "../api/session/types";
import ListDispatcher from "./ListDispatcher";
import {DashboardInfo} from "../component/dashboard/types";

export default class DashboardListDispatcher extends ListDispatcher<Dashboard, any, any> {
    static readonly instance: DashboardListDispatcher = new DashboardListDispatcher({
        action: undefined,
        items: [],
        requestIndex: 0
    });

    doRefresh(done?: () => void) {
        this.run(EzBiApiClient.instance.session.dashboards(), "loading", done)
            .success((items: DashboardsResponse) => this.updateState({items}));
    }

    doAdd(data: Partial<DashboardInfo>, done?: () => void) {
        this.run(EzBiApiClient.instance.session.addDashboard(JSON.stringify(data)), "adding", done)
            .success((dashboard: Dashboard) => this.updateState({items: [dashboard, ...this.items]}));
    }

    doUpdate(dashboard: Dashboard, done?: () => void) {
        this.run(EzBiApiClient.instance.session.updateDashboard(dashboard), "updating", done)
            .success((dashboard: Dashboard) => {
                const items = this.items.slice();
                const current = items.find(v => v.id === dashboard.id);
                if (!current) {
                    return;
                }
                Object.assign(current, dashboard);
                this.updateState({items, [dashboard.id]: undefined});
            });
        this.updateState({[dashboard.id]: {updating: true}} as never);
    }

    doDelete(id: number, done?: () => void) {
        this.run(EzBiApiClient.instance.session.deleteDashboard(id), "deleting", done)
            .success(() => this.updateState({items: this.items.filter(v => v.id !== id), [id]: undefined}))
        this.updateState({[id]: {deleting: true}} as never)
    }
}

LoggedInUserDispatcher.instance.subscribe(v => {
    if (!v) {
        return;
    }

    DashboardListDispatcher.instance.doRefresh();
});
