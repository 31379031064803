import {createContext, useContext, useEffect} from "react";
import ApiDispatcher from "../../../dispatcher/ApiDispatcher";
import {CPQLCompileRequest, CPQLCompileResponse} from "../../../api/cpql/types";
import EzBiApiClient from "../../../api/EzBiApiClient";
import useForceUpdate from "../../../hooks/useForceUpdate";

export class QueryCompilationDispatcher extends ApiDispatcher<CPQLCompileRequest, CPQLCompileResponse> {
    constructor() {
        super(EzBiApiClient.instance.cpql.compile as never, {request: {}} as never);
    }
}

export const QueryCompilationContext = createContext<QueryCompilationDispatcher>(null as never);

export const useQueryCompilationContext = () => useContext(QueryCompilationContext);

export function useQueryCompilationDispatcher(): QueryCompilationDispatcher {
    const ctx = useQueryCompilationContext();
    if (!ctx) {
        throw new Error("QueryCompilationContext.Provider not configured");
    }
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        return ctx.subscribe(forceUpdate);
    }, [forceUpdate, ctx]);

    return ctx;
}

export default QueryCompilationContext;
