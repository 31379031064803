import type {IApiExecutionContextFactory, AMEResult} from "ez-api-client/dist/api/types";
import Users from "./Users";
import {DateFilter, DateGroupListResponse} from "../types";
import ActiveUsers from "./ActiveUsers";

export default class UsersApiCategory {
    constructor(private readonly apiExecutionContextFactory: IApiExecutionContextFactory) {
        this.users = this.users.bind(this);
        this.activeUsers = this.activeUsers.bind(this);
    }

    users(request?: DateFilter): AMEResult<DateFilter, DateGroupListResponse> {
        request ??= {};
        return Users.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(Users.instance, request)
        );
    }

    activeUsers(request?: DateFilter): AMEResult<DateFilter, DateGroupListResponse> {
        request ??= {};
        return ActiveUsers.instance.execute(
            this.apiExecutionContextFactory.createApiExecutionContext(ActiveUsers.instance, request)
        );
    }
}
