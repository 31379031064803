import useTranslation, {TranslateOptions} from "../hooks/useTranslation";

export interface TranslatedProps extends TranslateOptions {
    caption: string
}

export default function Translated(props: TranslatedProps): JSX.Element {
    const [t] = useTranslation();

    return t(props.caption, props) as never;
}
