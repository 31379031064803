import {InputComponentProps} from "../../../types";
import React, {useCallback, useEffect, useMemo} from "react";
import useTranslation from "../../../hooks/useTranslation";
import {CartesianGridSettings} from "./CartesianGridSettings";
import objectKey from "../../../utils/objectKey";
import ObjectDispatcher from "../../../dispatcher/ObjectDispatcher";

export interface CartesianGridSettingsControlProps extends InputComponentProps<CartesianGridSettings> {

}

const propWrappers: Partial<Record<keyof CartesianGridSettings, keyof HTMLInputElement>> = {
    fill: "value",
    stroke: "value",
    strokeWidth: "valueAsNumber",
    strokeOpacity: "valueAsNumber",
    strokeDasharray: "value",
    fillOpacity: "valueAsNumber"
}

const propModifiers: Partial<Record<keyof CartesianGridSettings, (v: any) => any>> = {
    fillOpacity: v => v / 100,
    strokeOpacity: v => v / 100,
    strokeDasharray: v => v.replace(/\s+/g, " ").replace(/[^\d\s]+/g, "")
}

const DEFAULT: CartesianGridSettings = {
    fill: "#888888",
    fillOpacity: 0,
    stroke: "#888888",
    strokeOpacity: 0.5,
    strokeWidth: 1,
    strokeDasharray: "3 1",
};

export default function CartesianGridSettingsControl({id, value, onInput}: CartesianGridSettingsControlProps) {
    const [t] = useTranslation();
    const stateDispatcher = useMemo(() => new ObjectDispatcher<CartesianGridSettings>({...DEFAULT}), []);

    const onChanged = useCallback((e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>) => {
        const target = e.currentTarget as HTMLInputElement;
        if (!target) {
            return;
        }
        const key = target.name as (keyof CartesianGridSettings);
        let value = target[propWrappers[key as never]];
        if (key in propModifiers) {
            value = propModifiers[key]?.(value) as never ?? value;
        }
        stateDispatcher.updateState({[key]: value});
    }, [stateDispatcher]);

    useEffect(() => {
        stateDispatcher.update({
            ...DEFAULT,
            ...value
        });
        // eslint-disable-next-line
    }, [objectKey(value), stateDispatcher]);

    useEffect(() => {
        if (!onInput) {
            return;
        }

        return stateDispatcher.subscribe(v => onInput(v, id));
    }, [stateDispatcher, onInput, id]);

    const state: CartesianGridSettings = stateDispatcher.value as never;

    return <div className="flex-grow-1 w-100 grid-two-columns gap-2">
        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.strokeColor", "Stroke Color")}
        </label>
        <input type="color" className="form-control form-control-sm" value={state.stroke}
               onInput={onChanged} name="stroke" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.strokeOpacity", "Stroke Opacity")}
        </label>
        <input type="range" className="form-range" min={0} max={100} step={5}
               value={(state.strokeOpacity || 0) * 100}
               onInput={onChanged} name="strokeOpacity" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.strokeWidth", "Stroke Width")}
        </label>
        <input type="range" className="form-range" min={1} max={32} step={1}
               value={state.strokeWidth || 0}
               onInput={onChanged} name="strokeWidth" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.fillColor", "Fill Color")}
        </label>
        <input type="color" className="form-control form-control-sm" value={state.fill}
               onInput={onChanged} name="fill" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.fillOpacity", "Fill Opacity")}
        </label>
        <input type="range" className="form-range" min={0} max={100} step={5} value={(state.fillOpacity || 0) * 100}
               onInput={onChanged} name="fillOpacity" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.strokeDashArray", "Stroke Dash Array")}
        </label>
        <input type="text" className="form-control form-control-sm" value={state.strokeDasharray}
               onInput={onChanged} name="strokeDasharray"/>
    </div>
}
