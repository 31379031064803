import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatcherMapped} from "../../hooks/useDispatcher";
import DesignerModeDispatcher from "../../dispatcher/DesignerModeDispatcher";
import QueryListDispatcher from "../../dispatcher/QueryListDispatcher";
import QueryExecutionDispatcher from "../../context/QueryExecutionDispatcher";
import QueryDispatcher, {QueryDesignerData} from "../query-editor/QueryDispatcher";
import {REM} from "./constants";
import presentationTypeControls from "../query-editor/presentationTypeControls";
import QueryEditorContext from "../query-editor/context/QueryEditorContext";
import QueryExecutionContext from "../../context/QueryExecutionContext";
import If from "../If";
import {Button, InputGroup} from "react-bootstrap";
import {DashboardItem} from "../dashboard/types";
import {QueryBlockAction} from "./types";
import classNames from "classnames";

export interface QueryBlockProps {
    item: DashboardItem
    onAction: (action: QueryBlockAction, id: number, d?: Partial<DashboardItem>) => void
}

const QueryBlockDragSource: {id: number} = {id: 0};

export function QueryBlock({item: {w, h, q, id, fs}, onAction}: QueryBlockProps) {
    const [width, setWidth] = useState(w);
    const [height, setHeight] = useState(h);
    const [dragOver, setDragOver] = useState(false);

    const [designerMode] = useDispatcherMapped(
        DesignerModeDispatcher.instance,
        useCallback((d: DesignerModeDispatcher) => d.active, [])
    );

    const [query] = useDispatcherMapped(
        QueryListDispatcher.instance,
        useCallback((d: QueryListDispatcher) => d.getItemById(q), [q])
    )

    const execution = useMemo(() => new QueryExecutionDispatcher(), []);
    const queryContext = useMemo(() => new QueryDispatcher(query), [query]);

    const cpql = query?.query;

    useEffect(() => {
        if (!cpql) {
            return;
        }
        execution.exec(cpql, true);
    }, [cpql, execution]);

    useEffect(() => {
        setWidth(w);
        setHeight(h);
    }, [w, h]);

    const onDeleteClick = useCallback(() => onAction("delete", id), [id, onAction]);
    const onFullscreenClick = useCallback(() => onAction(fs ? "fs-exit" : "fs-enter", id), [id, onAction, fs]);

    const onResizeBegin = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const doc = document.documentElement;
        const hor = e.currentTarget.dataset.move === "h";

        const mouseMove = (ev: MouseEvent) => {
            const field = hor ? "clientX" : "clientY";
            const start = e[field] as number;
            const cur = ev[field] as number;
            const final = Math.max(4, (hor ? width : height) - Math.ceil((start - cur) / REM));
            if (hor) {
                setWidth(final);
            } else {
                setHeight(final);
            }

            onAction("resize", id, {[hor ? "w" : "h"]: final});
        };
        const mouseUp = (ev: MouseEvent) => {
            doc.removeEventListener("mousemove", mouseMove, true);
            doc.removeEventListener("mouseup", mouseUp, true);
        }
        doc.addEventListener("mousemove", mouseMove, true);
        doc.addEventListener("mouseup", mouseUp, true);

        e.preventDefault();
        e.stopPropagation();
    }, [width, height, id, onAction]);

    const onDragStart = useCallback((e: React.DragEvent<HTMLElement>) => {
        e.dataTransfer.setData("application/query-item", JSON.stringify({id}));
        e.dataTransfer.effectAllowed = "move";
        QueryBlockDragSource.id = id;
    }, [id]);

    const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
        if (QueryBlockDragSource.id === id) {
            e.dataTransfer.dropEffect = "none";
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "move";
        setDragOver(true);
    }, [id]);

    const onDragLeave = useCallback((e: React.DragEvent<HTMLElement>) => {
        if (!e.currentTarget.contains(e.relatedTarget as never)) {
            setDragOver(false);
        }
    }, []);

    const onDrop = useCallback((e: React.DragEvent<HTMLElement>) => {
        const item = e.dataTransfer.getData("application/query-item");
        if (!item) {
            return;
        }
        const {id: source} = JSON.parse(item);
        if (id === source) {
            return;
        }

        onAction("drag", id, {id: source})
    }, [id, onAction]);

    useEffect(() => {
        const dragEnd = () => setDragOver(false);
        document.addEventListener("dragend", dragEnd, true);
        return () => document.removeEventListener("dragend", dragEnd, true);
    }, []);

    if (!cpql) {
        return <></>;
    }

    const designerData = JSON.parse(query.designerData) as QueryDesignerData;

    const Control = presentationTypeControls[designerData.type ?? ""];

    const style: CSSProperties = {
        width: fs ? "100%" : `${width}rem`,
        height: fs ? "100%" : `${height}rem`,
        fontSize: fs ? "4rem" : `${(width / 48).toFixed(2)}rem`
    };

    return <QueryEditorContext.Provider value={queryContext}>
        <QueryExecutionContext.Provider value={execution}>
            <If condition={!designerMode}>
                <div style={style as never}>
                    <Control/>
                </div>
            </If>
            <If condition={designerMode}>
                <div className={classNames(
                    "resizable-container element-toolbox-container",
                    {"qb-drag-over": dragOver}
                )} style={style as never} draggable  onDragStart={onDragStart} onDragOver={onDragOver}
                     onDragLeave={onDragLeave} onDrop={onDrop}>
                    <Control/>
                    <span className="grip grip-right" data-move="h" onMouseDown={onResizeBegin} draggable={false}>
                        <i className="bi bi-grip-vertical"></i>
                        </span>
                    <span className="grip grip-bottom" data-move="v" onMouseDown={onResizeBegin} draggable={false}>
                            <i className="bi bi-grip-horizontal"></i>
                        </span>
                    <InputGroup className="element-toolbox w-auto" size="sm">
                        <Button variant="primary" onClick={onFullscreenClick}>
                            <i className={`bi bi-fullscreen${fs ? '-exit' : ''}`}></i>
                        </Button>
                        <Button variant="danger" onClick={onDeleteClick}>
                            <i className="bi bi-trash-fill"></i>
                        </Button>
                    </InputGroup>
                </div>
            </If>
        </QueryExecutionContext.Provider>
    </QueryEditorContext.Provider>
}
