import {InputComponentProps} from "../../../types";
import React, {useEffect, useMemo} from "react";
import useTranslation from "../../../hooks/useTranslation";
import {LegendSettings} from "./LegendSettings";
import {Align, LegendType, VerticalAlign} from "../types";
import {StateButton} from "../../custom-dashboard/StateButton";
import {horizontalAlignStates, verticalAlignStates} from "../../custom-dashboard/constants";
import ObjectDispatcher from "../../../dispatcher/ObjectDispatcher";
import EnumDropdown from "../../EnumDropdown";
import objectKey from "../../../utils/objectKey";

export interface LegendSettingsControlProps extends InputComponentProps<LegendSettings> {

}

const DEFAULT: LegendSettings = {
    align: Align.center,
    iconSize: 14,
    verticalAlign: VerticalAlign.top,
};

export default function LegendSettingsControl({value, onInput, id}: LegendSettingsControlProps) {
    const [t] = useTranslation();

    const state = useMemo(() => new ObjectDispatcher<LegendSettings>({...DEFAULT} as never), []);

    useEffect(() => {
        if (!onInput) {
            return;
        }

        return state.subscribe(v => onInput(v, id));
    }, [state, onInput, id]);

    useEffect(() => {
        state.update({
            ...DEFAULT,
            ...value
        });
        // eslint-disable-next-line
    }, [objectKey(value), state])

    const obj: LegendSettings = state.value || {} as never;

    return <div className="w-100 grid-two-columns gap-2">
        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.width", "Width")}
        </label>
        <input type="range" step="1" value={obj.width || 0} max={2048} className="form-range"
               onInput={state.asUpdater("width", e => e.currentTarget.valueAsNumber)}/>

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.height", "Height")}
        </label>
        <input type="range" step="1" value={obj.height || 0} max={200} className="form-range"
               onInput={state.asUpdater("height", e => e.currentTarget.valueAsNumber)}/>

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.horizontalAlign", "Horizontal Align")}
        </label>
        <StateButton states={horizontalAlignStates} defaultValue={obj.align}
                     onSelect={state.asUpdater("align", key => key as never)} name="align" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.verticalAlign", "Vertical Align")}
        </label>
        <StateButton states={verticalAlignStates} defaultValue={obj.verticalAlign}
                     onSelect={state.asUpdater("verticalAlign", key => key as never)} name="verticalAlign" />

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.iconSize", "Icon Size")}
        </label>
        <input type="range" step="1" value={obj.iconSize || 0} max={200} className="form-range"
               onInput={state.asUpdater("iconSize", e => e.currentTarget.valueAsNumber)}/>

        <label className="form-label p-0 m-0 no-line-height small">
            {t("charts.labels.iconType", "Icon Type")}
        </label>
        <EnumDropdown value={obj.iconType || "none"} onChange={state.asUpdater("iconType", v => v) as never}
                      enumObj={LegendType} translationCategory={"charts.components.legendType"}
                      dropdownToggleSize="sm" />
    </div>;
}
