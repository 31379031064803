import type {Dictionary} from "ez-api-client/dist/api/types";

export interface CPQLExecRequest extends Dictionary {
    offset?: number
    limit?: number
    query: string
    parameters: Record<string, unknown>
}

export enum CPQLType {
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    TIME = "TIME",
    DATETIME = "DATETIME",
    ANY = "ANY"
}

export interface CPQLNamed {
    name: string
}

export interface CPQLTyped {
    type: CPQLType
}

export interface CPQLNamedAndTyped extends CPQLNamed, CPQLTyped {
}

export interface CPQLExecResponse extends Dictionary {
    executionTime: number
    totalRecords: number
    offset: number
    limit: number
    fields: CPQLNamedAndTyped[]
    data: unknown[][]
}

export interface CPQLTableInfo extends CPQLNamed {
    columns: CPQLNamedAndTyped[]
}

export interface CPQLFunctionInfo extends CPQLNamed {
    returnType: CPQLType
    parameters: CPQLNamedAndTyped[]
    varArgs: boolean
    aggregate: boolean
}

export interface CPQLMappingResponse extends Dictionary {
    tables: CPQLTableInfo[]
    functions: CPQLFunctionInfo[]
}

export interface CPQLStatement {
    "@type": string
}

export interface CPQLExpression extends CPQLStatement {

}

export interface CPQLNameExpression extends CPQLExpression {
    "@type": "NameExpression"
    name: string
}

export interface CPQLColumnNameExpression extends CPQLExpression {
    "@type": "ColumnNameExpression"
    left: CPQLNameExpression
    right?: CPQLNameExpression
}

export interface CPQLCallExpression extends CPQLExpression {
    "@type": "CallExpression"
    name: CPQLNameExpression
    parameters: CPQLExpression[]
}

export interface CPQLAliasableExpression extends CPQLExpression {
    "@type": "AliasableExpression"
    grouped?: boolean
    expression: CPQLExpression
    alias?: CPQLNameExpression
}

export type CPQLConstantType = "INTEGER" | "DECIMAL" | "STRING" | "DATE" | "DATETIME" | "TIME" | "BOOLEAN" | "NULL";

export interface CPQLConstantExpression extends CPQLExpression {
    "@type": "ConstantExpression"
    type: CPQLConstantType
    value: string
}

export type CPQLBinaryOperator = "ADD" | "SUB" | "MUL" | "DIV" | "MOD" | "POW" | "LSH" | "RSH" | "AND" | "OR";

export interface CPQLBinaryExpression extends CPQLExpression {
    "@type": "BinaryExpression"
    left: CPQLExpression
    right: CPQLExpression
    operator: CPQLBinaryOperator
}

export interface CPQLGroupExpression extends CPQLExpression {
    "@type": "GroupExpression"
    expression: CPQLExpression
}

export type CPQLComparisonOperator = "EQ" | "NEQ" | "GT" | "LT" | "GTE" | "LTE" | "IS_NULL" | "IS_NOT_NULL" | "LIKE" | "NOT_LIKE" | "IN" | "NOT_IN";

export interface CPQLComparisonExpressionBase extends CPQLExpression {
    left: CPQLExpression
}

export interface CPQLComparisonExpression extends CPQLComparisonExpressionBase {
    "@type": "ComparisonExpression"
    right: CPQLExpression
    operator: CPQLComparisonOperator
}

export interface CPQLNullExpressionBase extends CPQLComparisonExpressionBase {
    left: CPQLExpression
    expression: CPQLExpression
}

export interface CPQLIsNullExpression extends CPQLNullExpressionBase {
    "@type": "IsNullExpression"
}

export interface CPQLIsNotNullExpression extends CPQLNullExpressionBase {
    "@type": "IsNotNullExpression"
}

export type CPQLConditionalOperator = "AND" | "OR";

export interface CPQLChainedConditionalExpression extends CPQLExpression{
    "@type": "ChainedConditionalExpression",
    left: CPQLExpression
    right: CPQLExpression
    operator: CPQLConditionalOperator
}

export interface CPQLArrayExpression extends CPQLExpression {
    "@type": "ArrayExpression"
    expressions: CPQLExpression[]
}

export interface CPQLInExpressionBase extends CPQLComparisonExpressionBase {
    expression: CPQLExpression
    array: CPQLArrayExpression
}

export interface CPQLInExpression extends CPQLInExpressionBase {
    "@type": "InExpression"
}

export interface CPQLNotInExpression extends CPQLInExpressionBase {
    "@type": "NotInExpression"
}

export interface CPQLOrderExpression extends CPQLExpression {
    "@type": "OrderExpression"
    expression: CPQLExpression
    type?: "ASC" | "DESC"
}

export interface CPQLExpressionListStatement<T extends CPQLExpression> extends CPQLStatement {
    "@type": "ExpressionListStatement"
    expressions: T[]
}

export interface CPQLWhereStatement extends CPQLStatement {
    "@type": "WhereStatement"
    expression: CPQLExpression
}

export interface CPQLHavingStatement extends CPQLStatement {
    "@type": "HavingStatement"
    expression: CPQLExpression
}

export interface CPQLParsedQuery {
    columns: CPQLExpressionListStatement<CPQLAliasableExpression>
    tables: CPQLExpressionListStatement<CPQLAliasableExpression>
    rowFilter?: CPQLWhereStatement
    groupFilter?: CPQLHavingStatement
    order: CPQLExpressionListStatement<CPQLOrderExpression>
}

export interface CPQLCompileRequest extends CPQLParsedQuery, Dictionary {

}

export interface CPQLCompileResponse extends Dictionary {
    query: string
}
